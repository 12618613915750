import { memo, ReactNode, useCallback, useMemo } from 'react';
import { ButtonLink, Tooltip, TSizeAll } from 'moralis-ui';
import { RemoveTokenFollowedModal } from '@/components/common/RemoveTokenFollowedModal/RemoveTokenFollowedModal';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useAuthModal } from '@/hooks/useAuthModal';
import { useUserCoinFavourites } from '@/hooks/useUserCoinFavourites';
import { ChainIdString } from '@/utils/supportedChains';
import { faHeart, faSpinnerThird, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartFilled } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/core/macro';
import styles from './ToggleFollowTokenButton.module.scss';

export const ToggleFollowTokenButton = memo(
  ({
    chainId,
    address,
    symbol,
    name,
    size = 'md',
    trigger,
    removeTrigger,
    loadingIcon,
    loadingSpin = true,
    hasConfirmModal = true,
    showTooltips = true,
  }: {
    chainId: ChainIdString;
    address: string;
    symbol: string | undefined;
    name: string | undefined;
    size?: TSizeAll;
    trigger?: ReactNode;
    removeTrigger?: ReactNode;
    loadingIcon?: IconDefinition;
    loadingSpin?: boolean;
    hasConfirmModal?: boolean;
    showTooltips?: boolean;
  }) => {
    const { coinFavourites, isAdding, isRemoving, removeCoinFavourite, addCoinFavourite } = useUserCoinFavourites();
    const { trackTokenSaved } = useAnalytics();
    const { isLoggedIn, askToLogIn } = useAuthModal();

    const isFavorite = useMemo(
      () => coinFavourites?.some((coin) => coin.tokenAddress === address && coin.chainId === chainId),
      [coinFavourites, address, chainId],
    );

    const handleToggleFollow = useCallback(
      (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isLoggedIn) {
          askToLogIn();
          return;
        }
        if (isFavorite) {
          void removeCoinFavourite({ tokenAddress: address, chainId });
        } else {
          void addCoinFavourite({ tokenAddress: address, chainId }).then(() => {
            trackTokenSaved({ coinAddress: address, chainId, name });
          });
        }
      },
      [
        isFavorite,
        removeCoinFavourite,
        addCoinFavourite,
        chainId,
        address,
        name,
        trackTokenSaved,
        isLoggedIn,
        askToLogIn,
      ],
    );

    if (isAdding || isRemoving) {
      return (
        <ButtonLink size={size} disabled={true} aria-label={t`Loading`}>
          <FontAwesomeIcon icon={loadingIcon || faSpinnerThird} spin={loadingSpin} />
        </ButtonLink>
      );
    }
    return !isFavorite || !isLoggedIn ? (
      <FollowButton
        size={size}
        trigger={trigger}
        handleToggleFollow={(e: React.MouseEvent) => handleToggleFollow(e)}
        showTooltips={showTooltips}
      />
    ) : (
      <UnfollowButton
        size={size}
        removeTrigger={removeTrigger}
        handleToggleFollow={handleToggleFollow}
        hasConfirmModal={hasConfirmModal}
        chainId={chainId}
        address={address}
        symbol={symbol}
        name={name}
        showTooltips={showTooltips}
      />
    );
  },
);

const FollowButton = memo(
  ({
    size,
    trigger,
    handleToggleFollow,
    showTooltips,
  }: {
    size: TSizeAll;
    trigger: ReactNode;
    handleToggleFollow: (e: React.MouseEvent) => void;
    showTooltips: boolean;
  }) => {
    const renderedTrigger = trigger ? (
      <button className={styles.resetButton} type="button" aria-label={t`Follow Token`} onClick={handleToggleFollow}>
        {trigger}
      </button>
    ) : (
      <ButtonLink size={size} aria-label={t`Follow Token`} onClick={handleToggleFollow}>
        <FontAwesomeIcon icon={faHeart} />
      </ButtonLink>
    );

    return showTooltips ? (
      <Tooltip size="sm" color="navy" trigger={renderedTrigger} content={t`Follow`} />
    ) : (
      renderedTrigger
    );
  },
);

const UnfollowButton = memo(
  ({
    size,
    removeTrigger,
    handleToggleFollow,
    hasConfirmModal,
    chainId,
    address,
    symbol,
    name,
    showTooltips,
  }: {
    size: TSizeAll;
    removeTrigger: ReactNode | undefined;
    handleToggleFollow: (e: React.MouseEvent) => void;
    hasConfirmModal: boolean;
    chainId: ChainIdString;
    address: string;
    symbol: string | undefined;
    name: string | undefined;
    showTooltips: boolean;
  }) => {
    const renderedTrigger = removeTrigger ? (
      <button
        className={styles.resetButton}
        type="button"
        aria-label={t`Unfollow Token`}
        onClick={hasConfirmModal ? undefined : handleToggleFollow}
      >
        {removeTrigger}
      </button>
    ) : (
      <ButtonLink size={size} aria-label={t`Unfollow Token`} onClick={hasConfirmModal ? undefined : handleToggleFollow}>
        <FontAwesomeIcon icon={faHeartFilled} />
      </ButtonLink>
    );

    const triggerWithTooltip = showTooltips ? (
      <Tooltip size="sm" color="navy" trigger={renderedTrigger} content={t`Unfollow`} />
    ) : (
      renderedTrigger
    );

    return hasConfirmModal ? (
      <RemoveTokenFollowedModal
        trigger={triggerWithTooltip}
        chainId={chainId}
        tokenAddress={address}
        tokenSymbol={symbol}
        tokenName={name}
      />
    ) : (
      triggerWithTooltip
    );
  },
);
