import { API } from '@/api';
import { TS } from '../TS';
import { getURLWithQuery } from './url';

export namespace Fetcher {
  export type FetchFnArg<T> = TS.ExcludeMissing<OperationOptions<T>>;
  export type FetchFn<T> = (params: FetchFnArg<T>) => Promise<API.Utils.Response<T>>;

  export type EndpointMethods<Keys extends string = string> = {
    [K in Keys]: Partial<Record<Lowercase<API.Utils.HTTPMethods>, unknown>>;
  };

  export type Paths<T> = EndpointMethods<keyof T & string>;

  export type MethodData<T extends Paths<T>, P extends keyof T, M extends API.Utils.HTTPMethods> = T[P][Lowercase<M>];

  export type GetMethodByPath<PathMap, K extends keyof PathMap & string> = API.Utils.HTTPMethods &
    Uppercase<TS.ExcludeKeysOf<PathMap[K], undefined>>;

  export type OperationOptions<MData> = {
    pathParams: API.Utils.PathParams<MData>;
    queryParams: API.Utils.Query<MData>;
    headers: API.Utils.Headers<MData>;
    body: API.Utils.Body<MData>;
  };
  type IsVoid<T> = API.Utils.Response<T> extends void ? { ignoreResponse: true } : { ignoreResponse?: boolean };
  export type OperationOverrides<MData> = {
    response?: (json: unknown) => json is API.Utils.Response<MData>;
    logPrefix?: string;
  } & IsVoid<MData>;
}

/**
 * Replace path parameters in URL path with actual values
 */
export const replacePathParams = (path: string, pathParams: Record<string, string>): string =>
  Object.keys(pathParams).reduce((acc, key) => acc.replaceAll(`{${key}}`, encodeURIComponent(pathParams[key])), path);

export const merge2 = <T>(a: T, b: unknown) => (a && b ? { ...a, ...b } : a ?? b) as T | undefined;

export const resolveURL = <T>(baseURL: string, path: string, options: Fetcher.OperationOptions<T>) => {
  const urlWithPath = [baseURL, path].join('');
  const urlWithParams = options.pathParams ? replacePathParams(urlWithPath, options.pathParams) : urlWithPath;
  return getURLWithQuery(urlWithParams, options.queryParams ?? undefined);
};
