import { Controller, useFormContext, ValidateResult } from 'react-hook-form';
import { Switch } from '@/components/core';
import { Input } from '@/components/core/Input';
import styles from './ToggleableInput.module.scss';

export const ToggleableInput = ({
  inputName,
  toggleName,
  label,
  required,
  placeholder,
  validate,
  disabled = false,
}: {
  disabled?: boolean;
  inputName: string;
  label: string;
  placeholder: string;
  required?: boolean;
  toggleName: string;
  validate?: (value: any, formValues: any) => ValidateResult | Promise<ValidateResult>;
}) => {
  const { control, setValue, watch, formState } = useFormContext();

  const isToggled = watch(toggleName);

  function handleSwitchChange(e: React.ChangeEvent<HTMLInputElement>, name: string) {
    if (!e.target.checked) setValue(name, formState?.defaultValues?.[name] || '');
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.toggle}>
        <Controller
          control={control}
          name={toggleName}
          render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid } }) => (
            <Switch
              checked={value}
              disabled={disabled}
              hasError={invalid}
              id={name}
              labelRight={label}
              onBlur={onBlur}
              onChange={(e) => {
                handleSwitchChange(e, inputName);
                onChange(e);
              }}
            />
          )}
          rules={{ required }}
        />
      </div>
      <div className={styles.input}>
        <Controller
          control={control}
          name={inputName}
          render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid } }) => (
            <Input
              disabled={!isToggled || disabled}
              hasError={invalid}
              id={name}
              name={name}
              placeholder={placeholder}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
          rules={{
            required: isToggled,
            validate,
          }}
        />
      </div>
    </div>
  );
};
