import { API } from '@/api';
import { Addon } from '@moralis/billing-types';
import { createAppStore } from './createAppStore';

export type ProtocolType = 'email' | 'telegram' | 'webhook';
export type ValidatedProtocols = {
  data: string[];
  type: ProtocolType;
};

export type User = {
  addonLimits?: Partial<Record<Addon, { limit: number }>>;
  cancelAt?: string;
  connectedAddress?: string;
  email?: string;
  id?: string;
  isEmailValidated?: boolean;
  isOg?: boolean;
  lastLogin?: number;
  level?: number;
  plan?: API.User.Comps['PlanResponseDto'];
  profileId?: string;
  purchasedTrialPlan?: boolean;
  token?: string;
  tradingAccountData?: API.User.Comps['SmartTradingDataResponseDto'];
  tradingViewUserName?: string;
  userName?: string;
  validatedProtocols?: ValidatedProtocols[];
  wallets?: string[];
};

export const {
  store: userStore,
  reset: clearUserStore,
  createHook: createUserHook,
} = createAppStore(
  {
    stage: null as null | API.User.Comps['PlanStageDto'],
    user: null as null | User,
  },
  { storeName: 'user', persist: true },
);

const set = userStore.setState;
export const updateUser = (newUser: Partial<User>) => set(({ user }) => ({ user: { ...user, ...newUser } }));
export const setStage = (stage: API.User.Comps['PlanStageDto']) => set({ stage });
