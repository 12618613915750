import create from 'zustand';

type TMenuStore = {
  isCollapsed: boolean;
  openMobileSubmenu: string | null;
  setIsCollapsed: (state: boolean) => void;
  setOpenMobileSubmenu: (state: string | null) => void;
  toggleIsCollapsed: () => void;
};

export const useMenuStore = create<TMenuStore>((set) => {
  return {
    isCollapsed: false,
    openMobileSubmenu: null,
    toggleIsCollapsed: () => {
      set((state) => {
        return {
          isCollapsed: !state.isCollapsed,
        };
      });
    },
    setOpenMobileSubmenu: (state: string | null) => {
      set(() => {
        return {
          openMobileSubmenu: state,
        };
      });
    },
    setIsCollapsed: (state: boolean) => {
      set(() => {
        return {
          isCollapsed: state,
        };
      });
    },
  };
});
