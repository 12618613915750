import { Controller, useFormContext } from 'react-hook-form';
import { Select, TSelectOption } from '@/components/core/Select';
import { IStrategyAlertFormData } from './StrategyAlert.types';
import { ITokenAlertFormData } from './TokenAlert.types';
import commonStyles from './col.module.scss';
import styles from './FrequencyField.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export const FrequencyField = ({ options }: { disabled?: boolean; options: TSelectOption[] }) => {
  const { control } = useFormContext<ITokenAlertFormData | IStrategyAlertFormData>();

  return (
    <div>
      <div className={commonStyles.col25}>
        <h5 className={typography.bodySmallRegular} style={{ lineHeight: '40px' }}>
          Alert Frequency
        </h5>
      </div>
      <Controller
        control={control}
        name="selectCoolDown"
        render={({ field, fieldState: { invalid, error } }) => (
          <div className={`${commonStyles.col75} ${styles.selectWrapper}`}>
            <Select
              hasError={invalid}
              id={field.name}
              options={options}
              placeholder="Select an alert frequency..."
              {...field}
            />
            {/* TODO: remove when Select will support errorMessage natively */}
            {error?.message && <span className="caption-12-regular color-input-error">{error?.message}</span>}
          </div>
        )}
        rules={{
          required: true,
          validate: (value) => {
            if (options.find((option) => option.id === value)?.disabled) {
              return 'Invalid "Alert Frequency" for your plan';
            }
            return true;
          },
        }}
      />
    </div>
  );
};
