import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { API } from '@/api';
import { apiBrowser } from '@/utils/api/apiBrowser';
import { fetchHiddenGemsCoins } from '@/utils/api/browser/fetchHiddenGemsCoins';
import { invariant } from '@/utils/invariant';
import { logApp } from '@/utils/logApp';
import { swrHook } from '@/utils/swrHook';
import { useAuthToken, withAuthToken } from './useAuth';

const log = logApp.create('useUserCoinFavourites');
const fetchAllFavourites = apiBrowser.user('/user/coinFavourite/all', 'GET');
const saveFavourite = apiBrowser.user('/user/coinFavourite', 'POST');
const deleteFavourite = apiBrowser.user('/user/coinFavourite/{favouriteId}', 'DELETE');

type UserCoinFavourites = API.User.Comps['UserCoinFavouritesResponseDto'];

const useAllFavourites = withAuthToken(swrHook.create(fetchAllFavourites.name, fetchAllFavourites), {
  auth: 'required',
});

interface RemoveCoinFavouriteParams {
  tokenAddress: string;
  chainId: string;
}

const useHiddenGemsCoins = swrHook.create(
  fetchHiddenGemsCoins.name,
  (coinFavourites: UserCoinFavourites[]) =>
    fetchHiddenGemsCoins({
      coinList: coinFavourites.map((coin) => ({
        chainId: coin.chainId,
        coinTokenAddress: coin.tokenAddress,
      })),
    }),
  {
    swr: {
      revalidateOnFocus: false,
    },
  },
);

export const useUserCoinFavourites = () => {
  const { isLoggedIn, requireAuthToken } = useAuthToken();
  const [removeTokenFollowedModalOpen, setRemoveTokenFollowedModalOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const {
    data: rawCoinFavourites,
    mutate: mutateCoinFavourites,
    isValidating: isCoinFavouritesValidating,
    isLoading: isCoinFavouritesLoading,
  } = useAllFavourites({});

  const {
    data: rawCoinFavouritesData,
    mutate: mutateTokenData,
    isValidating: isFetching,
    isLoading,
  } = useHiddenGemsCoins(rawCoinFavourites ?? null);

  // Sort coinFavourites by createdAt
  const coinFavourites = useMemo(() => {
    return rawCoinFavourites?.slice().sort((a, b) => {
      return b.createdAt - a.createdAt;
    });
  }, [rawCoinFavourites]);

  // Sort rawCoinFavouritesData based on sorted coinFavourites
  const coinFavouritesData = useMemo(() => {
    if (!rawCoinFavouritesData || !coinFavourites) return rawCoinFavouritesData;

    // Create a map with combined keys (chainId and tokenAddress)
    const favouriteMap = new Map(coinFavourites.map((fav) => [`${fav.chainId}_${fav.tokenAddress}`, fav]));

    // Sort rawCoinFavouritesData based on the map
    return rawCoinFavouritesData.slice().sort((a, b) => {
      const favA = favouriteMap.get(`${b.chainId_coinTokenAddress}`);
      const favB = favouriteMap.get(`${a.chainId_coinTokenAddress}`);
      return (favA?.createdAt || 0) - (favB?.createdAt || 0);
    });
  }, [rawCoinFavouritesData, coinFavourites]);

  const addCoinFavourite = async (request: { chainId: string; tokenAddress: string }) => {
    setIsAdding(true);
    invariant(!isCoinFavouritesValidating && !isCoinFavouritesLoading, 'Loading');
    try {
      await saveFavourite({
        body: request,
        authToken: requireAuthToken(),
      });
      mutateCoinFavourites();
      setIsAdding(false);
    } catch (error) {
      log.error('Error adding coin favourite', error, request);
      setIsAdding(false);
    }
  };

  const REMOVE_FAVORITE_DELAY = 300;

  const debouncedRemoveCoinFavourite = useMemo(
    () =>
      debounce(async ({ tokenAddress, chainId }: RemoveCoinFavouriteParams) => {
        invariant(!isCoinFavouritesValidating && !isCoinFavouritesLoading, 'Loading');
        const fav = coinFavourites?.find((c) => c.chainId === chainId && c.tokenAddress === tokenAddress);
        invariant(fav, 'Favourite not found');
        const [chainId_coinTokenAddress] = [chainId, tokenAddress].join('_');
        setIsRemoving(true);
        try {
          await deleteFavourite({
            pathParams: { favouriteId: fav.id },
            authToken: requireAuthToken(),
            ignoreResponse: true,
          });
          await Promise.all([
            mutateCoinFavourites((data) => data?.filter((coin) => coin.id !== fav.id)),
            mutateTokenData((data) =>
              data?.filter((coin) => coin.chainId_coinTokenAddress !== chainId_coinTokenAddress),
            ),
          ]);

          setRemoveTokenFollowedModalOpen(false);
        } catch (error) {
          log.error('Error removing coin favourite', error, { tokenAddress, chainId });
          // Restore the previous state
          await Promise.all([mutateCoinFavourites(), mutateTokenData()]);
        }

        setIsRemoving(false);
      }, REMOVE_FAVORITE_DELAY),

    [coinFavourites, isCoinFavouritesValidating, isCoinFavouritesLoading, mutateCoinFavourites, mutateTokenData],
  );

  useEffect(() => {
    return () => {
      debouncedRemoveCoinFavourite.cancel();
    };
  }, [debouncedRemoveCoinFavourite]);

  const removeCoinFavourite = debouncedRemoveCoinFavourite;

  const resetCoinFavourites = () => {
    mutateCoinFavourites([], false);
    mutateTokenData([], false);
  };

  // Manual refetch function
  const refetchCoinFavourites = useCallback(() => {
    mutateCoinFavourites(); // Re-fetch coin favourites
    mutateTokenData(); // Re-fetch token data
  }, [mutateCoinFavourites, mutateTokenData]);

  useEffect(() => {
    if (!isLoggedIn) {
      resetCoinFavourites();
    }
  }, [isLoggedIn]);

  return {
    isAdding,
    isRemoving,
    coinFavourites,
    coinFavouritesData,
    addCoinFavourite,
    removeCoinFavourite,
    isFetching: isFetching || isLoading || isCoinFavouritesValidating || isCoinFavouritesLoading,
    refetchCoinFavourites,
    removeTokenFollowedModalOpen,
    setRemoveTokenFollowedModalOpen,
  };
};
