import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { DEFAULT_LOCALE, isSupportedLanguage, SupportedLanguage } from '@/utils/languages';
import { i18n, Messages } from '@lingui/core';
import enMessages from './generated/en.json';
import frMessages from './generated/fr.json';

const catalogs: Record<SupportedLanguage, Messages> = {
  en: enMessages.messages,
  fr: frMessages.messages,
};

export const loadCatalog = async (locale: string): Promise<Messages> => {
  const mod = await import(`./generated/${locale}.po`);
  return mod.messages;
};

export const useLinguiInit = (messages: Messages) => {
  const router = useRouter();
  const locale = router.locale ?? router.defaultLocale ?? 'en';
  const isClient = typeof window !== 'undefined';

  if ((isClient && !i18n.locale) || (!isClient && locale !== i18n.locale)) {
    i18n.loadAndActivate({ locale, messages });
  }

  useEffect(() => {
    const localeDidChange = locale !== i18n.locale;
    if (localeDidChange) {
      i18n.loadAndActivate({ locale, messages });
    }
  }, [locale, messages]);

  return i18n;
};

export const activateLocale = (locale: string) => {
  const messages = isSupportedLanguage(locale) ? catalogs[locale] : catalogs[DEFAULT_LOCALE];
  i18n.load(locale, messages as Messages);
  i18n.activate(locale);
};
