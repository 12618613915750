export const allLanguages = [
  'en',
  'cs',
  'fr',
  'es',
  'de',
  'it',
  'pt',
  'ru',
  'zh',
  'ja',
  'ko',
  'ar',
  'hi',
  'bn',
  'ur',
  'tr',
  'fa',
  'he',
  'vi',
  'th',
  'ms',
  'id',
  'pl',
  'nl',
  'sv',
  'da',
  'no',
  'fi',
  'el',
  'hu',
  'ro',
  'sk',
  'sl',
  'bg',
  'uk',
  'sr',
  'hr',
  'lt',
  'lv',
  'et',
  'is',
  'mt',
  'ga',
  'cy',
  'eu',
  'ca',
  'gl',
  'az',
  'ka',
  'hy',
  'ta',
  'te',
  'kn',
  'ml',
  'mr',
  'gu',
  'pa',
  'si',
  'ne',
  'my',
  'km',
  'lo',
  'am',
  'sw',
  'zu',
  'xh',
  'st',
  'sn',
  'ny',
  'yo',
  'ig',
  'ha',
  'rw',
  'so',
  'af',
  'sq',
  'bs',
  'mk',
  'tl',
  'ceb',
  'jw',
  'su',
];

export const supportedLanguages = ['en', 'fr'] as const;

export type SupportedLanguage = (typeof supportedLanguages)[number];

export const DEFAULT_LOCALE: SupportedLanguage = 'en';

export const isSupportedLanguage = (lang: string): lang is SupportedLanguage => {
  return (supportedLanguages as readonly string[]).includes(lang);
};
