import { API } from '@/api';
import { User } from '@/stores/userStore';
import { normalizeTelegramUsername } from '@/utils/normalizeTelegramUsername';
import { notificationType } from '@/utils/notificationType';
import { getValidProtocol } from '@/utils/user/getValidProtocol';
import { IBundleAlertFormData } from './BundleAlert.types';

export const getFormDefaultValues = (
  user: User | null,
  bundle?: API.User.Comps['GetAllCuratedWalletListResponse'],
): IBundleAlertFormData => {
  return {
    selectBundle: bundle?.id || '',
    inputProtocolEmail: user?.email || '',
    inputProtocolTelegram: normalizeTelegramUsername(getValidProtocol('telegram', user)?.data[0]) || '',
    inputProtocolWebhook: '',
    toggleProtocolEmail: true,
    toggleProtocolTelegram: false,
    toggleProtocolWebhook: false,
  };
};

export const getPayload = (data: IBundleAlertFormData) => {
  const notificationData: API.User.Comps['INotificationData'][] = [];
  if (data?.toggleProtocolTelegram && data?.inputProtocolTelegram) {
    notificationData.push({
      type: notificationType.Telegram,
      data: data.inputProtocolTelegram,
    });
  }
  if (data?.toggleProtocolWebhook && data?.inputProtocolWebhook) {
    notificationData.push({
      type: notificationType.Webhook,
      data: data.inputProtocolWebhook,
    });
  }
  if (data?.toggleProtocolEmail && data?.inputProtocolEmail) {
    notificationData.push({
      type: notificationType.Email,
      data: data.inputProtocolEmail,
    });
  }

  return {
    id: data?.selectBundle,
    notificationData,
  };
};
