import { formatDistanceStrict, Locale } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { DEFAULT_LOCALE } from '@/utils/languages';

const getDateFnsLocale = (nextJsLocale: string) => {
  switch (nextJsLocale) {
    case 'fr':
      return fr;
    case 'en':
    default:
      return enUS;
  }
};

const timestampToDate = (timestamp: number | string) => new Date(Number(timestamp) * 1000);

/**
 * Timestamp in seconds to distance till now
 *
 * TODO: (Maxim) merge with ./timeAgoFromTimeStamp ?
 */
export const getTimestampDistance = (
  timestamp: number | string,
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year',
  locale: Locale = enUS,
) =>
  formatDistanceStrict(timestampToDate(timestamp), new Date(), {
    unit,
    locale,
  });

/**
 * Timestamp in seconds to distance till now in days
 */
export const getTimestampDistanceInDays = (timestamp: number | string, nextJsLocale: string = DEFAULT_LOCALE) => {
  const locale = getDateFnsLocale(nextJsLocale);
  return getTimestampDistance(timestamp, 'day', locale);
};
