import React from 'react';
import Head from 'next/head';
import { EnvSegment } from '@/utils/env/segmentClient';
import { getSEODescription, getSEOTitle, SEO_DOMAIN, SEO_PREVIEW_IMAGE, SEO_THEME_COLOR } from '@/utils/seo';
import { toArray } from '@/utils/toArray';
import { JsonLD } from './JsonLD';

export const MetaDataTags = ({
  title: titleProp,
  description,
  schema,
  previewImage = SEO_PREVIEW_IMAGE,
  previewImageWidth = 1200,
  previewImageHeight = 630,
  url = '',
  canonicalURL = url,
  children,
  includeDefaultSeoTitle = true,
}: React.PropsWithChildren<{
  description?: string;
  previewImage?: string;
  previewImageHeight?: number;
  previewImageWidth?: number;
  referrer?: string;
  schema?: { id: string; schema: any }[];
  title: string | string[];
  url?: string;
  canonicalURL?: string;
  includeDefaultSeoTitle?: boolean;
}>) => {
  const baseTitles = toArray(titleProp).filter(Boolean);
  const title = includeDefaultSeoTitle ? baseTitles.concat(getSEOTitle()).join(' | ') : baseTitles.join(' | ');
  description = description ?? getSEODescription();

  return (
    <>
      <Head>
        <meta charSet="utf-8" key="meta-charset" />
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" key="meta-ie-edge" />
        <meta
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no, minimal-ui, viewport-fit=cover"
          key="meta-viewport"
          name="viewport"
        />

        <title>{title}</title>
        <base target="_parent" />

        <meta content={description} key="meta-description" name="description" />
        <meta content="crypto, currency, NFT, portfolio, manager, discover, tokens" key="keywords" name="keywords" />
        <meta content="Moralis" key="og-site-name" property="og:site_name" />
        <meta content={title.substring(0, 60)} key="og-title" property="og:title" />
        <meta content={title.substring(0, 60)} key="twitter-title" name="twitter:title"></meta>
        <meta content={description.substring(0, 170)} key="og-description" property="og:description" />
        <meta content={description.substring(0, 170)} key="twitter-description" name="twitter:description"></meta>

        <link rel="preconnect" href={EnvSegment.cfg.cdnURL} />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://script.tapfiliate.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />

        <meta content={previewImage} key="og-image" property="og:image" />
        <meta content={previewImage} key="og-image-alt" property="og:image:alt" />
        <meta content={previewImage} key="twitter-image" property="twitter:image" />
        <meta content={previewImage} key="twitter-image-src" name="twitter:image:src" />

        <meta content={String(previewImageWidth)} key="og-image-width" property="og:image:width" />
        <meta content={String(previewImageHeight)} key="og-image-height" property="og:image:height" />
        <meta content="website" key="og-type" property="og:type" />

        <meta content={new URL(url, SEO_DOMAIN).toString()} key="og-url" property="og:url" />
        <link href={new URL(canonicalURL, SEO_DOMAIN).toString()} rel="canonical" />

        <meta
          content="CO9xrDmj_aqYls2l_3r6PtPocGQUVaogsIHsBisC3A4"
          key="meta-google-verification"
          name="google-site-verification"
        />

        {/* TWITTER */}
        <meta content="@moraliscom" key="twitter-site" name="twitter:site" />
        <meta content="@moraliscom" key="twitter-creator" name="twitter:creator" />
        <meta content="summary_large_image" key="twitter-card" name="twitter:card"></meta>

        {/* FAVICONs */}
        <link rel="apple-touch-icon" sizes="180x180" href="/static/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon-16x16.png" />
        <link rel="manifest" href="/static/site.webmanifest" />
        <link rel="mask-icon" href="/static/safari-pinned-tab.svg" color={SEO_THEME_COLOR} />
        <meta name="apple-mobile-web-app-title" content="Moralis" />
        <meta name="application-name" content="Moralis" />
        <meta name="msapplication-TileColor" content={SEO_THEME_COLOR} />
        <meta name="msapplication-TileImage" content="/static/mstile-144x144.png" />
        <meta name="theme-color" content={SEO_THEME_COLOR} />

        {/* GOOGLE SITE VERIFICATION */}
        <meta content="GyqP7m5pLRkvPBltFu3Q5zkHS563FeTKl_o0_U2QZNU" name="google-site-verification" />
        {children}
      </Head>
      {schema && schema.map((item) => <JsonLD data={item.schema} key={item.id} />)}
    </>
  );
};
