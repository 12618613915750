import { CHAIN_ID_MAP, ChainIdString } from '@/utils/supportedChains';

export type ChainOption = {
  label: string;
  id: ChainIdString;
  disabled: boolean;
  minimumPlanLvlRequired: number | null;
};

// This map is for type safety: it will not build if the any chain is not in the object
const chainOptionsMap: Record<ChainIdString, ChainOption> = {
  [CHAIN_ID_MAP.ethereum]: {
    label: 'Ethereum',
    id: CHAIN_ID_MAP.ethereum,
    disabled: false,
    minimumPlanLvlRequired: null, // note - null means public(non logged in) users can use it as well
  },
  [CHAIN_ID_MAP.binance]: {
    label: 'Binance',
    id: CHAIN_ID_MAP.binance,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.polygon]: {
    label: 'Polygon',
    id: CHAIN_ID_MAP.polygon,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.arbitrum]: {
    label: 'Arbitrum',
    id: CHAIN_ID_MAP.arbitrum,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.fantom]: {
    label: 'Fantom',
    id: CHAIN_ID_MAP.fantom,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.optimism]: {
    label: 'Optimism',
    id: CHAIN_ID_MAP.optimism,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.pulse]: {
    label: 'PulseChain',
    id: CHAIN_ID_MAP.pulse,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.avalanche]: {
    label: 'Avalanche',
    id: CHAIN_ID_MAP.avalanche,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.base]: {
    label: 'Base',
    id: CHAIN_ID_MAP.base,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.linea]: {
    label: 'Linea',
    id: CHAIN_ID_MAP.linea,
    disabled: false,
    minimumPlanLvlRequired: null,
  },
  [CHAIN_ID_MAP.solana]: {
    label: 'Solana',
    id: CHAIN_ID_MAP.solana,
    disabled: false,
    minimumPlanLvlRequired: 40,
  },
};
export const chainOptions = Object.values(chainOptionsMap);

export const excludeChainOptions = (idsToExclude: string[] = []) => {
  const idsToExcludeSet = new Set(idsToExclude);
  return chainOptions.filter((option) => !idsToExcludeSet.has(option.id));
};
