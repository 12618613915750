import { GetAllCuratedWalletListResponse } from '@/services/index';
import { ControlledSelect } from './ControlledSelect';
import styles from './col.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export const BundleField = ({ bundles }: { bundles?: GetAllCuratedWalletListResponse[] }) => {
  return (
    <div>
      <div className={styles.col25}>
        <h5 className={typography.bodySmallRegular}>Choose Bundle</h5>
      </div>
      <div className={styles.col75}>
        <ControlledSelect
          required
          name="selectBundle"
          options={
            bundles?.map((bundle) => ({
              label: bundle.name,
              id: bundle.id,
            })) || []
          }
          placeholder="Select a bundle..."
        />
      </div>
    </div>
  );
};
