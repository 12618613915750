import { useEffect } from 'react';

export const useScrollLock = (active: boolean) => {
  useEffect(() => {
    if (active) {
      // Store the current scroll position
      const scrollY = window.scrollY;

      // Add inline styles to lock the scroll and preserve the position
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%'; // Prevent layout shifts
    } else {
      // Restore the scroll position and reset styles
      const scrollY = parseInt(document.body.style.top || '0', 10) * -1;

      // Remove inline styles
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';

      // Restore the scroll position
      window.scrollTo(0, scrollY);
    }

    // Cleanup on unmount to restore the scroll position
    return () => {
      const scrollY = parseInt(document.body.style.top || '0', 10) * -1;

      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';

      window.scrollTo(0, scrollY);
    };
  }, [active]);
};
