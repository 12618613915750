/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiServiceClass } from './ApiServiceClass';

export { ApiError } from './core/ApiError';
export { BaseHttpRequest } from './core/BaseHttpRequest';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export { AddEmailDataRequestDto } from './models/AddEmailDataRequestDto';
export { addLockOptions } from './models/addLockOptions';
export { AddonResUsage } from './models/AddonResUsage';
export type { AddressesInfoDto } from './models/AddressesInfoDto';
export type { AlertCountResponseDto } from './models/AlertCountResponseDto';
export type { AlertDecreaseTokenAlertCountRequestDto } from './models/AlertDecreaseTokenAlertCountRequestDto';
export { AlertDto } from './models/AlertDto';
export type { AlertUnsubscribeAlertsRequestDto } from './models/AlertUnsubscribeAlertsRequestDto';
export type { ApproveAllowanceResponseDto } from './models/ApproveAllowanceResponseDto';
export type { ApproveSpenderResponseDto } from './models/ApproveSpenderResponseDto';
export type { ApproveTransactionResponseDto } from './models/ApproveTransactionResponseDto';
export type { AttributesDto } from './models/AttributesDto';
export type { AuthDto } from './models/AuthDto';
export type { cachedPairDelete } from './models/cachedPairDelete';
export type { ChainDataSummaryDto } from './models/ChainDataSummaryDto';
export { ChainId } from './models/ChainId';
export type { ChainsDataSummaryHistoryDto } from './models/ChainsDataSummaryHistoryDto';
export type { ChargeError } from './models/ChargeError';

export { CoinFilterDto } from './models/CoinFilterDto';
export type { CoinFiltersResponseDto } from './models/CoinFiltersResponseDto';
export type { CoinInfoDto } from './models/CoinInfoDto';
export type { CoinMetadata } from './models/CoinMetadata';
export type { CoinMetadataDto } from './models/CoinMetadataDto';
export type { CoinsResult } from './models/CoinsResult';
export type { CoinsWithAlphaDensityScoreDto } from './models/CoinsWithAlphaDensityScoreDto';
export { CreateReviewRequestDto } from './models/CreateReviewRequestDto';
export { CreateReviewResponseDto } from './models/CreateReviewResponseDto';
export { CreateTokenAlertRequestDto } from './models/CreateTokenAlertRequestDto';
export type { CuratedListPropsDto } from './models/CuratedListPropsDto';

export type { CustomerAddressParams } from './models/CustomerAddressParams';

export type { DayDto } from './models/DayDto';
export type { DeFiProtocolDto } from './models/DeFiProtocolDto';
export { EDataSummaryType } from './models/EDataSummaryType';
export type { EditUserWalletListRequestDto } from './models/EditUserWalletListRequestDto';
export type { EnableOrDisableStrategyAlertDto } from './models/EnableOrDisableStrategyAlertDto';
export { EOADto } from './models/EOADto';
export type { ERC20Transaction } from './models/ERC20Transaction';
export type { Erc20TransferDto } from './models/Erc20TransferDto';
export type { EvmNft } from './models/EvmNft';
export type { ExtendedCuratedWalletListResponse } from './models/ExtendedCuratedWalletListResponse';
export type { Filter } from './models/Filter';
export type { Filtering } from './models/Filtering';
export type { FollowCuratedWalletListRequest } from './models/FollowCuratedWalletListRequest';
export type { FromTokenAddressDto } from './models/FromTokenAddressDto';

export type { GetAllCuratedWalletListResponse } from './models/GetAllCuratedWalletListResponse';
export type { GetBundlesFollowedResponse } from './models/GetBundlesFollowedResponse';
export type { getCoinOptions } from './models/getCoinOptions';
export type { GetCuratedWalletListResponse } from './models/GetCuratedWalletListResponse';

export type { GetERC20MetadataResponseDto } from './models/GetERC20MetadataResponseDto';

export type { GetGasPricesResponseDto } from './models/GetGasPricesResponseDto';
export type { GetLatestMarketDataResponseDto } from './models/GetLatestMarketDataResponseDto';
export type { GetMoneyLineAccessRequestDto } from './models/GetMoneyLineAccessRequestDto';

export type { GetPercentNativeSwapAmountRequestDto } from './models/GetPercentNativeSwapAmountRequestDto';
export type { GetPublicQuestsResponseDto } from './models/GetPublicQuestsResponseDto';
export type { GetPublicStrategyAlertsDto } from './models/GetPublicStrategyAlertsDto';
export type { GetQuoteResponse52Dto } from './models/GetQuoteResponse52Dto';
export type { GetQuoteResponseDto } from './models/GetQuoteResponseDto';
export { GetReviewResponseDto } from './models/GetReviewResponseDto';
export type { GetSwapResponse52Dto } from './models/GetSwapResponse52Dto';
export type { GetSwapResponseDto } from './models/GetSwapResponseDto';

export type { GetTokenExplorerResponseDto } from './models/GetTokenExplorerResponseDto';
export type { GetTokenResponseDto } from './models/GetTokenResponseDto';

export type { GetUserWalletListResponse } from './models/GetUserWalletListResponse';

export type { Hits } from './models/Hits';
export type { HitsResult } from './models/HitsResult';
export type { Holders } from './models/Holders';
export type { HoneypotAnalysisDto } from './models/HoneypotAnalysisDto';
export type { HoneypotResultDto } from './models/HoneypotResultDto';
export type { IBuyers } from './models/IBuyers';
export type { IFilter } from './models/IFilter';
export type { IGetChainsDataSummaryResponse } from './models/IGetChainsDataSummaryResponse';
export type { IGetStrategiesAnalysisResult } from './models/IGetStrategiesAnalysisResult';
export type { IGetStrategyAlertCoinsResponse } from './models/IGetStrategyAlertCoinsResponse';
export type { IGoPlusHolderInfo } from './models/IGoPlusHolderInfo';
export type { IHoneypotApiResponse } from './models/IHoneypotApiResponse';
export type { INotificationData } from './models/INotificationData';
export type { Invoice } from './models/Invoice';

export type { InvoiceSettingsParams } from './models/InvoiceSettingsParams';
export type { IParsedDexToolsApiResponse } from './models/IParsedDexToolsApiResponse';
export type { IParsedGoPlusTokenSecurityResponse } from './models/IParsedGoPlusTokenSecurityResponse';
export type { IRepositoryCoinFilter } from './models/IRepositoryCoinFilter';
export type { IRepositoryFilter } from './models/IRepositoryFilter';
export type { IRepositorySorting } from './models/IRepositorySorting';
export type { ISellers } from './models/ISellers';
export type { ISocialMediaList } from './models/ISocialMediaList';
export type { ISocialMetrics } from './models/ISocialMetrics';
export type { ISorting } from './models/ISorting';
export type { IStrategyCoin } from './models/IStrategyCoin';
export type { ITimeFrame } from './models/ITimeFrame';
export type { ITimeFrame1 } from './models/ITimeFrame1';
export type { ITokenExplorerQuery } from './models/ITokenExplorerQuery';
export type { LiquiditySourcesResponseDto } from './models/LiquiditySourcesResponseDto';
export type { MarketDataRoi } from './models/MarketDataRoi';
export type { Media } from './models/Media';
export type { MediaQuality } from './models/MediaQuality';
export type { MetricsDto } from './models/MetricsDto';
export type { NativeTransferDto } from './models/NativeTransferDto';
export type { NetWorthByChainDto } from './models/NetWorthByChainDto';
export type { NetWorthByWalletAddressDto } from './models/NetWorthByWalletAddressDto';
export type { NFTCollectionDto } from './models/NFTCollectionDto';
export type { NftResult } from './models/NftResult';
export type { NftTransferDto } from './models/NftTransferDto';
export { NonEvmChain } from './models/NonEvmChain';
export type { NormalizedMetadata } from './models/NormalizedMetadata';
export type { NormalizedMetadataDto } from './models/NormalizedMetadataDto';
export type { NotificationConfigDto } from './models/NotificationConfigDto';
export { NotificationDto } from './models/NotificationDto';
export type { NotificationHistoryResponseDto } from './models/NotificationHistoryResponseDto';
export type { ObjectType } from './models/ObjectType';
export type { Pair } from './models/Pair';
export type { Pick_IStrategyCoin_chainId_or_coinAddress_or_buyPrice_ } from './models/Pick_IStrategyCoin_chainId_or_coinAddress_or_buyPrice_';

export type { PlanResponseDto } from './models/PlanResponseDto';
export type { PlanStageDto } from './models/PlanStageDto';
export type { PortfolioWalletsSettings } from './models/PortfolioWalletsSettings';
export type { PositionDetailsDto } from './models/PositionDetailsDto';
export type { PositionDto } from './models/PositionDto';
export type { PositionTokenDto } from './models/PositionTokenDto';
export type { PostTokenExplorerRequestDto } from './models/PostTokenExplorerRequestDto';
export type { PostTokenExplorerResponseDto } from './models/PostTokenExplorerResponseDto';
export type { PresetsInfoResponseDto } from './models/PresetsInfoResponseDto';
export type { ProtocolsDto } from './models/ProtocolsDto';
export type { PublicStrategyAlertsDto } from './models/PublicStrategyAlertsDto';
export type { PutQueryRequestDto } from './models/PutQueryRequestDto';
export type { PutQueryResponseDto } from './models/PutQueryResponseDto';
export { QuerySortBy } from './models/QuerySortBy';
export type { QuestInfoDto } from './models/QuestInfoDto';
export type { QuestItemInfoDto } from './models/QuestItemInfoDto';
export type { QuoteRequestDto } from './models/QuoteRequestDto';
export type { Refund } from './models/Refund';
export type { ReservesTimeline } from './models/ReservesTimeline';
export type { SaleDetailsDto } from './models/SaleDetailsDto';
export type { SaveAddressRequestDto } from './models/SaveAddressRequestDto';
export type { SaveSmartTradingDataRequestDto } from './models/SaveSmartTradingDataRequestDto';
export type { SearchCoinDetails } from './models/SearchCoinDetails';
export type { SearchCoinInfoDto } from './models/SearchCoinInfoDto';
export type { SearchDexToolsDto } from './models/SearchDexToolsDto';
export type { SelectedProtocolDto } from './models/SelectedProtocolDto';
export type { SetAddressDescriptionRequestDto } from './models/SetAddressDescriptionRequestDto';
export type { Shards } from './models/Shards';
export type { SimulationResultDto } from './models/SimulationResultDto';
export type { SmartTradingDataResponseDto } from './models/SmartTradingDataResponseDto';
export { Sorting } from './models/Sorting';
export { SortingDto } from './models/SortingDto';
export type { StrategyCoinResponse } from './models/StrategyCoinResponse';

export type { SwapRequestDto } from './models/SwapRequestDto';
export type { TAddStrategyCoin } from './models/TAddStrategyCoin';
export { TapfiliateAffiliateDto } from './models/TapfiliateAffiliateDto';

export type { TimeFrameDto } from './models/TimeFrameDto';
export type { TimeFrameValue } from './models/TimeFrameValue';
export type { TimeFrameValuesDto } from './models/TimeFrameValuesDto';
export type { Token } from './models/Token';
export type { TokenAlertResponseDto } from './models/TokenAlertResponseDto';
export type { TokenAttribute } from './models/TokenAttribute';
export type { TokenBalanceWithPriceDto } from './models/TokenBalanceWithPriceDto';
export type { TokenInfoDto } from './models/TokenInfoDto';
export type { TokenOwnerDto } from './models/TokenOwnerDto';
export type { TokenResult } from './models/TokenResult';
export type { TokenSale } from './models/TokenSale';
export type { TokensInfoResponseDto } from './models/TokensInfoResponseDto';
export type { Total } from './models/Total';
export type { ToTokenAddressDto } from './models/ToTokenAddressDto';
export type { TrackSwapRequestDto } from './models/TrackSwapRequestDto';
export type { Transaction } from './models/Transaction';
export type { TransactionDataDto } from './models/TransactionDataDto';
export type { TransactionMeta } from './models/TransactionMeta';
export type { TrendingCoinInfoDto } from './models/TrendingCoinInfoDto';
export { TToken } from './models/TToken';
export type { TxDto } from './models/TxDto';
export type { UnFollowCuratedWalletListRequest } from './models/UnFollowCuratedWalletListRequest';
export type { Unit } from './models/Unit';
export type { UnSaveAddressRequestDto } from './models/UnSaveAddressRequestDto';
export type { UpdateQuestItemRequestDto } from './models/UpdateQuestItemRequestDto';
export type { UpdateReviewRequestDto } from './models/UpdateReviewRequestDto';
export { UpdateReviewResponseDto } from './models/UpdateReviewResponseDto';
export { UpdateTokenAlertRequestDto } from './models/UpdateTokenAlertRequestDto';

export type { UserAcademyJwtSsoResponseDto } from './models/UserAcademyJwtSsoResponseDto';
export type { UserAddCoinFavouritesRequestDto } from './models/UserAddCoinFavouritesRequestDto';
export type { UserBeansResponseDto } from './models/UserBeansResponseDto';
export { UserBeanTransactionsResponseDto } from './models/UserBeanTransactionsResponseDto';
export type { UserCheckoutResponseDto } from './models/UserCheckoutResponseDto';
export type { UserChestResponseDto } from './models/UserChestResponseDto';
export type { UserClaimTradeRewardsDto } from './models/UserClaimTradeRewardsDto';
export type { UserClaimTradeRewardsResponseDto } from './models/UserClaimTradeRewardsResponseDto';
export type { UserCoinFavouritesResponseDto } from './models/UserCoinFavouritesResponseDto';
export type { UserConnectRequestDto } from './models/UserConnectRequestDto';
export type { UserConnectResponseDto } from './models/UserConnectResponseDto';
export type { UserCreateCheckoutRequestDto } from './models/UserCreateCheckoutRequestDto';
export type { UserCreateWalletListRequestDto } from './models/UserCreateWalletListRequestDto';
export type { UserItemResponseDto } from './models/UserItemResponseDto';
export type { UserListedPublicPlanResponseDto } from './models/UserListedPublicPlanResponseDto';
export type { UserLoginRequestDto } from './models/UserLoginRequestDto';
export type { UserOpenChestRequestDto } from './models/UserOpenChestRequestDto';
export type { UserOpenChestResponseDto } from './models/UserOpenChestResponseDto';
export type { UserOperationDto } from './models/UserOperationDto';
export type { UserPortalResponseDto } from './models/UserPortalResponseDto';
export type { UserPortfolioWalletsResponseDto } from './models/UserPortfolioWalletsResponseDto';
export type { UserQuestInfoResponseDto } from './models/UserQuestInfoResponseDto';
export type { UserRetrieveInvoiceResponseDto } from './models/UserRetrieveInvoiceResponseDto';
export type { UserStatsResponseDto } from './models/UserStatsResponseDto';
export type { UserUpdateAvatarRequestDto } from './models/UserUpdateAvatarRequestDto';
export { UserUpdatePortfolioWalletsRequestDto } from './models/UserUpdatePortfolioWalletsRequestDto';
export type { UserUpdateSubscriptionRequestDto } from './models/UserUpdateSubscriptionRequestDto';
export type { UserUpdateSubscriptionResponseDto } from './models/UserUpdateSubscriptionResponseDto';
export type { UserVerifyRequestDto } from './models/UserVerifyRequestDto';
export type { UserWalletListDto } from './models/UserWalletListDto';
export type { UserWalletListPropsDto } from './models/UserWalletListPropsDto';
export type { Wallet } from './models/Wallet';
export type { WalletHistoryResultDto } from './models/WalletHistoryResultDto';
export type { WalletsRequestDto } from './models/WalletsRequestDto';
export type { WalletTokenBreakdownDto } from './models/WalletTokenBreakdownDto';
export type { WeekAndDayDto } from './models/WeekAndDayDto';

export { AlertService } from './services/AlertService';
export { DefaultService } from './services/DefaultService';
export { InchService } from './services/InchService';
export { JiffyscanService } from './services/JiffyscanService';
export { PaymasterService } from './services/PaymasterService';
export { UserService } from './services/UserService';
