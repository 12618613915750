import { API } from '@/api';
import { TAuthMethod } from '@/types/auth';
import { createAppStore } from './createAppStore';

export const {
  store: authStore,
  reset: clearAuth,
  createHook: createAuthHook,
} = createAppStore(
  {
    authMethod: null as null | TAuthMethod,
    authToken: null as null | string,
    customer: null as null | API.BillingCoordinator.Comps['Customer'],
    /**
     * This will only be true if the user does not have an email linked to his account
     */
    requireEmailToken: null as null | string,
    isLoadingSocialLogin: false,
  },
  { storeName: 'auth', persist: true },
);

const get = authStore.getState;
const set = authStore.setState;
export const getToken = () => get().requireEmailToken ?? get().authToken;
export const setRequireEmailToken = (token?: string) => set({ requireEmailToken: token });
export const setAuth = (jwt: string, method: TAuthMethod) => set({ authMethod: method, authToken: jwt });
export const setCustomer = (customer: API.BillingCoordinator.Comps['Customer']) => set({ customer });
export const setIsLoadingSocialLogin = (loading: boolean) => set({ isLoadingSocialLogin: loading });
