import { color, Tooltip } from 'moralis-ui';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/react/macro';
import styles from './DataSyncingInfo.module.scss';

export const DataSyncingInfo = () => {
  return (
    <Tooltip
      className={styles.tooltip}
      color="navy"
      content={
        <div className={styles.tooltipContent}>
          <Trans>
            We are still synchronizing data, <br></br>please check back later. <br></br>This could take some time.
          </Trans>
        </div>
      }
      trigger={
        <div className={styles.tooltipTrigger}>
          <FontAwesomeIcon icon={faClock} style={{ width: '12px', height: '12px' }} />
          <p className="caption-16-regular" style={{ color: color.neutral400 }}>
            <Trans>Syncing</Trans>
          </p>
        </div>
      }
    />
  );
};
