import { getChainIconComponent } from '@/assets/img/chains';
import { getChainNameByChainId } from '@/utils/supportedChains';
import { getCryptoLogoProps } from './getCryptoLogoProps';
import styles from './ChainLogo.module.scss';

export const ChainLogo = ({
  chainId,
  className,
  transparent = false,
}: {
  chainId: string;
  className?: string;
  transparent?: boolean;
}) => {
  const IconComponent = getChainIconComponent(chainId);

  return (
    <IconComponent
      className={`${styles.logo} ${className} ignore-global-styles`}
      style={transparent ? undefined : { background: getCryptoLogoProps(getChainNameByChainId(chainId)).bgColor }}
    />
  );
};
