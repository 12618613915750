import { Modal, ModalClose, ModalContent } from '@/components/core/Modal';
import { SelectAlertTypeCards } from './SelectAlertTypeCards';
import { useAlertModalContext } from './useAlertModalContext';
import styles from './modal.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export const SelectAlertToCreateModal = () => {
  const { modalContent, closeAlertModal } = useAlertModalContext();

  if (modalContent !== 'select') return null;

  return (
    <Modal initialOpen={true} onOpenChange={closeAlertModal}>
      <ModalContent className={styles.modal}>
        <div className={styles.header}>
          <h5 className={typography.h2}>Choose Alert Type</h5>
          <ModalClose />
        </div>
        <SelectAlertTypeCards />
      </ModalContent>
    </Modal>
  );
};
