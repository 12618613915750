import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { useUserRewardsService } from '@/hooks/useUserRewardsService';
import { useUserStatsService } from '@/hooks/useUserStatsService';
import { useMenuStore } from '@/stores/useMenuStore';
import { INotificationBanner } from '@/types/notificationBanner';
import { TS } from '@/utils/TS';
import { AsideMenu } from '../aside/AsideMenu/AsideMenu';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { ErrorFeedback } from '../error/ErrorFeedback';
import { AuthModal, BottomMenu, MobileHeaderMenu } from './imports';
import { MetaDataTags } from './MetaDataTags';
import { PageViewTracking } from './PageViewTracking';
import { TrialBanner } from './TrialBanner';
// TODO: (Maxim) add global styles export to moralis-ui
import 'moralis-ui/style.css';
import styles from './Layout.module.scss';
import typography from '@/styles/scss/typography.module.scss';

const SearchV2 = dynamic(() => import('@/components/SearchV2/SearchV2').then((mod) => mod.SearchV2), { ssr: false });
const Footer = dynamic(() => import('@/components/footer/Footer').then((mod) => mod.Footer), { ssr: false });
const StoreDetailDrawer = dynamic(
  () => import('@/components/common/StoreDetailDrawer').then((mod) => mod.StoreDetailDrawer),
  { ssr: false },
);

const mainId = 'scrollableMain';
export const topPositionSpanId = 'mainTopPosition';

type TLayout = 'normal' | '2-column' | 'tokenExp' | 'fullHeight' | 'sidebar';
type TScreenWidth = 'normal' | 'full' | 'wide' | 'tokenExp';

export const Layout = ({
  children,
  customClassName,
  hasFooter = true,
  hasMainMenu = true,
  hasMobileMenu = true,
  hasMobileHeaderMenu = true,
  layout,
  metaTags,
  noPadding = false,
  pageNotification,
  screenWidth = 'normal',
  pageViewProps,
  sidebar,
  title,
}: React.PropsWithChildren<{
  customClassName?: string;
  hasFooter?: boolean;
  hasHeader?: boolean;
  hasMainMenu?: boolean;
  hasMobileMenu?: boolean;
  hasMobileHeaderMenu?: boolean;
  hasNotifications?: boolean;
  hasSearchBar?: boolean;
  hasTwitterBanner?: boolean;
  layout?: TLayout;
  metaTags: React.ReactElement<TS.Arg0<typeof MetaDataTags>, typeof MetaDataTags>;
  noPadding?: boolean;
  pageNotification?: INotificationBanner;
  screenWidth?: TScreenWidth;
  pageViewProps?: Record<string, unknown>;
  title?: string;
  sidebar?: {
    children: React.ReactNode;
    isOpen?: boolean;
    width?: 'sm' | 'md' | 'lg';
    fullScreenOnMobile?: boolean;
    footer?: React.ReactNode;
  };
}>) => {
  const { updateUserStats } = useUserStatsService();
  const { getUserRewards } = useUserRewardsService();

  const isCollapsed = useMenuStore((state) => {
    return state.isCollapsed;
  });

  useEffect(() => {
    updateUserStats();
    getUserRewards();
  }, [children]);

  const getMaxWidth = (layout?: TLayout, screenWidth?: TScreenWidth) => {
    if (layout === 'tokenExp') return styles.maxWidthTokenExp;
    if (layout === 'sidebar') return styles.maxWidthFull;
    if (screenWidth === 'wide') return styles.maxWidthWide;
    if (screenWidth === 'full') return styles.maxWidthFull;
    return styles.maxWidthNormal;
  };

  const getPadding = (layout?: TLayout, screenWidth?: TScreenWidth) => {
    if (layout === 'tokenExp') return styles.paddingTokenExp;
    if (layout === 'sidebar') return styles.paddingSidebar;
    if (screenWidth === 'wide') return styles.paddingWide;
    if (screenWidth === 'full') return styles.paddingFull;
    return styles.paddingNormal;
  };

  const getWidth = (layout?: TLayout, screenWidth?: TScreenWidth) => {
    if (layout === 'tokenExp') return styles.widthTokenExp;
    if (layout === 'sidebar') return styles.widthSidebar;
    if (screenWidth === 'wide') return styles.widthWide;
    if (screenWidth === 'full') return styles.widthFull;
    return styles.WidthNormal;
  };

  // TODO make token-single-twitter-iframe handle customClassName like the others as scss
  return (
    <div
      className={clsx(styles.layout, customClassName, noPadding === true ? styles.noPadding : null, {
        [styles['token-single-twitter-iframe']]: customClassName === 'token-single-twitter-iframe',
      })}
    >
      {metaTags}
      {hasMainMenu && <AsideMenu isCollapsed={isCollapsed} />}
      {hasMobileHeaderMenu && <MobileHeaderMenu />}

      <main
        className={clsx(styles.main, {
          [styles.isCollapsed]: isCollapsed,
          [styles.noMainMenu]: !hasMainMenu,
          [styles.noMainMenu]: !hasMainMenu,
          [styles.noMobileHeaderMenu]: !hasMobileHeaderMenu,
        })}
        id={mainId}
      >
        <span id={topPositionSpanId} />
        <TrialBanner pageNotification={pageNotification} />
        <div
          className={[
            styles.inner,
            getMaxWidth(layout, screenWidth),
            getPadding(layout, screenWidth),
            getWidth(layout, screenWidth),
          ].join(' ')}
        >
          {title && (
            <span className={styles.layoutH1}>
              <h1 className={typography.display1}>{title}</h1>
            </span>
          )}

          <div className={styles.container}>
            {sidebar?.children && (
              <div
                className={clsx(styles.sidebar, styles[sidebar.width || 'md'], {
                  [styles.closed]: layout === 'sidebar' && sidebar.isOpen === false,
                  [styles.mobileFull]: (layout === 'sidebar' && sidebar.fullScreenOnMobile) || false,
                })}
              >
                <ErrorBoundary renderFallback={() => <ErrorFeedback />}>{sidebar.children}</ErrorBoundary>
              </div>
            )}

            <div
              className={clsx(styles.inner, { [styles.innerWithSidebar]: layout === 'sidebar' && sidebar?.children })}
            >
              <ErrorBoundary renderFallback={() => <ErrorFeedback />}>{children}</ErrorBoundary>
            </div>
          </div>
          {sidebar?.footer}
        </div>
        {hasFooter && <Footer />}
        {hasMobileMenu && <BottomMenu />}
      </main>
      <AuthModal />
      <StoreDetailDrawer />

      <SearchV2 />

      <PageViewTracking pageViewProps={pageViewProps} />
    </div>
  );
};

Layout.MetaTags = MetaDataTags;
