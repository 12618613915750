'use client';
import { useEffect } from 'react';
import NextProgress from 'nextjs-progressbar';
import { PopMessageProvider } from 'moralis-ui';
import { SWRConfig } from 'swr';
import { WagmiProvider } from 'wagmi';
import { useAnalyticsLoad } from '@/hooks/useAnalytics';
import { FormResetProvider } from '@/hooks/useFormResetContext';
import { activateLocale, useLinguiInit } from '@/locales/utils';
import { useCoinInfoStore } from '@/stores/useCoinInfoStore';
import { RAINBOW_CONFIG } from '@/utils/wagmi';
import { apolloClient } from '@/utils/wordpress/apolloClient';
import { ApolloProvider } from '@apollo/client/react';
import { i18n, Messages } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AlertModalProvider } from './alertModal/useAlertModalContext';
import { moneyTheme } from './authModal/raindbowKitTheme';
import { DevModeToggle } from './devMode/DevModeToggle';
import { AddWalletModalProvider } from './manageWallets/context/AddWalletContext';
import { CookieScript } from './vendorScripts/CookieScript';
import { Hotjar } from './vendorScripts/Hotjar';
import { Tapfiliate } from './vendorScripts/Tapfiliate';

// Global swr cache settings
const swrCacheSettings = {
  refreshInterval: 300000,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  keepPreviousData: true,
};

export const Providers = ({
  children,
  messages,
  locale,
}: React.PropsWithChildren<{
  messages: Messages;
  locale: string;
}>) => {
  useAnalyticsLoad();
  const isFetching = useCoinInfoStore(({ isFetching }) => isFetching);
  activateLocale(locale);
  useLinguiInit(messages);

  useEffect(() => {
    document.body.classList.toggle('fetching', isFetching);
  }, [isFetching]);

  const queryClient = new QueryClient();

  return (
    <I18nProvider i18n={i18n}>
      <WagmiProvider config={RAINBOW_CONFIG}>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={apolloClient}>
            <SWRConfig value={swrCacheSettings}>
              <AlertModalProvider>
                <FormResetProvider>
                  <AddWalletModalProvider>
                    <RainbowKitProvider theme={moneyTheme}>
                      <PopMessageProvider>
                        <NextProgress />
                        <DevModeToggle>{children}</DevModeToggle>
                      </PopMessageProvider>
                    </RainbowKitProvider>
                  </AddWalletModalProvider>
                </FormResetProvider>
              </AlertModalProvider>
            </SWRConfig>
          </ApolloProvider>
        </QueryClientProvider>
      </WagmiProvider>
      <Tapfiliate />
      <CookieScript />
      <Hotjar />
    </I18nProvider>
  );
};
