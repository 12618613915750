import { IBundleAlertProps } from '@/components/alertModal/BundleAlert.types';
import { IStrategyAlertProps } from '@/components/alertModal/StrategyAlert.types';
import { ITokenAlertProps } from '@/components/alertModal/TokenAlert.types';
import { IDeleteModalProps, useAlertModalContext } from '@/components/alertModal/useAlertModalContext';

export const useAlertModal = () => {
  const { openAlertModal, closeAlertModal } = useAlertModalContext();

  return {
    open: {
      select: () => openAlertModal('select'),
      delete: (props: IDeleteModalProps) => openAlertModal('delete', props),
      token: (props?: ITokenAlertProps) => openAlertModal('token', props),
      strategy: (props?: IStrategyAlertProps) => openAlertModal('strategy', props),
      bundle: (props?: IBundleAlertProps) => openAlertModal('bundle', props),
    },
    close: closeAlertModal,
  };
};
