export const transformAsync =
  <A, T, U>(fn: (arg: A) => Promise<T>, transform: (resp: Promise<T>, arg: A) => U) =>
  async (arg: A) =>
    await transform(fn(arg), arg);

export const transformAsyncThen =
  <A, T, U>(fn: (arg: A) => Promise<T>, transform: (resp: T, arg: A) => U) =>
  async (arg: A) =>
    await fn(arg).then((resp) => transform(resp, arg));

export const transformAsyncCatch =
  <A, T>(fn: (arg: A) => Promise<T>, transform: (err: unknown, arg: A) => T | Promise<T>) =>
  async (arg: A): Promise<T> =>
    await fn(arg).catch((err) => transform(err, arg));
