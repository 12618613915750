import { CoinLogo } from '@/components/common/CoinLogo';
import { getCoinLogoURL } from '@/utils/urls';
import styles from './TokenSearchResultSelected.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export const TokenSearchResultSelected = ({
  address,
  chainId,
  name,
  symbol,
}: {
  address?: string;
  chainId?: string;
  name?: string;
  symbol?: string;
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logoAndDesc}>
        <CoinLogo
          chainId={chainId}
          className={styles.logo}
          name={name || symbol || address || 'N/A'}
          size="xs"
          src={getCoinLogoURL(String(chainId), String(address), undefined, 1)}
        />
        <div>
          <span className={typography.captionMedium}>{name}</span>
        </div>
      </div>
    </div>
  );
};
