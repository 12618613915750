import { useCallback, useEffect } from 'react';
import { DEFAULT_AVATAR, DEFAULT_AVATAR_ID, EItemCategory, EPIC_BEAN_NAME } from '@/components/beans/types';
import { setRewards } from '@/stores/beanStore';
import { apiBrowser } from '@/utils/api/apiBrowser';
import { useAuthToken } from './useAuth';

const fetchUserChests = apiBrowser.user('/user/userChests', 'GET');
const fetchUserItems = apiBrowser.user('/user/userItems', 'GET');

const getUserRewardsRaw = async (authToken: string) => {
  const chests = await fetchUserChests({ authToken });
  const items = await fetchUserItems({ authToken });

  if (items && items.length > 0) DEFAULT_AVATAR.moneyId = items[0].moneyId;
  items.unshift(DEFAULT_AVATAR);

  // Set user avatar
  for (let i = 0; i < items.length; i++) {
    // Unequip default avatar
    if (items[i].itemId === DEFAULT_AVATAR_ID) {
      items[i].properties.isEquipped = false;
    }
  }

  let foundAvatar = false;
  let avatar = DEFAULT_AVATAR;
  for (const item of items) {
    if (item.category.toLowerCase() === EItemCategory.AVATAR && item.properties?.isEquipped) {
      avatar = item;
      foundAvatar = true;
      break;
    }
  }

  if (!foundAvatar) {
    // Equip default avatar
    for (let i = 0; i < items.length; i++) {
      if (items[i].itemId === DEFAULT_AVATAR_ID) {
        items[i].properties.isEquipped = true;
        avatar = items[i];
      }
    }
  }

  // Set epic beans
  let magicBeans: (typeof items)[number] | null = null;
  for (const item of items) {
    if (
      item.category.toLowerCase() === EItemCategory.BEAN &&
      item.name.toLowerCase() === EPIC_BEAN_NAME.toLowerCase()
    ) {
      magicBeans = item;
      break;
    }
  }

  setRewards({
    avatar,
    chests,
    items,
    magicBeans,
  });
};

export const useUserRewardsService = () => {
  const { authToken } = useAuthToken();

  const getUserRewards = useCallback(() => {
    if (!authToken) {
      return;
    }
    getUserRewardsRaw(authToken);
  }, [authToken]);

  useEffect(getUserRewards, [getUserRewards]);
  return { getUserRewards };
};
