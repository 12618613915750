import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';
import MoralisMoneyLogomark from '@/assets/img/MoralisMoneyLogomark.svg';
import MoralisMoneyLogomarkXmas from '@/assets/img/MoralisMoneyLogomarkXmas.svg';
import MoralisMoneyLogotype from '@/assets/img/MoralisMoneyLogotype.svg';
import { ERoutePath } from '@/utils/ERoutePath';
import { isXmasSeason } from '@/utils/isXmasSeason';
import styles from './AsideMenuLogo.module.scss';

export const AsideMenuLogo = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  const altText = 'Moralis - Discover & Trade Undervalued Altcoins';
  return (
    <Link
      href={ERoutePath.home}
      className={clsx(styles.logoLink, { [styles.collapsedLogo]: isCollapsed, [styles.xmas]: isXmasSeason() })}
      data-testid="test-nav-button-home"
    >
      <div className={clsx(styles.logoWrap, { [styles.xmas]: isXmasSeason() })}>
        <Image
          priority
          alt={altText}
          height={26}
          src={isXmasSeason() ? MoralisMoneyLogomarkXmas.src : MoralisMoneyLogomark.src}
          width={isXmasSeason() ? 40 : 34}
        />
        {!isCollapsed && (
          <div className={styles.logotype}>
            <Image priority alt="" height={20.25} src={MoralisMoneyLogotype.src} width={90} />
          </div>
        )}
      </div>
    </Link>
  );
};
