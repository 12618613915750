import { apiBrowser } from '@/utils/api/apiBrowser';
import { SUPPORTED_CHAIN_IDS_WEB3_SERVICE } from '@/utils/supportedChains';

const fetchWalletNetWorth = apiBrowser.web3Proxy('/web3/getWalletsNetWorth', 'POST');

export const getWalletNetWorth = async (addresses: Array<string>) =>
  await fetchWalletNetWorth({
    body: {
      chainIds: SUPPORTED_CHAIN_IDS_WEB3_SERVICE.endpoints.web3ControllerGetWalletsNetWorth,
      addressesOrNames: addresses,
      excludeSpam: true,
      excludeUnverifiedContracts: false,
    },
  });
