import { ControlledInput } from './ControlledInput';
import styles from './col.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export const AlertNameField = () => {
  return (
    <div>
      <div className={styles.col25}>
        <h5 className={typography.bodySmallRegular}>Alert Name</h5>
      </div>
      <div className={styles.col75}>
        <ControlledInput required name="inputName" placeholder="Choose a name for your alert..." />
      </div>
    </div>
  );
};
