import { useCallback } from 'react';
import { Advertisement } from '@/utils/contentful/type';
import { useAnalytics } from './useAnalytics';

export enum AdSource {
  HOME_TRENDING_TOKENS_TABLE = 'Home Trending Tokens Table',
  HOME_BANNER = 'Home Banner',
  TOKEN_PAGE = 'Token Page',
}

type Params = Pick<Advertisement, 'url' | 'title'>;
export type HandleAdClick = (params: Params) => void;

export const useAds = (source: AdSource) => {
  const { trackLinkClick } = useAnalytics();

  const onAdClick: HandleAdClick = useCallback(
    ({ url, title }) => {
      trackLinkClick(`${source} Ad: ${title} (${url})`);
    },
    [source, trackLinkClick],
  );

  return {
    onAdClick,
  };
};
