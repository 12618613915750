import { popMessage, TSelectOption } from 'moralis-ui';
import { FormProvider } from 'react-hook-form';
import { AlertsUsedLabel } from '@/components/alerts/AlertsUsedLabel';
import { ButtonPrimary } from '@/components/core/Button';
import { useAlertModal } from '@/hooks/useAlertModal';
import { useFormHandler } from '@/hooks/useFormHandler';
import { useMagic } from '@/hooks/useMagic';
import { useStrategies } from '@/hooks/useStrategies';
import { useTelegram } from '@/hooks/useTelegram';
import { useUser, useUserStage } from '@/hooks/useUser';
import { useUserAlertsCount } from '@/hooks/useUserAlertsCount';
import { PutQueryResponseDto } from '@/services/index';
import { POP_MESSAGE } from '@/utils/popMessage/constants';
import { AlertScenarioTextVisualization } from './AlertScenarioTextVisualization';
import { CooldownTimeFrameType } from './common.types';
import { FrequencyField } from './FrequencyField';
import { getNotificationPeriodOptions } from './getNotificationPeriodOptions';
import { NotificationsFieldset } from './NotificationsFieldset';
import { IStrategyAlertFormData, IStrategyAlertProps } from './StrategyAlert.types';
import { getFormDefaultValues, getPayload } from './StrategyAlert.utils';
import { StrategyField } from './StrategyField';
import styles from './StrategyAlert.module.scss';

export const strategyNotificationPeriods: TSelectOption[] = [
  {
    id: '10',
    label: '10 minutes (Pro Plan or higher)',
  },
  {
    id: '30',
    label: '30 minutes (Pro Plan or higher)',
  },
  {
    id: '60',
    label: '1 hour (Pro Plan or higher)',
  },
  {
    id: '240',
    label: '4 hour',
  },
  {
    id: '720',
    label: '12 hours',
  },
  {
    id: '1440',
    label: '1 day',
  },
];

export const StrategyAlert = ({ strategy: strategyToEdit }: IStrategyAlertProps) => {
  const user = useUser();
  const { validateTelegram } = useTelegram();
  const { isLimitReached } = useUserAlertsCount();
  const { close: closeModal } = useAlertModal();
  const { updateStrategy, strategies, messages } = useStrategies();
  const { validateEmail } = useMagic();
  const planStage = useUserStage();

  const { isLoading, handleOnSubmit, ...formDefaultMethods } = useFormHandler<
    IStrategyAlertFormData,
    PutQueryResponseDto
  >({
    defaultValues: getFormDefaultValues(user, strategyToEdit),
    onSubmit: async (formData) => {
      if (!selectedStrategy) throw new Error('Alert not found');
      if (formData.toggleProtocolTelegram) {
        formData.inputProtocolTelegram = await validateTelegram(formData.inputProtocolTelegram);
      }
      if (formData.toggleProtocolEmail) {
        formData.inputProtocolEmail = await validateEmail(formData.inputProtocolEmail);
      }
      const payload = getPayload(formData, selectedStrategy);
      return await updateStrategy(payload).then((r) => {
        popMessage({
          message: messages.update(r.name),
          variant: 'success',
          title: 'Strategy updated',
        });
        return r;
      });
    },
    onSuccess: closeModal,
    onError: () => {
      popMessage({
        message: 'An error occurred while updating the strategy',
        variant: 'error',
        title: POP_MESSAGE.error.defaultTitle,
      });
    },
  });

  const selectedStrategy =
    strategyToEdit || strategies?.all?.find((strategy) => strategy?.id === formDefaultMethods.watch('selectStrategy'));

  return (
    <FormProvider {...formDefaultMethods}>
      <form className={styles.form} onSubmit={handleOnSubmit}>
        <StrategyField strategies={strategyToEdit ? [strategyToEdit] : strategies?.noAlert} />
        <FrequencyField
          disabled={Boolean(planStage?.notificationPeriods)}
          options={getNotificationPeriodOptions(strategyNotificationPeriods, planStage?.notificationPeriods)}
        />
        <NotificationsFieldset />
        <div className={styles.footer}>
          <AlertScenarioTextVisualization
            alertType="strategy"
            className={styles.scenario}
            cooldownTimeframe={String(+formDefaultMethods.watch('selectCoolDown') * 60) as CooldownTimeFrameType}
            strategyName={selectedStrategy?.name}
          />
          <AlertsUsedLabel />
          <ButtonPrimary
            className={styles.saveButton}
            disabled={isLimitReached}
            isLoading={isLoading}
            size="xl"
            type="submit"
          >
            {strategyToEdit ? 'Save Changes' : 'Save Strategy Alert'}
          </ButtonPrimary>
        </div>
      </form>
    </FormProvider>
  );
};
