import { useUserAlertsCount } from '@/hooks/useUserAlertsCount';
import { ReachedLimitWarning } from './ReachedLimitWarning';
import styles from './AlertsUsedLabel.module.scss';

export const AlertsUsedLabel = () => {
  const { alertCounts, max } = useUserAlertsCount();

  if (alertCounts?.all >= (max || 0)) {
    return <ReachedLimitWarning />;
  }

  return (
    <div className={styles.wrapper}>
      <span>
        {alertCounts.all} out of {max === Infinity ? 'Unlimited' : max} alerts used
      </span>
    </div>
  );
};
