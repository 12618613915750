/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';

import { AlertService } from './services/AlertService';
import { DefaultService } from './services/DefaultService';
import { InchService } from './services/InchService';
import { JiffyscanService } from './services/JiffyscanService';
import { PaymasterService } from './services/PaymasterService';
import { UserService } from './services/UserService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiServiceClass {
  public readonly alert: AlertService;
  public readonly default: DefaultService;
  public readonly inch: InchService;
  public readonly jiffyscan: JiffyscanService;
  public readonly paymaster: PaymasterService;
  public readonly user: UserService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.alert = new AlertService(this.request);
    this.default = new DefaultService(this.request);
    this.inch = new InchService(this.request);
    this.jiffyscan = new JiffyscanService(this.request);
    this.paymaster = new PaymasterService(this.request);
    this.user = new UserService(this.request);
  }
}
