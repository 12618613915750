import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NotFound } from '@/components/SearchV2/NotFound';
import { SearchResultsList } from '@/components/SearchV2/SearchResultsList';
import { useDebounce } from '@/hooks/useDebounce';
import { useSearchV2 } from '@/hooks/useSearchV2';
import { SUPPORTED_TOKEN_ALERT_CHAIN_IDS } from '@/utils/supportedChains';
import {
  autoUpdate,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { ITokenAlertCoin, ITokenAlertFormData } from './TokenAlert.types';
import { TokenSearchInput } from './TokenSearchInput';
import { TokenSearchResult } from './TokenSearchResult';
import { TokenSearchResultSelected } from './TokenSearchResultSelected';
import commonStyles from './col.module.scss';
import styles from './SelectTokenField.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export const SelectTokenField = ({
  tokenData,
  setTokenData,
  disabled = true,
}: {
  disabled?: boolean;
  setTokenData: (coin: ITokenAlertCoin) => void;
  tokenData?: ITokenAlertCoin;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const debouncedInputValue = useDebounce({ value: inputValue === '' ? undefined : inputValue });
  const { searchResult } = useSearchV2(debouncedInputValue, SUPPORTED_TOKEN_ALERT_CHAIN_IDS);

  const { control } = useFormContext<ITokenAlertFormData>();

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const { refs, context, floatingStyles } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange(isOpen) {
      setIsOpen(isOpen);
      if (!isOpen) {
        setInputValue('');
      }
    },
    transform: false,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
  });

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const focus = useFocus(context, { enabled: !disabled });
  const click = useClick(context, { toggle: false, enabled: !disabled });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getItemProps, getFloatingProps } = useInteractions([role, focus, click, dismiss, listNav]);

  function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputValue(value);
  }

  return (
    <div>
      <div className={commonStyles.col25}>
        <h5 className={typography.bodySmallRegular}>Token</h5>
      </div>
      <div className={commonStyles.col75}>
        <Controller
          control={control}
          name="selectToken"
          render={({ field: { onChange }, fieldState: { invalid } }) => (
            <>
              <div
                {...getReferenceProps()}
                className={`${styles.selectWrapper} ${invalid ? styles.error : ''}`}
                ref={refs.setReference}
              >
                {tokenData && !isOpen && (
                  <TokenSearchResultSelected
                    address={tokenData.address}
                    chainId={tokenData.chainId}
                    name={tokenData.name}
                    symbol={tokenData.symbol}
                  />
                )}

                <TokenSearchInput
                  disabled={disabled}
                  isOpen={isOpen}
                  placeholder={!isOpen && tokenData ? undefined : 'Search by name or paste address'}
                  value={inputValue}
                  onChange={onChangeInput}
                  onClose={() => setIsOpen(false)}
                />
              </div>
              {isOpen && searchResult && (
                <div
                  className={styles.dropdownBody}
                  ref={refs.setFloating}
                  style={floatingStyles}
                  {...getFloatingProps()}
                >
                  <SearchResultsList>
                    {searchResult?.result?.length ? (
                      searchResult?.result.map(
                        (coin, index) =>
                          coin && (
                            // eslint-disable-next-line react/jsx-key
                            <TokenSearchResult
                              {...getItemProps({
                                key: `${coin.chainId}-${coin.coinAddress}`,
                                ref(node) {
                                  listRef.current[index] = node;
                                },
                                onClick() {
                                  setInputValue('');
                                  setTokenData({
                                    address: coin.coinAddress,
                                    chainId: coin.chainId,
                                    symbol: coin.symbol,
                                    name: coin.name,
                                    price: coin.priceUSD,
                                  });
                                  setIsOpen(false);
                                  onChange({ address: coin.coinAddress, chainId: coin.chainId });
                                  refs.domReference.current?.focus();
                                },
                              })}
                              active={activeIndex === index}
                              coin={coin}
                            />
                          ),
                      )
                    ) : (
                      <NotFound value={debouncedInputValue} />
                    )}
                  </SearchResultsList>
                </div>
              )}
            </>
          )}
          rules={{
            required: true,
          }}
        />
      </div>
    </div>
  );
};
