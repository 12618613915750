import { UserWalletListDto } from '@/services/index';
import { t } from '@lingui/core/macro';

export enum EAdditionMethod {
  COINBASE_WALLET = 'Coinbase Wallet',
  METAMASK = 'MetaMask',
  RAINBOW = 'Rainbow',
  WALLET_CONNECT = 'WalletConnect',
  WATCHED = 'Watched',
}

export enum EPortfolioList {
  MY = 'yourPortfolio',
  WATCHED = 'watchedWallets',
}

export const portfolioListToTab = (list: EPortfolioList) =>
  list === EPortfolioList.MY ? t`My Wallets` : t`Watch List`;

// TODO: (Maxim) move type to shared place
export interface UserWallet {
  apiData: UserWalletListDto;
  authMethod?: string;
  balanceUsd: number;
  isLoggedInWallet?: boolean;
  isStatic: boolean;
}
