import { ReactNode } from 'react';
import { ButtonDestructive, Flex, Modal } from 'moralis-ui';
import { useIsMobileDevice } from '@/hooks/useIsMobileDevice';
import { useUserCoinFavourites } from '@/hooks/useUserCoinFavourites';
import { truncate } from '@/utils/fmt';
import { ChainIdString } from '@/utils/supportedChains';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/react/macro';
import { Drawer } from '../Drawer/Drawer';
import styles from './RemoveTokenFollowedModal.module.scss';

export const RemoveTokenFollowedModal = ({
  trigger,
  chainId,
  tokenAddress,
  tokenSymbol,
  tokenName,
}: {
  trigger: ReactNode;
  chainId: ChainIdString;
  tokenAddress: string;
  tokenSymbol: string | undefined;
  tokenName: string | undefined;
}) => {
  const { removeTokenFollowedModalOpen, setRemoveTokenFollowedModalOpen, removeCoinFavourite, isRemoving } =
    useUserCoinFavourites();
  const isMobileDevice = useIsMobileDevice();
  const handleRemove = (chainId: string, tokenAddress: string) => {
    setRemoveTokenFollowedModalOpen(false);
    removeCoinFavourite({ chainId: chainId, tokenAddress: tokenAddress });
  };

  const nonTranslatedName = tokenSymbol || tokenName || truncate(tokenAddress) || <Trans>this</Trans>;

  const modalInnerContent = (
    <p className={styles.modalContent}>
      <Trans>Are you sure you want to remove {nonTranslatedName} from your followed tokens?</Trans>
    </p>
  );
  const modalFooter = (
    <Flex justify="center" width="100%">
      <ButtonDestructive size="sm" onClick={() => handleRemove(chainId, tokenAddress)} disabled={isRemoving}>
        {isRemoving && <FontAwesomeIcon icon={faSpinnerThird} spin />}
        <Trans>Unfollow</Trans>
      </ButtonDestructive>
    </Flex>
  );

  if (isMobileDevice) {
    return (
      <>
        <div
          className={styles.removeTrigger}
          onClick={() => setRemoveTokenFollowedModalOpen(!removeTokenFollowedModalOpen)}
        >
          {trigger}
        </div>
        <Drawer isVisible={removeTokenFollowedModalOpen}>
          {modalInnerContent}
          {modalFooter}
        </Drawer>
      </>
    );
  } else {
    return (
      <Modal
        isVisible={removeTokenFollowedModalOpen}
        trigger={<div className={styles.removeTrigger}>{trigger}</div>}
        width="454px"
        slots={{
          footer: modalFooter,
          header: <Trans>Are Your Sure?</Trans>,
        }}
      >
        {modalInnerContent}
      </Modal>
    );
  }
};
