import { useState } from 'react';
import { useRouter } from 'next/router';
import { popMessage } from 'moralis-ui';
import { useAccount, useDisconnect } from 'wagmi';
import { clearAuth } from '@/stores/authStore';
import { resetBeanState } from '@/stores/beanStore';
import { clearUserStore } from '@/stores/userStore';
import { EOAuth } from '@/types/auth';
import { logApp } from '@/utils/logApp';
import { createMagic } from '@/utils/magic';
import { useAuthToken } from './useAuth';

const log = logApp.create('useLogout');

export const useLogout = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  // set up 3rd party hooks
  const router = useRouter();
  const { isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();

  // set up FE store hooks
  const { authMethod } = useAuthToken();

  // where the magic happens
  const handleLogout = async ({
    redirectUrl,
    notification,
  }: {
    redirectUrl: string | null;
    notification: null | {
      type: 'success' | 'warning' | 'error';
      message: string;
      title: string;
    };
  }) => {
    setIsLoggingOut(true);
    clearAuth();
    clearUserStore();

    if (isConnected) await disconnectAsync();
    if (authMethod === 'magic' || Object.values(EOAuth).includes(authMethod as EOAuth)) {
      await createMagic()
        .user.logout()
        .catch((error) => {
          log.error('[magic.user.logout]', error, { authMethod });
        });
    }

    resetBeanState();

    if (redirectUrl) {
      router.push(redirectUrl);
    }

    if (notification) {
      popMessage({
        variant: notification.type,
        title: notification.title,
        message: notification.message,
      });
    }

    setIsLoggingOut(false);
  };

  return { handleLogout, isLoggingOut };
};
