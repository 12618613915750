import { createContext, useContext, useState } from 'react';
import { TAlertType } from '@/components/alerts/TAlertType';
import { IBundleAlertProps } from './BundleAlert.types';
import { BundleAlertModal } from './BundleAlertModal';
import { DeleteAlertModal } from './DeleteAlertModal';
import { SelectAlertToCreateModal } from './SelectAlertToCreateModal';
import { IStrategyAlertProps } from './StrategyAlert.types';
import { StrategyAlertModal } from './StrategyAlertModal';
import { ITokenAlertProps } from './TokenAlert.types';
import { TokenAlertModal } from './TokenAlertModal';

type TStrategyModalContent = TAlertType | 'select' | 'delete';

type TModalProps = ITokenAlertProps | IStrategyAlertProps | IBundleAlertProps | IDeleteModalProps;

export interface IDeleteModalProps {
  name: string;
  onDelete: () => void;
}

interface IAlertModalContext<TProps = TModalProps> {
  closeAlertModal: () => void;
  modalContent: TStrategyModalContent | undefined;
  modalProps?: TProps;
  openAlertModal: (content?: TStrategyModalContent, props?: TProps) => void;
  setAlertModalContent: (content: TAlertType | undefined) => void;
}

const AlertModalContext = createContext<IAlertModalContext | undefined>(undefined);

export const AlertModalProvider = ({ children }: React.PropsWithChildren) => {
  const [modalContent, setAlertModalContent] = useState<TStrategyModalContent | undefined>();
  const [modalProps, setModalProps] = useState<TModalProps | undefined>();

  const openAlertModal = (content?: TStrategyModalContent, props?: TModalProps) => {
    setModalProps(props);
    setAlertModalContent(content);
  };

  const closeAlertModal = () => {
    setAlertModalContent(undefined);
  };

  const getAlertComponent = () => {
    switch (modalContent) {
      case 'strategy':
        return <StrategyAlertModal />;
      case 'token':
        return <TokenAlertModal />;
      case 'bundle':
        return <BundleAlertModal />;
      case 'delete':
        return <DeleteAlertModal />;
      case 'select':
      default:
        return <SelectAlertToCreateModal />;
    }
  };

  return (
    <AlertModalContext.Provider
      value={{ openAlertModal, closeAlertModal, modalContent, setAlertModalContent, modalProps }}
    >
      {children}
      {getAlertComponent()}
    </AlertModalContext.Provider>
  );
};

/**
 *
 * @important Probably you should not use this hook directly. Use `useAlertModal` instead
 */
export const useAlertModalContext = <TProps = TModalProps,>() => {
  const context = useContext(AlertModalContext as React.Context<IAlertModalContext<TProps> | undefined>);
  if (context === undefined) {
    throw new Error('useAlertModalContext must be used within a AlertModalProvider');
  }
  return context;
};
