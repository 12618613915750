import { CreateTokenAlertRequestDto, UpdateTokenAlertRequestDto } from '@/services/index';
import { User } from '@/stores/userStore';
import { AlertDto, NotificationDto } from '@/utils/api/types';
import { normalizeTelegramUsername } from '@/utils/normalizeTelegramUsername';
import { getValidProtocol } from '@/utils/user/getValidProtocol';
import { ITokenAlertCoin, ITokenAlertFormData } from './TokenAlert.types';

export const getFormDefaultValues = ({
  user,
  alert,
  notification,
  tokenDataInitial,
}: {
  user: User | null;
  alert?: AlertDto;
  notification?: NotificationDto;
  tokenDataInitial?: ITokenAlertCoin;
}): Partial<ITokenAlertFormData> => {
  return {
    inputName: notification?.name || '',
    inputProtocolEmail: notification?.config?.email || user?.email || '',
    inputProtocolTelegram:
      normalizeTelegramUsername(notification?.config?.telegram || getValidProtocol('telegram', user)?.data[0]) || '',
    inputProtocolWebhook: notification?.config?.webhookUrl || '',
    inputValue: Number(alert?.thresholdValue),
    priceChangePercentage: Number((notification as { percentualChange?: number })?.percentualChange) || 0,
    selectCoolDown: alert?.intervalInSeconds || '0',
    selectFilter: alert?.filter || 'priceChange',
    selectOperator: alert?.operator || 'gt',
    selectTimeframe: alert?.timeframe || '1440',
    selectToken:
      tokenDataInitial?.address && tokenDataInitial?.chainId
        ? {
            address: tokenDataInitial?.address,
            chainId: tokenDataInitial?.chainId,
          }
        : undefined,
    toggleProtocolEmail: Boolean(notification?.config?.email),
    toggleProtocolTelegram: Boolean(notification?.config?.telegram),
    toggleProtocolWebhook: Boolean(notification?.config?.webhookUrl),
  };
};

export const getPayload = ({
  alert,
  formData,
  tokenData,
}: {
  alert?: AlertDto;
  formData: ITokenAlertFormData;
  tokenData?: ITokenAlertCoin;
}): UpdateTokenAlertRequestDto | CreateTokenAlertRequestDto => {
  if (formData.selectFilter === 'priceChange') {
    if (tokenData?.price === undefined) {
      throw new Error('Was unable to fetch latest token data. Please try again.');
    }
    formData.selectTimeframe = '1440';
  }

  const config = {
    email: formData.toggleProtocolEmail ? formData.inputProtocolEmail : undefined,
    webhookUrl: formData.toggleProtocolWebhook ? formData.inputProtocolWebhook : undefined,
    telegram: formData.toggleProtocolTelegram ? formData.inputProtocolTelegram : undefined,
    serviceIssuer: 'MoralisMoney',
  };

  const commonPayload = {
    chainId: formData.selectToken.chainId,
    config,
    filter: formData.selectFilter,
    intervalInSeconds: formData.selectCoolDown,
    name: formData.inputName,
    operator: formData.selectOperator,
    thresholdValue: formData.inputValue.toString(),
    timeframe: formData.selectTimeframe,
    percentualChange: formData?.priceChangePercentage ? formData.priceChangePercentage.toString() : undefined,
    tokenName: tokenData?.name,
    topic: 'tempTopic',
    enabled: true,
    tokenAddress: formData.selectToken.address,
  };

  if (alert?.alertId) {
    return {
      ...commonPayload,
      alertId: alert.alertId,
    } as UpdateTokenAlertRequestDto;
  }
  return commonPayload as CreateTokenAlertRequestDto;
};
