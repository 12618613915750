import create from 'zustand';
import { persist } from 'zustand/middleware';
import { EPortfolioList } from '@/components/manageWallets/libs/types';

type TActiveSelection = {
  id: string;
  list: EPortfolioList;
};

export const useAccountSelectorStore = create(
  persist<{
    activeSelection: TActiveSelection | undefined;
    setActiveSelection: (activeSelection: TActiveSelection) => void;

    // Controls account selector tabs
    tabId: EPortfolioList;
    setTabId: (tabId: EPortfolioList) => void;
  }>(
    (set) => ({
      activeSelection: undefined,
      setActiveSelection: (selection: TActiveSelection) => set({ activeSelection: selection }),

      tabId: EPortfolioList.MY,
      setTabId: (tab: EPortfolioList) => set({ tabId: tab }),
    }),
    {
      name: 'mm-account-selector-storage',
    },
  ),
);
