import { useEffect, useState } from 'react';
import { popMessage } from 'moralis-ui'; // Imported popMessage
import { FormProvider, useWatch } from 'react-hook-form';
import { AlertsUsedLabel } from '@/components/alerts/AlertsUsedLabel';
import { ButtonPrimary } from '@/components/core/Button';
import { useAlertModal } from '@/hooks/useAlertModal';
import { useAuthToken } from '@/hooks/useAuth';
import { defaultUseFormErrorHandler, useFormHandler } from '@/hooks/useFormHandler';
import { useMagic } from '@/hooks/useMagic';
import { useTelegram } from '@/hooks/useTelegram';
import { useTokenAlerts } from '@/hooks/useTokenAlerts';
import { useUser } from '@/hooks/useUser';
import { useUserAlertsCount } from '@/hooks/useUserAlertsCount';
import { TokenAlertResponseDto } from '@/services/index';
import { apiBrowser } from '@/utils/api/apiBrowser';
import { POP_MESSAGE } from '@/utils/popMessage/constants';
import { AlertNameField } from './AlertNameField';
import { AlertScenarioTextVisualization } from './AlertScenarioTextVisualization';
import { CooldownTimeFrameType, FilterType, OperatorType, TimeframeType } from './common.types';
import { ConditionFieldset } from './ConditionFieldset';
import { FrequencyField } from './FrequencyField';
import { NotificationsFieldset } from './NotificationsFieldset';
import { SelectTokenField } from './SelectTokenField';
import { ITokenAlertCoin, ITokenAlertFormData, ITokenAlertProps } from './TokenAlert.types';
import { getFormDefaultValues, getPayload } from './TokenAlert.utils';
import styles from './TokenAlert.module.scss';

const fetchCoinsList = apiBrowser.insightsFilter('/coin-filter/coins/get-coins-list', 'POST');

const tokenNotificationPeriods = Object.entries({
  '0': 'Only One Time',
  '5': '5 Seconds',
  '30': '30 Seconds',
  '60': '1 Minute',
  '300': '5 Minutes',
  '3600': '1 Hour',
  '14400': '4 Hours',
  '86400': '1 Day',
}).map(([id, label]) => ({
  id,
  label,
}));

export const TokenAlert = ({ tokenData: tokenDataInitial, alert, notification }: ITokenAlertProps) => {
  const { isLimitReached } = useUserAlertsCount();
  const { validateEmail } = useMagic();
  const { close: closeModal } = useAlertModal();
  const { updateAlert, createAlert, messages } = useTokenAlerts();
  const { validateTelegram } = useTelegram();
  const user = useUser();

  const { isLoading, handleOnSubmit, ...formDefaultMethods } = useFormHandler<
    ITokenAlertFormData,
    TokenAlertResponseDto
  >({
    defaultValues: getFormDefaultValues({
      user,
      alert,
      notification,
      tokenDataInitial,
    }),
    onSubmit: async (formData) => {
      if (formData.toggleProtocolTelegram) {
        formData.inputProtocolTelegram = await validateTelegram(formData.inputProtocolTelegram);
      }
      if (formData.toggleProtocolEmail) {
        formData.inputProtocolEmail = await validateEmail(formData.inputProtocolEmail);
      }

      const payload = getPayload({ alert, formData, tokenData });

      if ('alertId' in payload) {
        return await updateAlert(payload).then((r) => {
          popMessage({
            variant: 'success',
            title: 'Success!',
            message: messages.update(r?.notification?.name),
          });
          return r;
        });
      } else {
        return await createAlert(payload).then((r) => {
          popMessage({
            variant: 'success',
            title: 'Success!',
            message: messages.create(r?.notification?.name),
          });
          return r;
        });
      }
    },
    onSuccess: closeModal,
    onError: (error) =>
      defaultUseFormErrorHandler(error, () => {
        popMessage({
          variant: 'error',
          title: POP_MESSAGE.error.defaultTitle,
          message: POP_MESSAGE.error.defaultMessage,
        });
      }),
  });

  const [selectToken, inputValue, selectCoolDown, selectFilter, selectOperator, selectTimeframe] = useWatch({
    control: formDefaultMethods.control,
    name: ['selectToken', 'inputValue', 'selectCoolDown', 'selectFilter', 'selectOperator', 'selectTimeframe'],
  });

  const [tokenData, setTokenData] = useState<ITokenAlertCoin | undefined>(tokenDataInitial);

  const { authToken } = useAuthToken();
  useEffect(() => {
    if (tokenDataInitial?.price || !selectToken) return;
    const getTokenData = async () => {
      const [response] = await fetchCoinsList({
        authToken,
        body: {
          coinAddresses: [selectToken.address],
          chainId: selectToken.chainId,
        },
      });

      setTokenData({
        address: response.metadata.coinAddress,
        chainId: response.metadata.chainId,
        name: response.metadata.name,
        price: response.metadata.priceUSD,
        symbol: response.metadata.symbol,
      });
    };
    getTokenData();
  }, [selectToken, tokenDataInitial?.price]);

  return (
    <FormProvider {...formDefaultMethods}>
      <form className={styles.form} onSubmit={handleOnSubmit}>
        <AlertNameField />
        <SelectTokenField disabled={Boolean(tokenDataInitial)} setTokenData={setTokenData} tokenData={tokenData} />
        <ConditionFieldset tokenData={tokenData} />
        <FrequencyField options={tokenNotificationPeriods} />
        <NotificationsFieldset />
        <div className={styles.footer}>
          <AlertScenarioTextVisualization
            alertType="token"
            amount={inputValue}
            className={styles.scenario}
            cooldownTimeframe={selectCoolDown as CooldownTimeFrameType}
            filter={selectFilter as FilterType}
            operator={selectOperator as OperatorType}
            timeframe={selectTimeframe as TimeframeType}
            tokenName={tokenData?.name}
          />
          <AlertsUsedLabel />
          <ButtonPrimary
            className={styles.saveButton}
            disabled={!formDefaultMethods.formState.isDirty || isLimitReached}
            isLoading={isLoading}
            size="xl"
            type="submit"
          >
            {notification ? 'Save Changes' : 'Save Token Alert'}
          </ButtonPrimary>
        </div>
      </form>
    </FormProvider>
  );
};
