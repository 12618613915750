import { useState } from 'react';
import { ethers } from 'ethers';
import { useDebounce } from 'moralis-ui';
import { apiBrowser } from '@/utils/api/apiBrowser';
import { isSolanaAddress } from '@/utils/chains';
import { logApp } from '@/utils/logApp';
import { swrHook } from '@/utils/swrHook';
import { addWalletToPortfolio, addWalletToWatchList } from '../libs/addWallet';
import { EAdditionMethod } from '../libs/types';
import { getMergedList } from '../utils/list';
import { TList } from '../utils/organizeWalletsByList';
import { useMultiWalletData } from './useMultiWalletData';

const log = logApp.create('useAddWallet');
const fetchAddressFromEns = apiBrowser.web3Proxy('/web3/getAddressFromEns', 'GET');
const useEnsName = swrHook.create(fetchAddressFromEns.name, fetchAddressFromEns);

export const useAddWallet = () => {
  const { data, mutate } = useMultiWalletData();
  const [isAdding, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedInputValue = useDebounce({ value: searchTerm, delay: 1000 });
  const { isLoading: isLoadingEnsName } = useEnsName(
    !debouncedInputValue || ethers.utils.isAddress(debouncedInputValue) || isSolanaAddress(debouncedInputValue)
      ? null
      : {
          queryParams: { name: debouncedInputValue },
        },
    {
      swr: {
        onSuccess: ({ address }) => setSearchTerm(address),
      },
    },
  );

  const mergedLists = data ? getMergedList(data as TList) : [];

  const addToPortfolio = async (additionMethod: EAdditionMethod, address?: string) => {
    await add(() => addWalletToPortfolio(address || searchTerm, additionMethod)).catch((err) => {
      log.warnError('[addToPortfolio]', err, { searchTerm, address });
    });
  };

  const addToWatchList = async (address?: string) => {
    await add(() => addWalletToWatchList(address || searchTerm)).catch((err) => {
      log.warnError('[addToWatchList]', err, { searchTerm, address });
    });
  };

  const add = async (fetch: () => Promise<void>) => {
    setIsLoading(true);
    await fetch();
    await mutate();
    setSearchTerm('');
    setIsLoading(false);
  };

  return {
    addToPortfolio,
    addToWatchList,
    isAdding,
    mergedLists,
    searchTerm,
    setSearchTerm,
    isLoadingEnsName,
  };
};
