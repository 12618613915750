import { useCallback, useMemo } from 'react';
import { createAuthHook } from '@/stores/authStore';
import { invariant } from '@/utils/invariant';
import { parseMoralisToken } from '@/utils/parseMoralisToken';
import { swrHook } from '@/utils/swrHook';
import { TS } from '@/utils/TS';

export namespace useAuth {
  export type PropName = 'authToken';
  export type Arg = Record<PropName, string | undefined>;
  export type WithArg<T extends object = object> = T & Arg;
}

export const useAuthEmailToken = createAuthHook((s) => s.requireEmailToken);
export const useAuthIsSocial = createAuthHook((s) => s.isLoadingSocialLogin);
export const useAuthCustomer = createAuthHook((s) => s.customer);
const useJWTToken = createAuthHook((s) => s.authToken);
const useAuthMethod = createAuthHook((s) => s.authMethod);

export const useAuthToken = () => {
  const authMethod = useAuthMethod();
  const authToken = useJWTToken() ?? undefined;
  return {
    authToken,
    authMethod,
    isLoggedIn: Boolean(authToken),
    decodedAuthToken: useMemo(() => (authToken ? parseMoralisToken(authToken) : undefined), [authToken]),

    requireAuthToken: useCallback(() => {
      invariant(authToken, 'Auth token is required');
      return authToken;
    }, [authToken]),
  };
};

export const withAuthToken = <T, Arg extends useAuth.Arg>(
  useSWRHook: swrHook.Hook<T, Arg>,
  {
    auth,
  }: {
    /**
     * If auth value is "optional" (default), authToken will be added from auth store
     * If auth value is "required", the request will be made only if authToken is present
     */
    auth?: 'optional' | 'required';
  } = {},
): swrHook.Hook<T, TS.PartialByKey<Arg, useAuth.PropName>> => {
  return (argSrc: TS.PartialByKey<Arg, useAuth.PropName> | null, cfg = {}) => {
    const appAuthToken = useAuthToken();
    const arg = argSrc === null ? null : (Object.assign({ authToken: appAuthToken.authToken }, argSrc) as Arg);
    return useSWRHook(auth === 'required' && arg && !arg.authToken ? null : arg, cfg);
  };
};
