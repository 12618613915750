import { Controller, useFormContext } from 'react-hook-form';
import { IInputProps, Input } from '@/components/core/Input';
import { ITokenAlertFormData } from './TokenAlert.types';

export const ControlledInput = ({
  name,
  required,
  ...props
}: Omit<IInputProps, 'onChange' | 'onFocus' | 'onBlur' | 'value' | 'id' | 'hasError'> & {
  name: keyof ITokenAlertFormData;
  required?: boolean;
}) => {
  const { control } = useFormContext<ITokenAlertFormData>();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid } }) => (
        <Input
          {...props}
          hasError={invalid}
          id={name}
          name={name}
          value={value as string | number}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
      rules={{ required }}
    />
  );
};
