import { formatTokenPrice, getValueOrNA } from '@/utils/fmt';
import { CooldownTimeFrameType, FilterType, OperatorType, TimeframeType } from './common.types';
import styles from './AlertScenarioTextVisualization.module.scss';

const filterTimeframeOptionsMap: { [key: string]: string } = {
  '10': '10m',
  '30': '30m',
  '60': '1H',
  '240': '4H',
  '720': '12H',
  '1440': '1D',
  '2880': '2D',
  '5760': '4D',
  '10080': '1W',
  '20160': '2W',
  '30240': '3W',
  '43200': '1M',
  '129600': '3M',
};

const coolDownTimeframeOptionsMap: { [key: string]: string } = {
  '0': 'Only one time',
  '5': '5 Seconds',
  '30': '30 Seconds',
  '60': '1 Minute',
  '300': '5 Minutes',
  '600': '10 Minutes',
  '1800': '30 Minutes',
  '3600': '1 Hour',
  '14400': '4 Hours',
  '43200': '12 Hours',
  '86400': 'Day',
};

const filterOptionsMap: { [key: string]: string } = {
  experiencedNetBuyersChange: 'Experienced Net Buyers',
  netBuyersChange: 'Net Buyers',
  netVolumeChangeUSD: 'Buy Pressure Change USD',
  priceChange: 'Price',
};

const operatorOptionsMap: { [key: string]: string } = {
  lt: 'Less Than',
  gt: 'Greater Than',
};

type AlertType = 'token' | 'strategy' | 'bundle';

interface IAlertScenarioVisualizationProps {
  alertType: AlertType;
  className?: string;
}

interface TokenAlertProps extends IAlertScenarioVisualizationProps {
  alertType: 'token';
  amount?: number;
  cooldownTimeframe?: CooldownTimeFrameType;
  filter?: FilterType;
  operator?: OperatorType;
  timeframe?: TimeframeType;
  tokenName?: string;
}

interface BundleAlertProps extends IAlertScenarioVisualizationProps {
  alertType: 'bundle';
  bundleName?: string;
}

interface StrategyAlertProps extends IAlertScenarioVisualizationProps {
  alertType: 'strategy';
  cooldownTimeframe?: CooldownTimeFrameType;
  strategyName?: string;
}

/**
 * @deprecated
 */
export const AlertScenarioTextVisualization = (props: TokenAlertProps | BundleAlertProps | StrategyAlertProps) => {
  let content: React.ReactNode;
  switch (props.alertType) {
    case 'token':
      content = renderTokenTextVisualization(props as TokenAlertProps);
      break;
    case 'bundle':
      content = renderBundleTextVisualization(props as BundleAlertProps);
      break;
    case 'strategy':
      content = renderStrategyTextVisualization(props as StrategyAlertProps);
      break;
  }

  return <div className={`${styles.alertScenarioTextVisualization} ${props.className || ''}`}>{content}</div>;
};

export const HighlightedText = ({ children }: React.PropsWithChildren) => (
  <span className={styles.highlightedText}>{children}</span>
);

function renderTokenTextVisualization({
  amount,
  cooldownTimeframe,
  filter,
  operator,
  timeframe,
  tokenName,
}: TokenAlertProps) {
  const cooldownValue = getValueOrNA(coolDownTimeframeOptionsMap[cooldownTimeframe!]);
  const filterValue = getValueOrNA(filterOptionsMap[filter!]);
  const operatorValue = getValueOrNA(operatorOptionsMap[operator!]);
  const timeframeValue = getValueOrNA(filterTimeframeOptionsMap[timeframe!]);
  const isDollarValue = filter === 'netVolumeChangeUSD' || filter === 'priceChange';

  return (
    <>
      You will be alerted every <HighlightedText>{cooldownValue}</HighlightedText> when{' '}
      <HighlightedText>{filterValue}</HighlightedText> for <HighlightedText>{getValueOrNA(tokenName)}</HighlightedText>{' '}
      is <HighlightedText>{operatorValue}</HighlightedText>{' '}
      <HighlightedText>{isDollarValue ? formatTokenPrice(amount) : getValueOrNA(amount)}</HighlightedText> in the last{' '}
      <HighlightedText>{timeframeValue}</HighlightedText>
    </>
  );
}

function renderBundleTextVisualization({ bundleName }: BundleAlertProps) {
  return (
    <>
      You will be alerted each time a wallet in <HighlightedText>{getValueOrNA(bundleName)}</HighlightedText> buys or
      sells a token
    </>
  );
}

function renderStrategyTextVisualization({ cooldownTimeframe, strategyName }: StrategyAlertProps) {
  const cooldownValue = getValueOrNA(coolDownTimeframeOptionsMap[cooldownTimeframe!]);
  return (
    <>
      Every <HighlightedText>{cooldownValue}</HighlightedText> you will get a notification with all the new coins that
      your strategy <HighlightedText>{getValueOrNA(strategyName)}</HighlightedText> has found you
    </>
  );
}
