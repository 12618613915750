import { isAddress } from 'viem';
import { PublicKey } from '@solana/web3.js';

export const chainById: Record<
  string,
  {
    chainId: string;
    name: string;
    tokenDecimals: number;
    tokenName: string;
    tokenSymbol: string;
  }
> = {
  1: {
    name: 'ethereum',
    chainId: '1',
    tokenName: 'Ethereum',
    tokenSymbol: 'ETH',
    tokenDecimals: 18,
  },
  3: {
    name: 'ropsten',
    chainId: '3',
    tokenName: 'Ethereum',
    tokenSymbol: 'ETH',
    tokenDecimals: 18,
  },
  4: {
    name: 'rinkeby',
    chainId: '4',
    tokenName: 'Ethereum',
    tokenSymbol: 'ETH',
    tokenDecimals: 18,
  },
  5: {
    name: 'goerli',
    chainId: '5',
    tokenName: 'Ethereum',
    tokenSymbol: 'ETH',
    tokenDecimals: 18,
  },
  42: {
    name: 'kovan',
    chainId: '42',
    tokenName: 'Ethereum',
    tokenSymbol: 'ETH',
    tokenDecimals: 18,
  },
  56: {
    name: 'bsc',
    chainId: '56',
    tokenName: 'Binance chain',
    tokenSymbol: 'BNB',
    tokenDecimals: 18,
  },
  65: {
    name: 'okex-testnet',
    chainId: '65',
    tokenName: 'OKEx',
    tokenSymbol: 'OKT',
    tokenDecimals: 18,
  },
  66: {
    name: 'okex',
    chainId: '66',
    tokenName: 'OKEx',
    tokenSymbol: 'OKT',
    tokenDecimals: 18,
  },
  88: {
    name: 'tomochain',
    chainId: '88',
    tokenName: 'TomoChain',
    tokenSymbol: 'TOMO',
    tokenDecimals: 18,
  },
  89: {
    name: 'tomochain-testnet',
    chainId: '89',
    tokenName: 'TomoChain',
    tokenSymbol: 'TOMO',
    tokenDecimals: 18,
  },
  97: {
    name: 'bsc-testnet',
    chainId: '97',
    tokenName: 'Binance chain',
    tokenSymbol: 'BNB',
    tokenDecimals: 18,
  },
  100: {
    name: 'xdai',
    chainId: '100',
    tokenName: 'xDai',
    tokenSymbol: 'xDAI',
    tokenDecimals: 18,
  },
  108: {
    name: 'thundercore',
    chainId: '108',
    tokenName: 'ThunderCore',
    tokenSymbol: 'TT',
    tokenDecimals: 18,
  },
  109: {
    name: 'thundercore-testnet',
    chainId: '109',
    tokenName: 'ThunderCore',
    tokenSymbol: 'TT',
    tokenDecimals: 18,
  },
  121: {
    name: 'fuse-testnet',
    chainId: '121',
    tokenName: 'Fuse',
    tokenSymbol: 'FUSE',
    tokenDecimals: 18,
  },
  122: {
    name: 'fuse',
    chainId: '122',
    tokenName: 'Fuse',
    tokenSymbol: 'FUSE',
    tokenDecimals: 18,
  },
  128: {
    name: 'huobi',
    chainId: '128',
    tokenName: 'Huobi',
    tokenSymbol: 'HT',
    tokenDecimals: 18,
  },
  137: {
    name: 'polygon',
    chainId: '137',
    tokenName: 'Polygon',
    tokenSymbol: 'MATIC',
    tokenDecimals: 18,
  },
  246: {
    name: 'energyweb',
    chainId: '246',
    tokenName: 'Energy Web',
    tokenSymbol: 'EWT',
    tokenDecimals: 18,
  },
  250: {
    name: 'fantom',
    chainId: '250',
    tokenName: 'Fantom',
    tokenSymbol: 'FTM',
    tokenDecimals: 18,
  },
  256: {
    name: 'huobi-testnet',
    chainId: '256',
    tokenName: 'Huobi',
    tokenSymbol: 'HT',
    tokenDecimals: 18,
  },
  1285: {
    name: 'moonbeam-testnet',
    chainId: '1285',
    tokenName: 'Moonbeam',
    tokenSymbol: 'DEV',
    tokenDecimals: 18,
  },
  1287: {
    name: 'moonbeam',
    chainId: '1287',
    tokenName: 'Moonbeam',
    tokenSymbol: 'DEV',
    tokenDecimals: 18,
  },
  4002: {
    name: 'fantom-testnet',
    chainId: '4002',
    tokenName: 'Fantom',
    tokenSymbol: 'FTM',
    tokenDecimals: 18,
  },
  4689: {
    name: 'iotex',
    chainId: '4689',
    tokenName: 'IoTeX',
    tokenSymbol: 'IOTX',
    tokenDecimals: 18,
  },
  4690: {
    name: 'iotex-testnet',
    chainId: '4690',
    tokenName: 'IoTeX',
    tokenSymbol: 'IOTX',
    tokenDecimals: 18,
  },
  42161: {
    name: 'arbitrum',
    chainId: '42161',
    tokenName: 'Arbitrum',
    tokenSymbol: 'ARB',
    tokenDecimals: 18,
  },
  42220: {
    name: 'celo',
    chainId: '42220',
    tokenName: 'Celo',
    tokenSymbol: 'CELO',
    tokenDecimals: 18,
  },
  43113: {
    name: 'avalanche-testnet',
    chainId: '43113',
    tokenName: 'Avalanche',
    tokenSymbol: 'AVAX',
    tokenDecimals: 18,
  },
  43114: {
    name: 'avalanche',
    chainId: '43114',
    tokenName: 'Avalanche',
    tokenSymbol: 'AVAX',
    tokenDecimals: 18,
  },
  44787: {
    name: 'celo-testnet',
    chainId: '44787',
    tokenName: 'Celo',
    tokenSymbol: 'CELO',
    tokenDecimals: 18,
  },
  73799: {
    name: 'web3',
    chainId: '73799',
    tokenName: 'Web3',
    tokenSymbol: 'WEB3',
    tokenDecimals: 18,
  },
  73800: {
    name: 'web3-testnet',
    chainId: '73800',
    tokenName: 'Web3',
    tokenSymbol: 'WEB3',
    tokenDecimals: 18,
  },
  80001: {
    name: 'polygon-testnet',
    chainId: '80001',
    tokenName: 'Polygon',
    tokenSymbol: 'MATIC',
    tokenDecimals: 18,
  },
  421611: {
    name: 'arbitrum-testnet',
    chainId: '421611',
    tokenName: 'Arbitrum',
    tokenSymbol: 'ARB',
    tokenDecimals: 18,
  },
  1666600000: {
    name: 'harmony',
    chainId: '1666600000',
    tokenName: 'Harmony',
    tokenSymbol: 'ONE',
    tokenDecimals: 18,
  },
  1666700000: {
    name: 'harmony-testnet',
    chainId: '1666700000',
    tokenName: 'Harmony',
    tokenSymbol: 'ONE',
    tokenDecimals: 18,
  },
  11297108109: {
    name: 'palm-testnet',
    chainId: '11297108109',
    tokenName: 'Palm',
    tokenSymbol: 'PALM',
    tokenDecimals: 18,
  },
} as const;

export const isSolanaAddress = (address: string) => {
  try {
    new PublicKey(address);
    return true;
  } catch {
    return false;
  }
};

export const isETHAddress = (address: string) => isAddress(address);
