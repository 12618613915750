import { useAccount } from 'wagmi';
import { EPortfolioList, UserWallet } from '@/components/manageWallets/libs/types';
import { useAuthToken } from '@/hooks/useAuth';
import { TAuthMethod } from '@/types/auth';
import { parseMoralisToken } from '@/utils/parseMoralisToken';

const createWalletAPIData = (address: string, additionMethod: string) => {
  return {
    address,
    settings: {
      additionMethod,
      currentListName: EPortfolioList.MY,
      includeInAggregateView: true,
    },
  } as UserWallet['apiData'];
};

const getConnWallet = (walletConnectedToTheApp: string, connName = 'Connected Wallet') => {
  return {
    isStatic: true,
    balanceUsd: 0,
    isLoggedInWallet: false,
    authMethod: undefined,
    apiData: createWalletAPIData(walletConnectedToTheApp, connName),
  };
};

const getAuthWallet = (authToken: string, authMethod: TAuthMethod) => {
  const { address } = parseMoralisToken(authToken);
  return {
    isStatic: true,
    balanceUsd: 0,
    isLoggedInWallet: true,
    authMethod,
    apiData: createWalletAPIData(address, 'Logged in'),
  };
};

export const useUserWallets = () => {
  const { authToken, authMethod } = useAuthToken();
  const acc = useAccount();

  const authWallet: UserWallet | null = authToken && authMethod ? getAuthWallet(authToken, authMethod) : null;
  let connWallet: UserWallet | null = acc.address ? getConnWallet(acc.address, acc.connector?.name) : null;

  // If the connected wallet is the same as the auth wallet, we don't need to show it twice
  if (authWallet && authWallet.apiData.address === acc.address) {
    connWallet = null;
  }

  const userWallets: UserWallet[] = [];
  // Logged in first
  if (authWallet) {
    userWallets.push(authWallet);
  }
  if (connWallet) {
    userWallets.push(connWallet);
  }
  return userWallets;
};
