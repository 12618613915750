import Link from 'next/link';
import { useAnalytics } from '@/hooks/useAnalytics';
import color from '@/styles/color';
import { LINKS } from '@/utils/links';
import { ButtonPrimary } from '../core/Button';
import styles from './styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export const ErrorFeedback = ({
  buttonText = 'Reload page',
  feedback = 'You can reach out for help via:',
  helpCenter = 'Moralis Help Center',
  text = 'Sorry, something went wrong',
  title = 'Well this is embarrassing!',
}: {
  buttonText?: string;
  feedback?: string;
  helpCenter?: string;
  text?: string;
  title?: string;
}) => {
  const { trackButtonClick } = useAnalytics();
  return (
    <div className={styles.errorWrap}>
      <h1 className={typography.h1} color={color.moneyWhite} style={{ marginBottom: '16px' }}>
        {title}
      </h1>
      <p className={typography.body} color={color.moneyWhite} style={{ marginBottom: '16px' }}>
        {text}
      </p>
      <p className={typography.body} color={color.moneyWhite} style={{ marginBottom: '2px' }}>
        {feedback}
      </p>

      <div className={styles.errorLinks}>
        <Link
          className={typography.captionRegular}
          href={LINKS.discord}
          target="_blank"
          onClick={() => trackButtonClick('Error Boundary Discord Button')}
        >
          {helpCenter}
        </Link>
      </div>

      <ButtonPrimary
        onClick={async () => {
          window.location.reload();
          trackButtonClick('Error Boundary Reload Button');
        }}
      >
        {buttonText}
      </ButtonPrimary>
    </div>
  );
};
