import { useWatch } from 'react-hook-form';
import { useUserStage } from '@/hooks/useUser';
import { RX_EMAIL } from '@/utils/regex';
import { getUserAllowedAlertProtocols } from '../alerts/getUserAllowedAlert';
import { ToggleableInput } from './ToggleableInput';
import commonStyles from './col.module.scss';
import styles from './NotificationsFieldset.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export const NotificationsFieldset = () => {
  const allowedProtocols = getUserAllowedAlertProtocols(useUserStage());
  const protocolToggles: boolean[] = useWatch({
    name: ['toggleProtocolEmail', 'toggleProtocolTelegram', 'toggleProtocolWebhook'],
  });

  const isAtLeastOneChecked = protocolToggles.some(Boolean);
  return (
    <div>
      <div className={commonStyles.col25}>
        <h5 className={typography.bodySmallRegular} style={{ lineHeight: '35px' }}>
          Notifications
        </h5>
      </div>

      <div className={commonStyles.col75}>
        <div className={styles.content}>
          <ToggleableInput
            disabled={allowedProtocols.email === false}
            inputName="inputProtocolEmail"
            label="Email"
            placeholder="Enter an email..."
            required={!isAtLeastOneChecked}
            toggleName="toggleProtocolEmail"
            validate={(value) => RX_EMAIL.test(value)}
          />
          <ToggleableInput
            disabled={allowedProtocols.telegram === false}
            inputName="inputProtocolTelegram"
            label="Telegram"
            placeholder="Enter a telegram username..."
            toggleName="toggleProtocolTelegram"
          />
          <ToggleableInput
            disabled={!allowedProtocols.webhook}
            inputName="inputProtocolWebhook"
            label="Webhook"
            placeholder="Enter a webhook url..."
            toggleName="toggleProtocolWebhook"
          />
        </div>
      </div>
    </div>
  );
};
