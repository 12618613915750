import { Controller, useFormContext } from 'react-hook-form';
import { Select, TSelectOption } from '@/components/core/Select';
import { IBundleAlertFormData } from './BundleAlert.types';
import { IStrategyAlertFormData } from './StrategyAlert.types';
import { ITokenAlertFormData } from './TokenAlert.types';

type TTokenAlertSelectName = keyof Pick<
  ITokenAlertFormData,
  'selectCoolDown' | 'selectFilter' | 'selectOperator' | 'selectTimeframe' | 'selectToken'
>;
type TStrategyAlertSelectName = keyof Pick<IStrategyAlertFormData, 'selectStrategy'>;
type TBundleAlertSelectName = keyof Pick<IBundleAlertFormData, 'selectBundle'>;

export const ControlledSelect = ({
  name,
  required = true,
  options,
  placeholder,
}: {
  name: TTokenAlertSelectName | TStrategyAlertSelectName | TBundleAlertSelectName;
  options: TSelectOption[];
  placeholder: string;
  required?: boolean;
}) => {
  const { control } = useFormContext<ITokenAlertFormData | IStrategyAlertFormData | IBundleAlertFormData>();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid } }) => (
        <Select
          hasError={invalid}
          id={name}
          name={name}
          options={options}
          placeholder={placeholder}
          value={value as string | number}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
      rules={{ required }}
    />
  );
};
