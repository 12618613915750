import { Modal, ModalClose, ModalContent } from '@/components/core/Modal';
import { TokenAlert } from './TokenAlert';
import { ITokenAlertProps } from './TokenAlert.types';
import { useAlertModalContext } from './useAlertModalContext';
import styles from './modal.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export const TokenAlertModal = () => {
  const { modalProps, closeAlertModal, modalContent } = useAlertModalContext<ITokenAlertProps>();

  if (modalContent !== 'token') return null;

  return (
    <Modal initialOpen={true} onOpenChange={closeAlertModal}>
      <ModalContent className={styles.modal}>
        <div className={styles.header}>
          <h5 className={typography.h3}>{modalProps?.notification ? 'Edit' : 'Create'} Token Alert</h5>
          <ModalClose />
        </div>
        <TokenAlert
          alert={modalProps?.alert}
          notification={modalProps?.notification}
          tokenData={modalProps?.tokenData}
        />
      </ModalContent>
    </Modal>
  );
};
