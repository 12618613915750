import Link from 'next/link';
import color from '@/styles/color';
import { ERoutePath } from '@/utils/ERoutePath';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ReachedLimitWarning.module.scss';

export const ReachedLimitWarning = ({ className = '' }: { className?: string }) => {
  return (
    <div className={`${styles.wrapper_limit} ${className}`}>
      <div className={styles.inner}>
        <FontAwesomeIcon color={color.moneyRed40} icon={faExclamationCircle} style={{ width: '16px' }} />
        <span>You’ve reached your alerts limit!</span>
      </div>
      <Link className={styles.link} href={ERoutePath.manageSubscription} target="_blank">
        Upgrade
      </Link>
    </div>
  );
};
