import clsx from 'clsx';
import { Flex, Skeleton, Table, TableCell, Tooltip } from 'moralis-ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { TrendingCoinInfoDto } from '@/utils/api/types';
import { Advertisement, TGlossaryItem } from '@/utils/contentful/type';
import { SortOrder } from '@/utils/sorting';
import { SortableColumnsTrending } from '@/utils/trending/trendingTokens';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { TableSortIcon } from '../common/TableSortIcon';
import { TrendingPageUITableBody } from './TrendingPageUITableBody';
import { TrendingTableLoadingSkeleton } from './TrendingTableLoadingSkeleton';
import { COLUMNS_PER_ROW, ITEMS_PER_PAGE } from './useTrending';
import styles from './TrendingPageUI.module.scss';
import tableTokenStyle from './TrendingPageUITableBody.module.scss';
import skeletonStyles from './TrendingTableLoadingSkeleton.module.scss';

const renderTooltip = (description: string) =>
  description && (
    <span className={tableTokenStyle.tokenTableHeaderTooltip}>
      <Tooltip
        // Quick temporary fix added. TODO: Remove when the new stable layout is implemented.
        style={{ zIndex: '30' }}
        color="navy"
        content={description}
        position="top"
        size="md"
        trigger={<FontAwesomeIcon icon={faCircleInfo} width={12} />}
      />
    </span>
  );

export const TrendingTable = ({
  currentPage,
  itemsPerPage,
  rows,
  sortingColumn,
  sortingOrder,
  onSortChange,
  glossaryMoneyMetricTerms,
  ads,
  hasMoreData,
  onChangePageNumber,
  isTrendingTokenDataLoading,
}: {
  currentPage: number;
  itemsPerPage: number;
  rows: TrendingCoinInfoDto[];
  sortingColumn: SortableColumnsTrending;
  sortingOrder: SortOrder;
  onSortChange: (column: SortableColumnsTrending) => void;
  glossaryMoneyMetricTerms: TGlossaryItem[];
  ads: Advertisement[];
  hasMoreData: boolean;
  onChangePageNumber: () => void;
  isTrendingTokenDataLoading?: boolean;
}) => {
  const getDescriptionByItem = (itemName: string) => {
    const term = glossaryMoneyMetricTerms.find((term) => term.item?.toLowerCase() === itemName.toLowerCase());
    return term?.description || '';
  };
  const hasFollowedButton = useFeatureFlag('trending_followed');

  const renderSortableCell = (label: string, column: SortableColumnsTrending, isLoading = false, itemName?: string) => {
    const description = itemName ? getDescriptionByItem(itemName) : '';

    return (
      <TableCell align="left" aria-label={column} variant="headerCell">
        <span className={styles.tokenTableHeader}>
          {renderTooltip(description)}
          <span
            className={clsx('caption-14-medium', styles.tokenTableHeaderFilters)}
            onClick={() => !isLoading && onSortChange(column)}
          >
            {label}
            <TableSortIcon isSelected={column === sortingColumn} isAsc={sortingOrder === SortOrder.ASC} />
          </span>
        </span>
      </TableCell>
    );
  };

  const renderNoSortableCell = (label: string, itemName?: string) => {
    const description = itemName ? getDescriptionByItem(itemName) : '';

    return (
      <TableCell align="left" aria-label={label} variant="headerCell">
        <span className={styles.tokenTableHeader}>
          {renderTooltip(description)}
          <span className={clsx('caption-14-medium', styles.tokenTableHeaderFilters)}>{label}</span>
        </span>
      </TableCell>
    );
  };

  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={onChangePageNumber}
      hasMore={hasMoreData}
      loader={<TrendingTableLoadingSkeleton />}
    >
      <Table
        transparent
        className={styles.mainPageTable}
        hasBorder={false}
        size="md"
        tbodyCells={
          <TrendingPageUITableBody
            ads={ads}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            trendingTokenPage={rows}
            isTrendingTokenDataLoading={isTrendingTokenDataLoading}
          />
        }
        theadCells={
          <tr>
            <TableCell align="left" aria-label="tokenInfo" className={tableTokenStyle.fieldToken} variant="headerCell">
              <span className={clsx('caption-14-medium', styles.tokenTableHeader)}>
                <Trans context="Header label for the token column in the table"># Token</Trans>
              </span>
            </TableCell>
            {renderSortableCell(
              t({
                message: 'Price (24H)',
                context: 'Price percentage change over the last 24 hours',
              }),
              SortableColumnsTrending.price24HChange,
              isTrendingTokenDataLoading,
              t({
                message: 'Price Percent Change',
                context: 'Describes how the price has changed in percentage terms',
              }),
            )}
            {renderSortableCell(
              t({
                message: 'FDV',
                context: 'Fully Diluted Valuation',
              }),
              SortableColumnsTrending.fullyDilutedValuation,
              isTrendingTokenDataLoading,
              t({
                message: 'Fully Diluted Valuation',
                context: 'Estimated market cap if all tokens are in circulation',
              }),
            )}
            {renderSortableCell(
              t({
                message: 'Coin Age',
                context: 'Age of the cryptocurrency or token since its launch',
              }),
              SortableColumnsTrending.coinAge,
              isTrendingTokenDataLoading,
              t({
                message: 'Coin Age',
                context: 'Refers to how long the coin has existed',
              }),
            )}
            {renderSortableCell(
              t({
                message: 'Vol (24H)',
                context: 'Volume traded in the last 24 hours',
              }),
              SortableColumnsTrending.volume24H,
              isTrendingTokenDataLoading,
              t({
                message: 'Total Volume',
                context: 'Total trading volume in USD over the last 24 hours',
              }),
            )}
            {renderSortableCell(
              t({
                message: 'Buy Pressure (24H)',
                context: 'Buy pressure percentage in the last 24 hours',
              }),
              SortableColumnsTrending.buyPressure,
              isTrendingTokenDataLoading,
              t({
                message: 'Buy Pressure',
                context: 'Indicator of buy demand over sell demand in the last 24 hours',
              }),
            )}
            {renderSortableCell(
              t({
                message: 'Followers',
                context: 'Number of Twitter followers for the token',
              }),
              SortableColumnsTrending.twitterFollowerCount,
              isTrendingTokenDataLoading,
              t({
                message: 'Social Media Metrics',
                context: 'Metrics related to the social media popularity of the token',
              }),
            )}
            {renderNoSortableCell(
              t({
                message: 'Categories',
                context: 'Categories the token belongs to',
              }),
              t({
                message: 'Category',
                context: 'Token classification or group',
              }),
            )}
            {renderSortableCell(
              t({
                message: 'Security',
                context: 'Security score of the token',
              }),
              SortableColumnsTrending.moralisScore,
              isTrendingTokenDataLoading,
              t({
                message: 'Security Score',
                context: 'Assessment of the token’s security rating',
              }),
            )}
            {hasFollowedButton && <TableCell aria-label={t`Actions`} variant="headerCell"></TableCell>}
          </tr>
        }
      />
    </InfiniteScroll>
  );
};

export const TrendingTableLoading = () => {
  return Array.from(Array(ITEMS_PER_PAGE).keys()).map((key) => (
    <tr key={key}>
      {Array.from(Array(COLUMNS_PER_ROW).keys()).map((colIndex) => {
        if (colIndex === 0) {
          return (
            <TableCell key={colIndex} align="left">
              <Flex direction="row" justify="flex-start" spacing={12} shrink={0} align="center">
                <Skeleton type="circle" className={clsx(skeletonStyles.skeleton, skeletonStyles.circle)} />
                <Flex direction="column" grow={2} spacing={4} align="start" width="80%">
                  <Skeleton type="rectangle" className={clsx(skeletonStyles.skeleton, skeletonStyles.name)} size="md" />
                  <Skeleton
                    type="rectangle"
                    className={clsx(skeletonStyles.skeleton, skeletonStyles.chain)}
                    size="xs"
                  />
                </Flex>
              </Flex>
            </TableCell>
          );
        }

        return (
          <TableCell key={colIndex} align="right">
            <Skeleton type="rectangle" className={skeletonStyles.skeleton} />
          </TableCell>
        );
      })}
    </tr>
  ));
};
