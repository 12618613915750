import { API } from '@/api';

export enum EPortfolioView {
  HISTORY = 'history',
  NFTS = 'nfts',
  NFT = 'nft',
  NFT_DETAILS = 'nft-details',
  TOKENS = 'tokens',
  NFTS_COLLECTIONS = `${EPortfolioView.NFTS}/collections`,
  AGGREGATE = 'aggregate',
  SUGGESTIONS = 'suggestions',
}

export enum ENavigationIDs {
  HOME = '1',
  NFTS = '3',
  NFT_DETAILS = '4',
  TOKENS = '2',
}

export enum ESortNftByPriceOrName {
  NAME = '0',
  PRICE = '1',
}

export enum EStortType {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IPortfolioPercentage {
  percentage: number;
  symbol: string;
}

export interface IPortfolioSuggestions {
  wallet_address: string[];
  wallet_ensName: string | null;
  chain_ids: string;
  token_address: string;
  token_symbol: string;
  token_name: string;
  token_logo: string | undefined;
  token_thumbnail: string | undefined;
  usd_value: number;
  matchedTokens: API.InsightsFilter.Comps['CoinInfoDto'][];
}

export type CategoryItem = {
  name: string;
  value: number;
};

export type SuggestionsWithCategories = IPortfolioSuggestions & {
  categories: CategoryItem[];
};

export type TChart = 'pie' | 'bar';

export type DatedWalletHistoryResult = API.Web3Proxy.Comps['WalletHistoryResultDto'] & {
  date?: string;
};

export type TPortfolioLink = {
  title: string;
  url: string;
};

export enum EWalletProfitabilityTimeframes {
  ALL = 'all',
  SEVENDAYS = '7',
  THIRTYDAYS = '30',
  SIXTYDAYS = '60',
  NINETYDAYS = '90',
}

export type TWalletProfitabilityDays =
  (typeof EWalletProfitabilityTimeframes)[keyof typeof EWalletProfitabilityTimeframes];
