import { API } from '@/api';
import { createBroswerFetcherFactory } from './apiBrowser.factory';
// import '../only.browser';

export const apiBrowser = {
  ads: createBroswerFetcherFactory<API.Ads.Paths, { authToken: 'required' }>({
    baseURL: '/api/ads',
    passAuthToken: true,
  }),
  billingCoordinator: createBroswerFetcherFactory<API.BillingCoordinator.Paths, { authToken: 'required' }>({
    baseURL: '/api/billingCoordinator',
    passAuthToken: true,
  }),
  web3Proxy: createBroswerFetcherFactory<API.Web3Proxy.Paths>({ baseURL: '/api/web3Service' }),
  web3: createBroswerFetcherFactory<API.Web3.Paths>({ baseURL: '/api/web3' }),
  web3Solana: createBroswerFetcherFactory<API.Web3Solana.Paths>({ baseURL: '/api/solana/web3' }),
  user: createBroswerFetcherFactory<API.User.Paths, { authToken: 'required' }>({
    baseURL: '/api/userService',
    passAuthToken: true,
  }),
  coin: createBroswerFetcherFactory<API.Coins.Paths, { authToken: 'required' }>({
    baseURL: '/api/coinsService',
    passAuthToken: true,
  }),
  insightsSearch: createBroswerFetcherFactory<API.InsightsSearch.Paths>({
    baseURL: '/api/insightsRepoSearchService',
  }),
  // @deprecated
  insightsFilter: createBroswerFetcherFactory<API.InsightsFilter.Paths, { authToken: 'required' }>({
    baseURL: '/api/insightsRepoService',
    passAuthToken: true,
  }),
  // 3rd party
  oneInch: createBroswerFetcherFactory<API.OneInchSwap.Paths>({ baseURL: '/api/1inch/swap' }),
  oneInchGasPrice: createBroswerFetcherFactory<API.OneInchGasPrice.Paths>({ baseURL: '/api/1inch/gas-price' }),
  dexTools: createBroswerFetcherFactory<API.DexTools.Paths>({ baseURL: '/api/dextools' }),
};
