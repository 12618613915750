import { useCallback } from 'react';
import { RangeSlider } from 'moralis-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@/components/core/Input';
import { formatTokenPrice } from '@/utils/fmt';
import { ITokenAlertFormData } from './TokenAlert.types';
import styles from './PriceInputWithSlider.module.scss';
import typography from '@/styles/scss/typography.module.scss';

const percentages = [
  { label: '-100%', percentage: -100 },
  { label: '-75%', percentage: -75 },
  { label: '-50%', percentage: -50 },
  { label: '-25%', percentage: -25 },
  { label: '0%', percentage: 0 },
  { label: '25%', percentage: 25 },
  { label: '50%', percentage: 50 },
  { label: '75%', percentage: 75 },
  { label: '100%', percentage: 100 },
];

export const PriceInputWithSlider = ({ price }: { price?: number }) => {
  const { control, setValue } = useFormContext<ITokenAlertFormData>();

  const getTargetPrice = useCallback(
    (changeInPercent: number) => {
      if (price === undefined) return undefined;
      if (changeInPercent < 0) {
        return price * (1 - -changeInPercent / 100);
      } else {
        return price * (1 + changeInPercent / 100);
      }
    },
    [price],
  );

  return (
    <Controller
      control={control}
      name="priceChangePercentage"
      render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid } }) => (
        <div className={styles.wrapper}>
          <Input
            hasError={invalid}
            id={name}
            name={name}
            placeholder="Enter a price change..."
            type="number"
            value={value}
            onBlur={onBlur}
            onChange={(value) => {
              const valueAsNumber = Number(value.target.value);
              const targetPrice = getTargetPrice(valueAsNumber);
              if (targetPrice !== undefined) {
                setValue('inputValue', targetPrice);
                valueAsNumber < 0 ? setValue('selectOperator', 'lt') : setValue('selectOperator', 'gt');
              }
              onChange(valueAsNumber);
            }}
          />
          <div className={styles.prices}>
            <div className={styles.priceWrapper}>
              <h6 className={typography.bodySmallRegular}>Current</h6>
              <span className={typography.bodySmallBold}>{formatTokenPrice(price)}</span>
            </div>
            <div className={styles.priceWrapper}>
              <h6 className={typography.bodySmallRegular}>Target</h6>
              <span className={typography.bodySmallBold}>{formatTokenPrice(getTargetPrice(value))}</span>
            </div>
          </div>
          <div className={styles.slider}>
            <RangeSlider
              id="alert-slider"
              max={100}
              min={-100}
              name="alert-slider"
              onChange={(e) => {
                const valueAsNumber = Number(e.target.value);
                const targetPrice = getTargetPrice(valueAsNumber);
                if (targetPrice !== undefined) {
                  setValue('inputValue', targetPrice);
                  valueAsNumber < 0 ? setValue('selectOperator', 'lt') : setValue('selectOperator', 'gt');
                }
                onChange(valueAsNumber);
              }}
              hasMarkers
              scale={percentages.map((query) => query.percentage.toString())}
              value={value}
            />
          </div>
        </div>
      )}
      rules={{
        required: true,
        validate: (value) => value !== 0 && value > -100,
      }}
    />
  );
};
