import Link from 'next/link';
import clsx from 'clsx';
import { EHomePageView } from '@/components/common/HomePage/lib/EHomePageView.enum';
import { ERoutePath } from '@/utils/ERoutePath';
import { remCalc } from '@/utils/remCalc';
import { faChartLine, faFireFlameCurved, faWavePulse, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/core/macro';
import styles from './HomePageTabs.module.scss';

const NavLink = ({
  href,
  icon,
  label,
  isActive,
}: {
  href: string;
  icon: IconDefinition;
  label: React.ReactNode;
  isActive: boolean;
}) => {
  return (
    <Link href={href} className={clsx(styles.tabLink, { [styles.active]: isActive })}>
      <span className={styles.tabTitle}>
        <FontAwesomeIcon icon={icon} style={{ width: remCalc(16), height: remCalc(16) }} />
        {label}
      </span>
    </Link>
  );
};

export const HomePageTabs = ({ tabId }: { tabId: EHomePageView }) => {
  const getViewName = (view: EHomePageView): string => {
    switch (view) {
      case EHomePageView.LATEST_COINS:
        return t({
          message: 'Latest Coins',
          context: 'The most recently added coins',
        });
      case EHomePageView.TOP_BY_MARKET_CAP:
        return t({
          message: 'Top By Market Cap',
          context: 'Coins ranked by their market capitalization',
        });
      case EHomePageView.TRENDING:
        return t({
          message: 'Trending',
          context: 'Coins currently trending',
        });
      case EHomePageView.VERIFIED_CRYPTO_TOKENS:
        return t({
          message: 'Verified Projects',
          context: 'Discover verified crypto projects',
        });
      default:
        return '';
    }
  };

  return (
    <div className={styles.tabs}>
      <NavLink
        href={ERoutePath.home}
        icon={faFireFlameCurved}
        label={getViewName(EHomePageView.TRENDING)}
        isActive={tabId === EHomePageView.TRENDING}
      />
      <NavLink
        href={ERoutePath.topTokensMarketCap}
        icon={faChartLine}
        label={getViewName(EHomePageView.TOP_BY_MARKET_CAP)}
        isActive={tabId === EHomePageView.TOP_BY_MARKET_CAP}
      />
      <NavLink
        href={ERoutePath.newCoins}
        icon={faWavePulse}
        label={getViewName(EHomePageView.LATEST_COINS)}
        isActive={tabId === EHomePageView.LATEST_COINS}
      />
    </div>
  );
};
