import { ButtonPrimary } from 'moralis-ui';
import { ERoutePath } from '@/utils/ERoutePath';
import { faRocket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const UpgradeProLink = () => (
  <ButtonPrimary
    onClick={() => {
      window.open(ERoutePath.pricing, '_self');
    }}
  >
    <FontAwesomeIcon icon={faRocket} />
    Upgrade{' '}
  </ButtonPrimary>
);
