const color = {
  black: '#000000',
  moneyAero10: '#F0F8FF',
  moneyAero20: '#DCEEFE',
  moneyAero30: '#BBDBF7',
  moneyAero40: '#85B3DB',
  moneyAero50: '#6287B7',
  moneyAero60: '#3877AD',
  moneyAero70: '#1A3656',
  moneyAero71: '#193554',
  moneyAero72: '#193452',
  moneyAero73: '#183251',
  moneyAero74: '#17314F',
  moneyAero75: '#17304D',
  moneyAero76: '#162F4B',
  moneyAero77: '#152E49',
  moneyAero78: '#142C48',
  moneyAero79: '#142B46',
  moneyAero80: '#132A44',
  moneyAero83: '#10253E',
  moneyAero85: '#0D2035',
  moneyAero90: '#09182A',
  moneyBlue20: '#213853',
  moneyBlue30: '#57A5FF',
  moneyBlue40: '#0D243F',
  moneyBlue50: '#0150AD',
  moneyBlue60: '#003470',
  moneyBlueNatural40: '#0F7FFF',
  moneyGreen40: '#5FC578',
  moneyGrey30: '#CACFD3',
  moneyGrey40: '#A8AFB7',
  moneyGrey50: '#5E84B5',
  moneyLavender50: '#5555FA',
  moneyMint40: '#00D1AE',
  moneyMint50: '#00AD96',
  moneyNavy40: '#0B72C4',
  moneyPink40: '#E156D0',
  moneyPurple40: '#BC68FF',
  moneyRed40: '#E95557',
  moneyWhite: '#FFFFFE',
  moneyWhiteOpacity5: 'rgba(255,255,255,0.05)',
  moneyYellow50: '#EBBB00',
  moneyTeal50: '#0E5C58',
  moneyPurple50: '#9B22FF',
  aero10: '#F0F8FF',
  aero20: '#DCEEFE',
  aero30: '#BBDBF7',
  aero50: '#5B8DB9',
  aero80: '#213853',
  aero90: '#081825',
  aero100: '#001018',
  blue05: '#E8EAED',
  blue10: '#DCEFFD',
  blue20: '#99D3FF',
  blue30: '#52ABFF',
  blue40: '#0F7FFF',
  blue50: '#005AC2',
  blue60: '#003470',
  blue70: '#041836',
  blueGray50: '#68738D',
  fuchsia10: '#FCF0FB',
  fuchsia40: '#E156D0',
  gray20: '#F8F8F8',
  gray30: '#CACFD3',
  gray40: '#A8AFB7',
  gray60: '#4F5760',
  lavender40: '#8F7DFF',
  lime10: '#F6FFCC',
  mint05: '#E8FCF9',
  mint10: '#D1FFF0',
  mint20: '#A1FFE0',
  mint30: '#33FFCC',
  mint40: '#00D1AE',
  mint50: '#00AD96',
  mint53: '#009D88',
  mint60: '#00856F',
  mint80: '#003F3B',
  navy10: '#E9F2FA',
  navy20: '#BCD7F0',
  navy30: '#9EC7EA',
  navy40: '#0B72C4',
  navy80: '#00006B',
  purple10: '#F3EEFB',
  purple20: '#E9C4FF',
  purple50: '#9B22FF',
  purple60: '#6012A1',
  purple80: '#1F1446',
  red10: '#FDF0F0',
  red30: '#FF6969',
  red40: '#E95557',
  red50: '#FF0000',
  red60: '#AE0000',
  sky40: '#01C0F4',
  turquoise40: '#01CBD2',
  white: '#FFFFFF',
  white10: '#F5F5F5',
  yellow10: '#FEFAED',
  yellow20: '#F9EDBA',
  yellow40: '#F0C800',
  yellow50: '#EBBB00',
  yellow60: '#D69700',
} as const;

export const bgGradient = 'linear-gradient(0deg, rgba(9,27,48,1) 0%, rgba(13,36,63,1) 100%)';

export const modalGradient = 'radial-gradient(102.8% 102.8% at 3.14% -9.06%, #2F5E92 0%, #133B5C 83.07%, #11365A 100%)';

export const progressGradient = 'linear-gradient(90deg, #0F7FFF 0%, #57A5FF 100%)';

export default color;
