'use client';
import { useEffect, useState } from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useUserPlanLevel } from '@/hooks/useUser';
import { useNotificationBannerStore } from '@/stores/useNotificationBannerStore';
import { INotificationBanner } from '@/types/notificationBanner';
import { NotificationBanner } from './imports';

const useTrialBanner = () => {
  const dismissTrialBanner = useNotificationBannerStore((s) => s.dismissTrialBanner);
  const isTrialBannerVisible = useNotificationBannerStore((s) => s.isTrialBannerVisible);
  const [trialBannerVisible, setTrialBannerVisible] = useState(false);
  const isPaidUser = useUserPlanLevel() > 1;

  useEffect(() => {
    setTrialBannerVisible(isTrialBannerVisible);
  }, [isTrialBannerVisible]);

  return {
    isVisible: !(!trialBannerVisible || isPaidUser),
    dismissTrialBanner,
  };
};

export const TrialBanner = ({ pageNotification }: { pageNotification?: INotificationBanner }) => {
  const { isVisible, dismissTrialBanner } = useTrialBanner();
  const { trackButtonClick } = useAnalytics();
  if (!pageNotification || !isVisible) {
    return null;
  }
  return (
    <NotificationBanner
      {...pageNotification}
      onDismiss={() => {
        trackButtonClick('General Banner Dismissed');
        dismissTrialBanner();
      }}
      onLinkClicked={() => {
        trackButtonClick('General Banner Clicked');
      }}
    />
  );
};
