import React, { MouseEvent } from 'react';
import { Avatar, ButtonSecondary, Flex } from 'moralis-ui';
import { TokenAdsView } from '@/services/models/GetTokenAdsViewDto';
import { faDiscord, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/core/macro';
import styles from './HomeTokenAd.module.scss';

export const TokenAdBanner = ({ tokenAdsView }: { tokenAdsView: TokenAdsView }) => {
  const hasCta = Boolean(tokenAdsView?.cta?.text);

  const hasTokenUrl = hasCta ? tokenAdsView?.cta?.link : tokenAdsView.tokenURL;

  const hrefLinkCta = hasCta ? tokenAdsView?.cta?.link : tokenAdsView.tokenURL;

  const twitterLink = tokenAdsView?.links?.find((link) => link.name === 'X');
  const discordLink = tokenAdsView?.links?.find((link) => link.name === 'discord');
  const telegramLink = tokenAdsView?.links?.find((link) => link.name === 'telegram');
  const websiteLink = tokenAdsView?.links?.find((link) => link.name === 'token-project-link');

  const handleClickLink = (link: string) => (event: MouseEvent) => {
    event.preventDefault();
    window.open(link, '_blank');
  };

  return (
    <div className={styles.banner}>
      <div className={styles.infoContainer}>
        {tokenAdsView?.icon && URL.canParse(tokenAdsView.icon) && (
          <Avatar alt={t`token avatar`} size="lg" src={tokenAdsView.icon} />
        )}

        <Flex direction="column" justify="center" height="100%" maxWidth="100%" spacing={4}>
          <Flex direction="row" spacing={8} align="baseline" justify="flex-start">
            <p className={styles.tokenName}>{tokenAdsView?.tokenName || ''}</p>
            <p className={styles.tokenSymbol}>{tokenAdsView?.tokenSymbol || ''}</p>
          </Flex>
          <p className={styles.pitch}>{tokenAdsView?.pitch}</p>
        </Flex>
      </div>
      <div className={styles.buttonsContainer}>
        {tokenAdsView?.links?.length ? (
          <Flex spacing={8} direction="row" justify="center">
            {discordLink ? (
              <ButtonSecondary className={styles.socialButton} size="xs" onClick={handleClickLink(discordLink.url)}>
                <FontAwesomeIcon className={styles.socialMediaIcon} icon={faDiscord} />
              </ButtonSecondary>
            ) : null}
            {telegramLink ? (
              <ButtonSecondary className={styles.socialButton} size="xs" onClick={handleClickLink(telegramLink.url)}>
                <FontAwesomeIcon className={styles.socialMediaIcon} icon={faTelegram} />
              </ButtonSecondary>
            ) : null}
            {websiteLink ? (
              <ButtonSecondary className={styles.socialButton} size="xs" onClick={handleClickLink(websiteLink.url)}>
                <FontAwesomeIcon className={styles.socialMediaIcon} icon={faGlobe} />
              </ButtonSecondary>
            ) : null}
            {twitterLink ? (
              <ButtonSecondary className={styles.socialButton} size="xs" onClick={handleClickLink(twitterLink.url)}>
                <FontAwesomeIcon className={styles.socialMediaIcon} icon={faTwitter} />
              </ButtonSecondary>
            ) : null}
          </Flex>
        ) : null}
        {hasTokenUrl && (
          <ButtonSecondary size="xs" className={styles.ctaButton} onClick={handleClickLink(hrefLinkCta)}>
            {hasCta ? tokenAdsView.cta.text : t`Check Chart`}
          </ButtonSecondary>
        )}
      </div>
    </div>
  );
};
