import { useMemo } from 'react';
import useSWR from 'swr';
import {
  FollowCuratedWalletListRequest,
  GetAllCuratedWalletListResponse,
  UnFollowCuratedWalletListRequest,
} from '@/services/index';
import { api } from '@/utils/api';
import { useAuthToken } from './useAuth';

const messages = {
  follow: (name: string) => `Bundle alert for "${name}" created`,
  unfollow: (name: string) => `You are no longer following ${name}`,
};

const fetchBundles = async () => {
  return await api.user.userControllerGetCuratedWalletLists1();
};

export const useBundles = () => {
  const { isLoggedIn } = useAuthToken();
  const { data, error, mutate, isLoading, isValidating } = useSWR(isLoggedIn ? 'get-bundles' : null, fetchBundles);

  const followBundle = async (requestBody: FollowCuratedWalletListRequest) => {
    const updatedAlert = await api.user.userControllerFollowCuratedWalletList(requestBody);
    mutate(
      (prevData) => prevData?.map((oldAlert) => (oldAlert.id === updatedAlert.id ? updatedAlert : oldAlert)),
      false,
    );
    return updatedAlert;
  };

  const unfollowBundle = async (requestBody: UnFollowCuratedWalletListRequest) => {
    const updatedAlert = await api.user.userControllerUnfollowCuratedWalletList(requestBody);
    mutate(
      (prevData) => prevData?.map((oldAlert) => (oldAlert.id === updatedAlert.id ? updatedAlert : oldAlert)),
      false,
    );
    return updatedAlert;
  };

  const bundles = useMemo(() => {
    if (!data) return;

    const activeBundles = data.filter((bundle) => bundle.status === 'active');

    const withAlert: GetAllCuratedWalletListResponse[] = [];
    const noAlert: GetAllCuratedWalletListResponse[] = [];

    for (const bundle of activeBundles || []) {
      if (bundle.isFollowing) {
        withAlert.push(bundle);
      } else {
        noAlert.push(bundle);
      }
    }
    return { withAlert, noAlert, all: activeBundles };
  }, [data]);

  return {
    messages,
    bundles,
    isLoading: isLoading || isValidating,
    error,
    followBundle,
    unfollowBundle,
  };
};
