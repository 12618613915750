import React, { memo } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import clsx from 'clsx';
import { TableCell } from 'moralis-ui';
import { CoinLogo } from '@/components/common/CoinLogo';
import { SafetyShield } from '@/components/common/SafetyShield';
import TokenMetric from '@/components/common/Tables/atoms/TokenMetric';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useLocale } from '@/hooks/useLocale';
import { Nullish } from '@/types/common';
import { TrendingCoinInfoDto } from '@/utils/api/types';
import { Advertisement } from '@/utils/contentful/type';
import { formatMetric, formatTokenPrice } from '@/utils/fmt';
import { formatAsTokenAmount } from '@/utils/formatAsTokenAmount';
import { getTimestampDistanceInDays } from '@/utils/getTimestampDistance';
import { computeHypeScore } from '@/utils/hypeScore';
import { ChainIdString, getChainNameByChainId } from '@/utils/supportedChains';
import { getCoinURLById } from '@/utils/urls';
import { DataSyncingInfo } from '../common/DataSyncingInfo';
import { ToggleFollowTokenButton } from '../common/ToggleFollowTokenButton';
import { getRandomTrendingTokenPageAd } from './utils/ad';
import { HomePageCategoryPills } from './HomePageCategoryPills';
import { TrendingTableLoading } from './TrendingTable';
import styles from './TrendingPageUITableBody.module.scss';

const TrendingPageAd = dynamic(() => import('./TrendingPageAd').then((mod) => mod.TrendingPageAd), {
  ssr: false,
});

export const TrendingPageUITableBody = ({
  trendingTokenPage,
  currentPage,
  itemsPerPage,
  ads,
  isTrendingTokenDataLoading,
}: {
  currentPage: number;
  itemsPerPage: number;
  trendingTokenPage: TrendingCoinInfoDto[];
  ads: Advertisement[];
  isTrendingTokenDataLoading?: boolean;
}) => {
  const locale = useLocale();
  if (isTrendingTokenDataLoading) {
    return <TrendingTableLoading />;
  }

  const selectedAd = getRandomTrendingTokenPageAd({
    ads,
    rowCount: trendingTokenPage.length,
  });

  return (
    <>
      {trendingTokenPage.map((token, index) => {
        const rowNumber = (currentPage - 1) * itemsPerPage + index + 1;
        const chainName = getChainNameByChainId(token.chainId);
        const batteryChartValue = computeHypeScore(token.hypeScore || 0) / 100;
        let batteryChartValueShowUser = Math.round(batteryChartValue * 100);
        if (batteryChartValueShowUser === 100) {
          batteryChartValueShowUser -= 1;
        }

        return (
          <TrendingTableRow
            token={token}
            rowNumber={rowNumber}
            chainName={chainName}
            key={`wrap-${token.coinAddress}-${token.chainId}`}
            selectedAd={selectedAd}
            index={index}
            locale={locale}
          />
        );
      })}
    </>
  );
};

const TrendingTableRow = memo(
  ({
    token,
    rowNumber,
    chainName,
    index,
    selectedAd,
    locale,
  }: {
    token: TrendingCoinInfoDto;
    rowNumber: number;
    chainName: string;
    index: number;
    selectedAd: Nullish<{
      ad: Advertisement;
      placement: number;
    }>;
    locale: string;
  }) => {
    const hasFollowedButton = useFeatureFlag('trending_followed');
    return (
      <React.Fragment>
        {index + 1 === selectedAd?.placement && selectedAd?.ad && <TrendingPageAd ad={selectedAd?.ad} />}
        <tr>
          <TableCell align="left" className={styles.fieldToken}>
            <Link
              legacyBehavior
              passHref
              href={getCoinURLById(token)}
              key={`link-${token.coinAddress}-${token.chainId}`}
            >
              <a className={styles.tokenInfo}>
                <span className={clsx('caption-14-regular', styles.tokenInfoRowNumber)}>{rowNumber}</span>
                <span className={styles.tokenInfoBox}>
                  <span className={styles.tokenInfoBoxLogo}>
                    <CoinLogo
                      chainId={token.chainId}
                      name={token.name || token.symbol || 'N/A'}
                      size="small"
                      src={token.imgURL}
                    />
                  </span>
                  <span className={styles.tokenInfoBoxTitle}>
                    <span
                      className={clsx(
                        styles.tokenInfoBoxTitleName,
                        styles.tokenInfoBoxTitleNameDesktop,
                        'body-16-medium',
                      )}
                    >
                      {token.name ?? 'N/A'}
                    </span>
                    <span
                      className={clsx(
                        styles.tokenInfoBoxTitleName,
                        styles.tokenInfoBoxTitleNameMobile,
                        'body-16-medium',
                      )}
                    >
                      {token.symbol ?? 'N/A'}
                    </span>
                    <span className={clsx('caption-12-regular', styles.tokenInfoBoxTitleSymbol)}>{chainName}</span>
                  </span>
                </span>
              </a>
            </Link>
          </TableCell>

          <TableCell align="left" className={styles.fieldTable}>
            <span
              className={clsx('body-16-medium', styles.tokenInfoBoxValue, styles.equal, {
                [styles.positive]: token.price24HChange !== null && token.price24HChange > 0,
                [styles.negative]: token.price24HChange !== null && token.price24HChange < 0,
              })}
            >
              {token.price24HChange !== null ? formatMetric(token.price24HChange, 'percent', true).value : 'N/A'}
            </span>
            <span className={clsx('caption-12-medium', styles.tokenInfoBoxValue)}>
              {formatTokenPrice(token.price !== null ? token.price : undefined)}
            </span>
          </TableCell>
          <TableCell align="right" className={styles.fieldTable}>
            <span className={clsx('body-16-medium', styles.tokenInfoBoxValue)}>
              $
              {formatAsTokenAmount(
                token.fullyDilutedValuation !== null ? parseFloat(token.fullyDilutedValuation.toString()) : 0,
              )}
            </span>
          </TableCell>
          <TableCell align="right" className={styles.fieldTable}>
            {token.blockTimestamp ? (
              <span className={clsx('body-16-medium', styles.tokenInfoBoxValue)}>
                {getTimestampDistanceInDays(token.blockTimestamp, locale)}
              </span>
            ) : (
              <DataSyncingInfo />
            )}
          </TableCell>
          <TableCell align="right" className={styles.fieldTable}>
            <TokenMetric
              className={clsx('body-16-medium', styles.tokenInfoBoxValue, styles.tokenInfoBoxValueVolume)}
              currency={true}
              hasTooltip={false}
              value={token.volume24H || 0}
            />
          </TableCell>
          <TableCell align="right" className={styles.fieldTable}>
            <TokenMetric
              className={clsx('body-16-medium', styles.tokenInfoBoxValue)}
              currency={true}
              hasChangeIndication={true}
              hasTooltip={false}
              value={token.buyPressure || 0}
            />
          </TableCell>
          <TableCell align="right" className={styles.fieldTable}>
            <span className={clsx('body-16-medium', styles.tokenInfoBoxValue)}>
              {token.twitterFollowerCount !== null && (
                <>{new Intl.NumberFormat('en-US').format(token.twitterFollowerCount || 0)}</>
              )}
            </span>
          </TableCell>
          <TableCell align="right" className={styles.fieldTable}>
            <HomePageCategoryPills categories={token.categories} />
          </TableCell>
          <TableCell align="center" className={styles.fieldScore}>
            <span className={styles.fieldScoreBox}>
              <SafetyShield dexLevel={token.moralisScore ?? 0} fontSize={12} shieldSize={32} />
            </span>
          </TableCell>
          {hasFollowedButton && (
            <TableCell className={styles.actions}>
              <ToggleFollowTokenButton
                address={token.coinAddress}
                chainId={token.chainId as ChainIdString}
                name={token.name || undefined}
                symbol={token.symbol || undefined}
              />
            </TableCell>
          )}
        </tr>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) =>
    prevProps.index === nextProps.index &&
    prevProps.token === nextProps.token &&
    prevProps.rowNumber === nextProps.rowNumber &&
    prevProps.selectedAd === nextProps.selectedAd &&
    prevProps.locale === nextProps.locale &&
    prevProps.chainName === nextProps.chainName,
);
