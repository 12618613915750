import { GetServerSidePropsContext } from 'next';
import type { IncomingMessage, ServerResponse } from 'http';
import { EnvApp } from './env/app';
import { AppCtxStatic, LogEntry, Logger, LogHandle } from './Logger';

const rootDir = import.meta.url.split('/').slice(0, -2).join('/');

class LoggerWithBuffer {
  public meta = {
    basic: getLogCtxBasic,
    byReq: getLogCtxByServerRequest,
    byResp: getLogCtxByServerResponse,
    bySSP: getLogCtxOfServerSideProps,
    byFetch: getLogCtxByResponse,
  };

  private appCtx: AppCtxStatic<string> = {
    appName: EnvApp.logName,
    version: EnvApp.version,
  };
  private bfr: LogEntry[] = [];
  private _log: LogHandle = (le: LogEntry) => {
    this.bfr.push(le);
  };

  constructor(private initLog: () => Promise<LogHandle>) {
    this.initLog().then((log) => {
      this._log = log;
      this.bfr.forEach(log);
      this.bfr.length = 0;
    });
  }

  create<ItCtx extends Partial<AppCtxStatic<string>>>(name: string, ctx?: ItCtx) {
    return new Logger({
      logName: name.replace(rootDir, ''),
      app: {
        ...this.appCtx,
        ...ctx,
      },
      send: (le: LogEntry) => this._log(le),
    });
  }
}

const getLogCtxBasic = (importMetaURL: string, extra?: Record<string, unknown>) => ({
  modulePath: importMetaURL,
  ...extra,
});

const getLogCtxByResponse = (
  resp: Response,
  importMetaURL: string,
  {
    method,
    extra,
  }: {
    method: string | null;
    extra?: Record<string, unknown>;
  },
) => ({
  modulePath: importMetaURL,
  method,
  ok: resp.ok,
  url: resp.url,
  status: resp.status,
  statusText: resp.statusText,
  redirected: resp.redirected,
  ...extra,
});

// Server side

const simplifyReq = (req: IncomingMessage) => ({
  method: req.method,
  url: req.url,
  headers: req.headers,
});

const getLogCtxByServerRequest = (req: IncomingMessage, importMetaURL: string, extra?: Record<string, unknown>) => ({
  modulePath: importMetaURL,
  req: simplifyReq(req),
  ...extra,
});

const getLogCtxByServerResponse = (
  { req, statusCode, statusMessage, errored }: ServerResponse,
  importMetaURL: string,
  extra?: Record<string, unknown>,
) =>
  getLogCtxByServerRequest(req, importMetaURL, {
    status: statusCode,
    statusText: statusMessage,
    errored,
    ...extra,
  });

const getLogCtxOfServerSideProps = (
  { query, params, res }: GetServerSidePropsContext,
  importMetaURL: string,
  extra?: Record<string, unknown>,
) =>
  getLogCtxByServerResponse(res, importMetaURL, {
    ssp: { query, params },
    ...extra,
  });

const logItem =
  EnvApp.verbosity === 'quiet'
    ? async () => () => null
    : typeof window === 'undefined'
    ? () => import('./log.server').then((m) => m.createServerLog())
    : () => import('./log.client').then((m) => m.createClientLog());

export const logApp = new LoggerWithBuffer(logItem);
