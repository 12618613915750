import { asString } from '../asType';
import { EnvApp } from './app';

const [protocol, apiHost] = asString(process.env.NEXT_PUBLIC_SEGMENT_INT_URL, 'NEXT_PUBLIC_SEGMENT_INT_URL').split(
  '://',
);

const NEXT_PUBLIC_SEGMENT_WRITE_KEY =
  (EnvApp.environment === 'prod'
    ? process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_PROD
    : process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_DEV) || process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

export const EnvSegment = {
  cfg: {
    writeKey: asString(NEXT_PUBLIC_SEGMENT_WRITE_KEY, 'NEXT_PUBLIC_SEGMENT_WRITE_KEY'),
    cdnURL: asString(process.env.NEXT_PUBLIC_SEGMENT_CDN_URL, 'NEXT_PUBLIC_SEGMENT_WRITE_KEY'),
  },
  intergationCfg: {
    apiHost,
    protocol,
  },
};
