import React, { useState } from 'react';
import Image from 'next/image';
import { ChainLogo } from './ChainLogo';
import styles from './CoinLogo.module.scss';

const CoinLogoRaw = ({
  chainId,
  name,
  size = 'medium',
  src,
  className,
}: {
  chainId?: string;
  className?: string;
  name: string | undefined;
  size?: 'large' | 'medium' | 'small' | 'xs';
  src: string | undefined | null;
}) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const renderedName = name || 'NA';
  return (
    <div className={`${styles.logo} ${styles[size]} ${className || ''}`}>
      {src && (
        <Image
          alt={renderedName}
          className={`${styles.coin} ${isImgLoaded ? '' : styles.hidden}`}
          height={500}
          src={src}
          width={500}
          onError={() => setIsImgLoaded(false)}
          onLoad={() => setIsImgLoaded(true)}
          unoptimized={true}
        />
      )}
      {!isImgLoaded && <span role="img">{renderedName.slice(0, 2)}</span>}
      {chainId && <ChainLogo chainId={chainId} className={styles.chain} />}
    </div>
  );
};

export const CoinLogo = React.memo(CoinLogoRaw);
CoinLogo.displayName = 'CoinLogo';
