import dynamic from 'next/dynamic';

export const AuthModal = dynamic(() => import('@/components/authModal/AuthModal').then((mod) => mod.AuthModal), {
  ssr: false,
});

export const MobileHeaderMenu = dynamic(
  () => import('@/components/aside/MobileHeaderMenu/MobileHeaderMenu').then((mod) => mod.MobileHeaderMenu),
  {
    ssr: false,
  },
);

export const BottomMenu = dynamic(
  () => import('@/components/aside/BottomMenu/BottomMenu').then((mod) => mod.BottomMenu),
  {
    ssr: false,
  },
);

export const NotificationBanner = dynamic(
  () => {
    return import('@/components/common/NotificationBanner');
  },
  {
    ssr: false,
  },
);
