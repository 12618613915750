import { forwardRef } from 'react';
import { CoinLogo } from '@/components/common/CoinLogo';
import { SafetyShield } from '@/components/common/SafetyShield';
import { SearchCoinInfoDto } from '@/services/index';
import { truncate } from '@/utils/fmt';
import { getTimestampDistance } from '@/utils/getTimestampDistance';
import { getCoinLogoURL } from '@/utils/urls';
import { useId } from '@floating-ui/react';
import styles from './TokenSearchResult.module.scss';

export const TokenSearchResult = forwardRef<
  HTMLDivElement,
  {
    active?: boolean;
    coin: SearchCoinInfoDto;
  }
>(({ active, coin, ...rest }, ref) => {
  const id = useId();

  return (
    <div
      aria-selected={active}
      id={id}
      ref={ref}
      role="option"
      {...rest}
      className={`${styles.dropdown_item} ${active ? styles.active : undefined}`}
    >
      <div className={`${styles.column} ${styles.left}`}>
        <div className={styles.logoAndDesc}>
          <CoinLogo
            chainId={coin?.chainId}
            className={styles.logo}
            name={coin?.name || coin?.symbol || coin.coinAddress}
            size="medium"
            src={getCoinLogoURL(coin.chainId, coin.coinAddress, undefined, 1)}
          />
          <div className={styles.coinDesc}>
            <span className={styles.name}>{coin.name}</span>
            <span className={styles.symbol}>{coin.symbol}</span>
            <span className={styles.address}>{coin.coinAddress ? truncate(coin.coinAddress, 12) : 'N/A'}</span>
          </div>
        </div>
        <SafetyShield dexLevel={coin?.moralisScore || 0} fontSize={12} />
      </div>

      <div className={`${styles.column} ${styles.right}`}>
        <span className={styles.coinAge}>
          {coin.blockTimestamp ? <>{getTimestampDistance(coin?.blockTimestamp)} ago</> : 'N/A'}
        </span>
      </div>
    </div>
  );
});

TokenSearchResult.displayName = 'TokenSearchResult';
