import { API } from '@/api';
import { ProtocolType } from '@/stores/userStore';
import { TAlertType } from './TAlertType';

export const getUserAllowedAlertTypes = (userStage: API.User.Comps['PlanStageDto'] | null) => {
  const allowedAlertTypes: Partial<Record<TAlertType, boolean>> = userStage?.allowedAlertTypes ?? {};
  return allowedAlertTypes;
};

export const getUserAllowedAlertProtocols = (userStage: API.User.Comps['PlanStageDto'] | null) => {
  const allowedAlertProtocols: Partial<Record<ProtocolType, boolean>> = userStage?.allowedAlertProtocols ?? {};
  return allowedAlertProtocols;
};
