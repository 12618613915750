import Big from 'big.js';
import { isNullish } from './isNullish';
import { numberConverter } from './numberConverter';
import { sha } from './sha';

/**
 * Responsible for converting number to comma separated number string
 */
export const formatNumber = (n: number | string, minimumFractionDigits = 2, maximumFractionDigits = 18) => {
  const number = Number(n);

  // Define thresholds for Peta, Exa, Zetta, and Yotta
  const PET = 1e15; // Peta (P)
  const EXA = 1e18; // Exa (E)
  const ZET = 1e21; // Zetta (Z)
  const YOT = 1e24; // Yotta (Y)

  if (number >= 1000 || number <= -1000) {
    // Extend to handle larger values with custom prefixes
    const absNumber = Math.abs(number);
    if (absNumber >= YOT) {
      return Math.round(number / YOT) + 'Y';
    } else if (absNumber >= ZET) {
      return Math.round(number / ZET) + 'Z';
    } else if (absNumber >= EXA) {
      return Math.round(number / EXA) + 'E';
    } else if (absNumber >= PET) {
      return Math.round(number / PET) + 'P';
    } else {
      // Use compact notation for numbers less than Peta
      return Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1,
      }).format(number);
    }
  }

  // Return standard localized number format for numbers smaller than 1000
  return number.toLocaleString(undefined, {
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

/**
 * Responsible for converting number to Currency Format
 */
export const formatCurrency = (
  amount: number | undefined,
  numCurrency = 'USD',
  numFormat = 'en-US',
  maximumFractionDigits = 1,
  minimumFractionDigits = 0,
) => {
  if (amount == undefined) return 'N/A';
  const number = Number(amount);

  if (number >= 1000 || number <= -1000) {
    return Intl.NumberFormat(numFormat, {
      currency: numCurrency,
      maximumFractionDigits,
      minimumFractionDigits,
      notation: 'compact',
      style: 'currency',
    }).format(number);
  }
  const formatter = new Intl.NumberFormat(numFormat, {
    style: 'currency',
    currency: numCurrency,
  });

  return formatter.format(Number(amount));
};

/**
 * Responsible for converting number to Currency Format
 * but for numbers we do not want to round up or down
 */
export const formatCurrencyWithoutRounding = (
  amount: number | undefined,
  numCurrency = 'USD',
  numFormat = 'en-US',
  maximumFractionDigits = 0,
) => {
  const formatter = new Intl.NumberFormat(numFormat, {
    style: 'currency',
    currency: numCurrency,
    minimumFractionDigits: maximumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });

  return formatter.format(Number(amount));
};

/**
 * Responsible for Calculating the dollar value of a token
 */
export const getDollarValue = (
  balance: string, // Amount of tokens
  current_value: number, // Current value of the token
  decimals: number, // Decimals of the token
): number => {
  if (current_value && current_value != undefined) {
    return Number(
      Big(Number(balance))
        .div(10 ** Number(decimals))
        .mul(Number(current_value))
        .toNumber()
        .toFixed(2),
    );
  }
  return 0;
};

/**
 * Responsible for Truncating given string if it's longer then the passed length
 */
export const truncate = (str: string, length = 14) => {
  if (str.length <= length) return str;
  const separator = '...';
  const sepLen = separator.length,
    charsToShow = length - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return str.substring(0, frontChars) + separator + str.substring(str.length - backChars);
};

/**
 * Responsible for Truncating given string if it's longer then the passed length, only the last characters are cut off
 */
export const truncateTail = (str: string, length = 12) => {
  if (str.length <= length) return str;
  const separator = '...';
  const sepLen = separator.length;
  const charsToShow = length - sepLen;

  if (charsToShow <= 0) {
    return str.slice(0, length);
  }

  // Perform the initial truncation
  let truncated = str.slice(0, charsToShow) + separator;

  // Ensure that the last character before the '...' isn't a special character
  while (/[^a-zA-Z0-9]$/.test(truncated.slice(0, -sepLen))) {
    truncated = truncated.slice(0, -sepLen - 1) + separator;
  }

  return truncated;
};

/**
 * Responsible for converting number to token price format
 * If has more than 2 zeros after decimal point, convert to subscript
 *
 * This function treats numbers less than 1 as if they have 4 decimal places, instead of 2
 * this means inconsistent result based on input sign (+ or -)
 * formatAsTokenPrice(10000.505555) // $10,000.50
 * formatAsTokenPrice(- 10000.505555) // $-10,000.505555
 */
export const formatTokenPrice = (num?: number): string => {
  if (num == undefined) return 'N/A';

  const leadingZerosCount = numberConverter.getZerosAmountAfterPoint(num);

  const shouldBeSubscript = leadingZerosCount >= 2 && num < 1;

  if (shouldBeSubscript) {
    return `$${numberConverter.convertToSubscript(num, leadingZerosCount, 5)}`;
  }

  // If the number is less than 1, show 4 decimal places else show 2
  let maximumFractionDigits = num < 1 ? 4 : 2;

  // If the number is 0, show 2 decimal places
  if (num === 0) {
    maximumFractionDigits = 2;
  }

  // if the number is < -1 show 2 decimal places
  // -1000.233323432 should be treated as -1000.23
  if (num < -1) {
    maximumFractionDigits = 2;
  }

  return formatCurrencyWithoutRounding(num, undefined, undefined, maximumFractionDigits);
};

/**
 * Responsible for capitalizing the first letter of a given string
 */
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Responsible for capitalizing the first letter of a given string
 */
export const capitalizeHyphenSeparatedWords = (str: string) => {
  // Split the string by hyphen
  const parts = str.split('-');

  // Capitalize each part using the provided utility and reassemble
  const capitalizedParts = parts.map((part) => {
    // Assuming capitalize exists and capitalizes the first letter of the string
    // If it capitalizes the whole string or behaves differently, you might need to adjust this line
    return capitalize(part);
  });

  // Join the capitalized parts back with a hyphen
  return capitalizedParts.join('-');
};

/**
 * Responsible for converting metric number t o formatted value
 */
export const formatMetric = (
  metricValue: number,
  metricType: 'percent' | 'currency' | 'number' = 'number',
  isChangeable = false,
  isVerbose = false,
  noMetricValue = 'N/A',
) => {
  const isPositive = metricValue && metricValue >= 0 ? true : false;

  if (!metricValue) {
    return { value: noMetricValue, isPositive };
  }

  let sign = '';

  if (isChangeable) {
    if (isPositive) {
      sign = isVerbose ? 'increased by ' : '+';
    } else {
      // Bill removed the sign as this function is only being used in one place currently
      // and it renders twice as the data has the minus symbol already. returns: --5%
      sign = isVerbose ? 'decreased by ' : '';
    }
  }

  let value;

  switch (metricType) {
    case 'currency':
      value = sign + formatCurrency(metricValue);
      break;
    case 'percent':
      value = sign + formatNumber(metricValue, 2, 2) + '%';
      break;
    case 'number':
      value = sign + metricValue.toFixed(12).replace(/\.?0+$/, '');
      break;
    default:
      value = 'N/A';
      break;
  }

  return { value, isPositive };
};

// makes ID string human readable label
export const splitCamelCase = (str: string): string => str.replace(/([A-Z])/g, ' $1').trim();

export const getValueOrNA = (value?: string | number): string | number =>
  value !== undefined && value !== null && value !== '' ? value : 'N/A';

/**
 * Handles an address, string of addresses, or null.
 * - Returns the string as-is if addresses is a string.
 * - Returns the first element or joins all with a dash if it's an array.
 * - Returns null if addresses is null.
 * @param {string|string[]|null} addresses - The addresses to process.
 * @param {boolean} joinWithDash - Indicates whether to join array elements with a dash.
 * @returns {string} - The processed addresses as a string. Returns empty string if addresses is null.
 */
export const addressesToString = (addresses: string | string[] | null, joinWithDash: boolean = false): string => {
  if (typeof addresses === 'string') {
    return addresses;
  } else if (Array.isArray(addresses)) {
    return joinWithDash ? sha(addresses.sort().join('-')) : addresses[0];
  }
  return '';
};

/**
 * Handles an address, string of addresses, or null.
 * - Returns as-is if addresses is a array.
 * - Returns a string with the address as the only element if it's a string.
 * - Returns an empty array if addresses is null.
 * @param {string|string[]|null} value - The addresses to process.
 * @returns {string} - The processed addresses as aan array of strings. Returns empty string[] if addresses is null.
 */
export const addressesToArray = (value: string | string[] | null): string[] => {
  if (isNullish(value)) return [];
  return Array.isArray(value) ? value : [value];
};

export const toCamelCase = (str: string) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};
