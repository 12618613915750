import { useCallback, useEffect, useMemo } from 'react';
import create from 'zustand';
import { FetchError } from '@/utils/api/FetchError';
import { swrHook } from '@/utils/swrHook';
import { TS } from '@/utils/TS';
import { useAuth, useAuthToken, withAuthToken } from './useAuth';

type BoolStore<T extends string> = {
  varName: T;
  disable: () => void;
  isEnabled: boolean;
  enable: () => void;
  toggle: () => void;
};

const createBooleanStore = <T extends string>(varName: T) =>
  create<BoolStore<T>>((set) => {
    return {
      varName,
      isEnabled: false,
      disable: () => set({ isEnabled: false }),
      enable: () => set({ isEnabled: true }),
      toggle: () => set(({ isEnabled }) => ({ isEnabled: !isEnabled })),
    };
  });

const useAuthModalZustand = createBooleanStore('authModal');
const { enable: openAuthModal, disable: closeAuthModal, toggle: toggleAuthModal } = useAuthModalZustand.getState();

const useAuthModalState = () => {
  const isAuthModalOpen = useAuthModalZustand((s) => s.isEnabled);
  return useMemo(
    () => ({
      isAuthModalOpen,
      openAuthModal,
      closeAuthModal,
      toggleAuthModal,
    }),
    [isAuthModalOpen],
  );
};

export const useAuthModal = () => {
  const { isLoggedIn, authToken } = useAuthToken();
  const am = useAuthModalState();

  const askToLogIn = useCallback(() => {
    if (!isLoggedIn) {
      am.openAuthModal();
    }
    return isLoggedIn;
  }, [isLoggedIn]);

  return {
    ...am,
    askToLogIn,
    isLoggedIn,
    authToken,
  };
};

export const useAuthModalOnMount = () => {
  const { isLoggedIn, openAuthModal } = useAuthModal();
  useEffect(() => {
    if (!isLoggedIn) {
      openAuthModal();
    }
  }, [isLoggedIn]);
};

export const withAuthModal = <T, Arg extends useAuth.Arg>(
  useSWRHook: swrHook.Hook<T, Arg>,
  opts: TS.Arg1<typeof withAuthToken> = {},
): swrHook.Hook<T, TS.PartialByKey<Arg, useAuth.PropName>> => {
  const useSWRHookAuth = withAuthToken(useSWRHook, opts);
  return (argSrc, cfg) => {
    const arg = argSrc as Arg | null;

    const { openAuthModal } = useAuthModal();
    const handleError = useCallback(
      (err: unknown) => {
        if (FetchError.isLogout(err)) {
          openAuthModal();
        }
        return Promise.reject(err);
      },
      [openAuthModal],
    );

    cfg ??= {};
    cfg.handleError ??= handleError;
    return useSWRHookAuth(arg, cfg);
  };
};
