import { useFormContext } from 'react-hook-form';
import { ControlledInput } from './ControlledInput';
import { ControlledSelect } from './ControlledSelect';
import { PriceInputWithSlider } from './PriceInputWithSlider';
import { ITokenAlertCoin, ITokenAlertFormData } from './TokenAlert.types';
import stylesCommon from './col.module.scss';
import styles from './ConditionFieldset.module.scss';
import typography from '@/styles/scss/typography.module.scss';

const filterOptions = [
  { id: 'experiencedNetBuyersChange', label: 'Experienced Net Buyers Change' },
  { id: 'netBuyersChange', label: 'Net Buyers Change' },
  { id: 'netVolumeChangeUSD', label: 'Buy Pressure Change USD' },
  { id: 'priceChange', label: 'Price' },
];

const operatorOptions = [
  [
    { id: 'lt', label: 'Less Than' },
    { id: 'gt', label: 'Greater Than' },
  ],
  [
    { id: 'lt', label: 'Decreased By' },
    { id: 'gt', label: 'Increased By' },
  ],
];

const timeframeOptions = [
  { id: '10', label: '10m' },
  { id: '30', label: '30m' },
  { id: '60', label: '1H' },
  { id: '240', label: '4H' },
  { id: '720', label: '12H' },
  { id: '1440', label: '1D' },
  { id: '2880', label: '2D' },
  { id: '5760', label: '4D' },
  { id: '10080', label: '1W' },
  { id: '20160', label: '2W' },
  { id: '30240', label: '3W' },
  { id: '43200', label: '1M' },
  { id: '129600', label: '3M' },
];

export const ConditionFieldset = ({ tokenData }: { tokenData?: ITokenAlertCoin }) => {
  const { watch } = useFormContext<ITokenAlertFormData>();

  return (
    <div>
      <div className={stylesCommon.col25}>
        <h5 className={typography.bodySmallRegular}>Condition</h5>
      </div>
      <div className={`${stylesCommon.col75}`}>
        <div className={`${styles.content} ${watch('selectFilter') === 'priceChange' ? styles.gap16 : styles.gap8}`}>
          <ControlledSelect required name="selectFilter" options={filterOptions} placeholder="Select a filter..." />

          {watch('selectFilter') === 'priceChange' ? (
            <PriceInputWithSlider price={tokenData?.price} />
          ) : (
            <>
              <div className={styles.horizontalStack}>
                <ControlledSelect
                  required
                  name="selectOperator"
                  options={watch('selectFilter') === 'netVolumeChangeUSD' ? operatorOptions[1] : operatorOptions[0]}
                  placeholder="Select an operator..."
                />
                <ControlledInput required name="inputValue" placeholder="Enter a value..." type="number" />
              </div>
              <ControlledSelect
                required
                name="selectTimeframe"
                options={timeframeOptions}
                placeholder="Select a timeframe..."
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
