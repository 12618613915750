import dynamic from 'next/dynamic';
import Link from 'next/link';
import clsx from 'clsx';
import { Nav, Tooltip } from 'moralis-ui';
import { useSearchStore } from '@/components/SearchV2/hooks/useSearchStore';
import { useAuthToken } from '@/hooks/useAuth';
import { useAsideMenuData } from './hooks/useAsideMenuData';
import { AsideMenuLogo } from './AsideMenuLogo';
import { AsideSearchInput } from './AsideSearchInput';
import styles from './AsideMenu.module.scss';

const AsideMenuFooterUI = dynamic<{ isCollapsed: boolean }>(
  () => import('@/components/aside/AsideMenu/AsideMenuFooterUI').then((mod) => mod.AsideMenuFooterUI),
  {
    ssr: false,
  },
);
const AsideMenuSavedTokens = dynamic(() => import('./AsideMenuSavedTokens').then((mod) => mod.AsideMenuSavedTokens), {
  ssr: false,
});
const AsideMenuFooterUserInfo = dynamic(
  () => import('./AsideMenuFooterUserInfo').then((mod) => mod.AsideMenuFooterUserInfo),
  {
    ssr: false,
  },
);

export const AsideMenu = ({ isCollapsed }: { isCollapsed: boolean }) => {
  const { toggleSearchModal } = useSearchStore();
  const menuItems = useAsideMenuData();
  const { isLoggedIn } = useAuthToken();
  return (
    <aside
      className={clsx(styles.mainMenu, { [styles.collapsed]: isCollapsed })}
      id={isCollapsed ? 'sidebarCollapsed' : 'sidebarExpanded'}
    >
      <div className={clsx(styles.main, { [styles.collapsed]: isCollapsed })}>
        <div className={styles.logo}>
          <AsideMenuLogo isCollapsed={isCollapsed} />
        </div>
        <AsideSearchInput onClickOpenSearch={toggleSearchModal} isCollapsed={isCollapsed} />

        <div className={styles.inner}>
          <div className={styles.innerMainNav}>
            <Nav
              isCollapsed={isCollapsed}
              variant="filled"
              className={clsx(styles.navMenu, { [styles.collapsed]: isCollapsed })}
            >
              {menuItems.map((item) => (
                <Tooltip
                  color="navy"
                  position="right"
                  style={{ zIndex: 20, display: !isCollapsed || item.subItems ? 'none' : undefined }}
                  content={item.children}
                  key={item.id}
                  trigger={
                    item.subItems || !item.href ? (
                      <Nav.Item
                        icon={item.icon}
                        isActive={item.isActive}
                        onClick={item.onClick}
                        subItems={item.subItems}
                        className={styles.navItem}
                      >
                        {item.children}
                      </Nav.Item>
                    ) : (
                      <Link href={item.href} target={item.isExternal ? '_blank' : undefined}>
                        <Nav.Item
                          icon={item.icon}
                          isActive={item.isActive}
                          onClick={item.onClick}
                          subItems={item.subItems}
                          className={styles.navItem}
                        >
                          {item.children}
                        </Nav.Item>
                      </Link>
                    )
                  }
                />
              ))}
            </Nav>
          </div>
          <AsideMenuSavedTokens isCollapsed={isCollapsed} isLoggedIn={isLoggedIn} />
        </div>
      </div>
      <div className={styles.footer}>
        {isLoggedIn && !isCollapsed && <AsideMenuFooterUserInfo />}
        <AsideMenuFooterUI isCollapsed={isCollapsed} />
      </div>
    </aside>
  );
};
