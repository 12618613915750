import { isAddress } from 'viem';
import { ChainId, NonEvmChain } from '@/services';
import { asPipe } from './asPipe';
import { asNumber, asString, asStringEnum, asStringRegex, AsType } from './asType';
import { invariant } from './invariant';
import { isString } from './isType';

const CHAIN_ID_LIST_MAP: Record<ChainIdString, boolean> = {
  'solana': true,
  '0x1': true,
  '0x38': true,
  '0x2105': true,
  '0xa4b1': true,
  '0x89': true,
  '0xa86a': true,
  '0xa': true,
  '0x171': true,
  '0xe708': true,
  '0xfa': true,
};

const CHAIN_ID_MAP_CANONICAL = {
  solana: 'solana',
  ethereum: '0x1',
  binance: '0x38',
  base: '0x2105',
  arbitrum: '0xa4b1',
  polygon: '0x89',
  avalanche: '0xa86a',
  optimism: '0xa',
  linea: '0xe708',
  fantom: '0xfa',
  pulse: '0x171',
} as const satisfies Record<string, `${ChainId | NonEvmChain}`>;

export const DEX_TOOLS_CHAIN_ID_MAP = {
  [CHAIN_ID_MAP_CANONICAL.solana]: 'solana',
  [CHAIN_ID_MAP_CANONICAL.ethereum]: 'ether',
  [CHAIN_ID_MAP_CANONICAL.binance]: 'bsc',
  [CHAIN_ID_MAP_CANONICAL.base]: 'base',
  [CHAIN_ID_MAP_CANONICAL.arbitrum]: 'arbitrum',
  [CHAIN_ID_MAP_CANONICAL.polygon]: 'polygon',
  [CHAIN_ID_MAP_CANONICAL.avalanche]: 'avalanche',
  [CHAIN_ID_MAP_CANONICAL.optimism]: 'optimism',
  [CHAIN_ID_MAP_CANONICAL.linea]: 'linea',
  [CHAIN_ID_MAP_CANONICAL.fantom]: 'fantom',
  [CHAIN_ID_MAP_CANONICAL.pulse]: 'pulse',
} as const satisfies Record<ChainIdString, string>;

export type DexToolsChainId = (typeof DEX_TOOLS_CHAIN_ID_MAP)[keyof typeof DEX_TOOLS_CHAIN_ID_MAP];

export const CHAIN_ID_MAP = {
  ...CHAIN_ID_MAP_CANONICAL,
  eth: CHAIN_ID_MAP_CANONICAL.ethereum,
  bsc: CHAIN_ID_MAP_CANONICAL.binance,
  avax: CHAIN_ID_MAP_CANONICAL.avalanche,
  ftm: CHAIN_ID_MAP_CANONICAL.fantom,
  poly: CHAIN_ID_MAP_CANONICAL.polygon,
  op: CHAIN_ID_MAP_CANONICAL.optimism,
  linea: CHAIN_ID_MAP_CANONICAL.linea,
  arb: CHAIN_ID_MAP_CANONICAL.arbitrum,
} as const satisfies Record<string, ChainIdString>;

export const CHAIN_ID_LIST = Object.values(CHAIN_ID_MAP_CANONICAL);

export const USE_ETH_TOKEN_CHAIN_IDS: ChainIdString[] = [
  CHAIN_ID_MAP_CANONICAL.optimism,
  CHAIN_ID_MAP_CANONICAL.base,
  CHAIN_ID_MAP_CANONICAL.arbitrum,
  CHAIN_ID_MAP_CANONICAL.linea,
];

export const SUPPORTED_TOKEN_ALERT_CHAIN_IDS: ChainIdString[] = [
  CHAIN_ID_MAP_CANONICAL.binance,
  CHAIN_ID_MAP_CANONICAL.ethereum,
  CHAIN_ID_MAP_CANONICAL.polygon,
  CHAIN_ID_MAP_CANONICAL.arbitrum,
];

const SUPPORTED_BUBBLE_CHART_HOLDERS_CHAIN_IDS: ChainIdString[] = [
  CHAIN_ID_MAP_CANONICAL.solana,
  CHAIN_ID_MAP_CANONICAL.ethereum,
  CHAIN_ID_MAP_CANONICAL.base,
  CHAIN_ID_MAP_CANONICAL.arbitrum,
  CHAIN_ID_MAP_CANONICAL.polygon,
  CHAIN_ID_MAP_CANONICAL.avalanche,
  CHAIN_ID_MAP_CANONICAL.fantom,
];

export const UNSUPPORTED_CHAIN_IDS_HOLDERS_SUMMARY: ChainIdString[] = [
  CHAIN_ID_MAP_CANONICAL.solana,
  CHAIN_ID_MAP_CANONICAL.binance,
  CHAIN_ID_MAP_CANONICAL.polygon,
];

/**
 * Chains that are not supported by our services
 * @note We might use it for some specific cases e.g. "Coming soon"
 */
const UNSUPPORTED_CHAIN_IDS: ChainIdString[] = [];

/**
 * @todo: remove this when we introduce config-based approach
 */
export const UNSUPPORTED_CHAIN_IDS_METRIC_TOKEN_TIMEFRAME_SIDEBAR: ChainIdString[] = [CHAIN_ID_MAP.pulse];

export const SUPPORTED_CHAIN_IDS_WEB3_SERVICE = {
  chainIds: [
    CHAIN_ID_MAP_CANONICAL.binance,
    CHAIN_ID_MAP_CANONICAL.ethereum,
    CHAIN_ID_MAP_CANONICAL.base,
    CHAIN_ID_MAP_CANONICAL.arbitrum,
    CHAIN_ID_MAP_CANONICAL.polygon,
    CHAIN_ID_MAP_CANONICAL.avalanche,
    CHAIN_ID_MAP_CANONICAL.optimism,
    CHAIN_ID_MAP_CANONICAL.linea,
    CHAIN_ID_MAP_CANONICAL.fantom,
    CHAIN_ID_MAP_CANONICAL.pulse,
  ],
  endpoints: {
    web3ControllerGetWalletsNetWorth: [
      CHAIN_ID_MAP_CANONICAL.binance,
      CHAIN_ID_MAP_CANONICAL.ethereum,
      CHAIN_ID_MAP_CANONICAL.base,
      CHAIN_ID_MAP_CANONICAL.arbitrum,
      CHAIN_ID_MAP_CANONICAL.polygon,
      CHAIN_ID_MAP_CANONICAL.avalanche,
      CHAIN_ID_MAP_CANONICAL.optimism,
      CHAIN_ID_MAP_CANONICAL.linea,
      CHAIN_ID_MAP_CANONICAL.fantom,
    ],
  },
};

// docs: https://docs.moralis.com/web3-data-api/evm/reference/get-top-profitable-wallet-per-token
export const TOP_GAINERS_UNSUPPORTED_CHAIN_IDS: ChainIdString[] = [
  CHAIN_ID_MAP_CANONICAL.solana,
  CHAIN_ID_MAP_CANONICAL.binance,
  CHAIN_ID_MAP_CANONICAL.fantom,
  CHAIN_ID_MAP_CANONICAL.arbitrum,
  CHAIN_ID_MAP_CANONICAL.optimism,
  CHAIN_ID_MAP_CANONICAL.linea,
  CHAIN_ID_MAP_CANONICAL.avalanche,
  CHAIN_ID_MAP_CANONICAL.pulse,
];

// Maps chainId to token page url chain names
export const TOKEN_PAGE_CHAINS = {
  [CHAIN_ID_MAP_CANONICAL.solana]: 'solana',
  [CHAIN_ID_MAP_CANONICAL.ethereum]: 'ethereum',
  [CHAIN_ID_MAP_CANONICAL.binance]: 'binance',
  [CHAIN_ID_MAP_CANONICAL.base]: 'base',
  [CHAIN_ID_MAP_CANONICAL.arbitrum]: 'arbitrum',
  [CHAIN_ID_MAP_CANONICAL.polygon]: 'polygon',
  [CHAIN_ID_MAP_CANONICAL.avalanche]: 'avalanche',
  [CHAIN_ID_MAP_CANONICAL.optimism]: 'optimism',
  [CHAIN_ID_MAP_CANONICAL.linea]: 'linea',
  [CHAIN_ID_MAP_CANONICAL.fantom]: 'fantom',
  [CHAIN_ID_MAP_CANONICAL.pulse]: 'pulse',
};

type CI = typeof CHAIN_ID_MAP_CANONICAL;
export type ChainIdString = CI[keyof CI];
export type ChainNameCanonical = keyof CI;
export type ChainName = keyof typeof CHAIN_ID_MAP;

export const SUPPORTED_CHAINS = Object.values(CHAIN_ID_MAP_CANONICAL).filter(
  // eslint-disable-next-line sonarjs/no-empty-collection
  (id) => !UNSUPPORTED_CHAIN_IDS.includes(id),
);

export const PORTFOLIO_WALLET_SUPPORTED_CHAINS = SUPPORTED_CHAINS.filter((chain) => chain !== CHAIN_ID_MAP.solana);

export const isChainSupported = (chain: string) => SUPPORTED_CHAINS.includes(chain as ChainIdString);
export const isChainId = (chain: string | undefined): chain is ChainIdString =>
  CHAIN_ID_LIST.includes(chain as ChainIdString);
export const isChainName = (chain: string | undefined): chain is ChainName =>
  chain !== undefined && chain in CHAIN_ID_MAP;
export const isChainNameCanonical = (chain: string | undefined): chain is ChainName =>
  chain !== undefined && chain in CHAIN_ID_MAP;

const INVERT_MAP = Object.fromEntries(
  Object.entries(CHAIN_ID_MAP_CANONICAL).map(([key, value]) => [value, key]),
) as Record<ChainIdString, ChainName>;

export function getChainNameByChainId(chainId: ChainIdString): ChainName;
export function getChainNameByChainId<D extends string>(chainId: string | undefined, defaultName?: D): D | ChainName;
export function getChainNameByChainId<D extends string>(
  chainId: string | ChainIdString | undefined,
  defaultName: D = 'unknown' as D,
): D | ChainName {
  return isChainId(chainId) ? INVERT_MAP[chainId] : defaultName;
}

export const getChainIdByChainName = ((chainName): ChainIdString | undefined => {
  if (!isString(chainName)) {
    return undefined;
  }
  if (isChainName(chainName)) {
    return CHAIN_ID_MAP[chainName];
  }
  return undefined;
}) as {
  (chainName: ChainName): ChainIdString;
  (chainName: unknown): ChainIdString | undefined;
};

export const SUPPORTED_CHAINS_NAME_LIST = SUPPORTED_CHAINS.map((chainId) => getChainNameByChainId(chainId));

/**
 * @deprecated
 */
export const chainIdToChainNameMappingDexScreener = (chainId: string) => {
  switch (chainId) {
    case CHAIN_ID_MAP_CANONICAL.binance:
      return 'bsc';
    case CHAIN_ID_MAP_CANONICAL.pulse:
      return 'pulsechain';
    default:
      return getChainNameByChainId(chainId);
  }
};

export const asChainName = asStringEnum(CHAIN_ID_MAP);
export const asChainId = asStringEnum(CHAIN_ID_LIST_MAP);
export const asChainIdNumber: AsType<number> = (data, varName) =>
  asNumber(isString(data) && isChainId(data) ? Number.parseInt(data, 16) : data, varName);

// TODO: make it more correct?
// Taken from here https://www.reddit.com/r/solana/comments/soi03d/regex_for_validating_solana_addresses/?rdt=46370
const SOL_ADDR_RX = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
export const asTokenAddressOfSolana = asStringRegex(SOL_ADDR_RX, 'must be valid Solana address');

export const asTokenAddressOfETH = asPipe(asString).pipe((s) => {
  invariant(isAddress(s), `must be valid ethereum address, got ${s}`);
  return s;
}).run;

export const isBubbleMapSupported = (chainId: ChainIdString) =>
  SUPPORTED_BUBBLE_CHART_HOLDERS_CHAIN_IDS.includes(chainId);
