import { fetchMultiPortfolioData } from '@/components/manageWallets/libs/fetchMultiPortfolioData';
import { CACHE_DATA_ONE_DAY_TTL, SWR_DATA_REFRESH_INTERVAL } from '@/components/portfolio/const/constants';
import { cacheKey } from '@/components/portfolio/lib/cacheKey';
import { useAuthToken } from '@/hooks/useAuth';
import { useQueryWithStorage } from '@/hooks/useQueryWithStorage';
import { useUserWallets } from './useUserWallets';

export const useMultiWalletData = () => {
  const { requireAuthToken, decodedAuthToken: { moneyId } = {} } = useAuthToken();
  const userWallets = useUserWallets();

  return useQueryWithStorage(() => fetchMultiPortfolioData(requireAuthToken(), userWallets), {
    key: cacheKey.multiWalletWalletsAndNetWorth(moneyId),
    ttl: CACHE_DATA_ONE_DAY_TTL,
    keepPreviousData: true,
    skipIf: !moneyId,
    refreshInterval: SWR_DATA_REFRESH_INTERVAL,
  });
};
