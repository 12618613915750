import { GetTokenExplorerResponseDto, INotificationData, PutQueryRequestDto } from '@/services/index';
import { ProtocolType, User } from '@/stores/userStore';
import { normalizeTelegramUsername } from '@/utils/normalizeTelegramUsername';
import { notificationType } from '@/utils/notificationType';
import { getValidProtocol } from '@/utils/user/getValidProtocol';
import { IStrategyAlertFormData } from './StrategyAlert.types';

export const getFormDefaultValues = (
  user: User | null,
  query?: GetTokenExplorerResponseDto,
): IStrategyAlertFormData => {
  const serializedNotificationData: Partial<Record<ProtocolType, string>> = query?.notificationData
    ? Object.fromEntries(query.notificationData?.map((item) => [item.type, item.data]))
    : {};

  return {
    inputProtocolEmail: serializedNotificationData?.email || user?.email || '',
    inputProtocolTelegram:
      normalizeTelegramUsername(serializedNotificationData?.telegram || getValidProtocol('telegram', user)?.data[0]) ||
      '',
    inputProtocolWebhook: serializedNotificationData?.webhook || '',
    selectCoolDown: query?.monitorTimeInMinutes?.toString() || '',
    selectStrategy: query?.id || '',
    toggleProtocolEmail: Boolean(serializedNotificationData?.email),
    toggleProtocolTelegram: Boolean(serializedNotificationData?.telegram),
    toggleProtocolWebhook: Boolean(serializedNotificationData?.webhook),
  };
};

export const getPayload = (data: IStrategyAlertFormData, query: GetTokenExplorerResponseDto): PutQueryRequestDto => {
  const notificationData: INotificationData[] = [];
  if (data?.toggleProtocolTelegram && data?.inputProtocolTelegram) {
    notificationData.push({
      type: notificationType.Telegram,
      data: data.inputProtocolTelegram,
    });
  }

  if (data?.toggleProtocolWebhook && data?.inputProtocolWebhook) {
    notificationData.push({
      type: notificationType.Webhook,
      data: data.inputProtocolWebhook,
    });
  }

  if (data?.toggleProtocolEmail && data?.inputProtocolEmail) {
    notificationData.push({
      type: notificationType.Email,
      data: data.inputProtocolEmail,
    });
  }

  return {
    name: query.name,
    chainId: query.chainId,
    queries: query.queries,
    notification: true,
    monitorTimeInMinutes: Number(data.selectCoolDown),
    notificationData,
    id: query.id,
    email: data?.inputProtocolEmail,
  };
};
