import { API } from '@/api';
import { DEFAULT_AVATAR } from '@/components/beans/types';
import { createAppStore, StoreState } from './createAppStore';

export const {
  store: beanStore,
  reset: resetBeanState,
  createHook: createBeanHook,
} = createAppStore(
  {
    amount: 0,
    nextDate: null as number | null,
    rewards: {
      avatar: DEFAULT_AVATAR,
      chests: [] as API.User.Comps['UserChestResponseDto'][],
      items: [] as API.User.Comps['UserItemResponseDto'][],
      magicBeans: null as null | API.User.Comps['UserItemResponseDto'],
    },
  },
  { storeName: 'beans', persist: true },
);

export type BeansRewards = StoreState<typeof beanStore>['rewards'];

const set = beanStore.setState;
export const setBeanState = beanStore.setState;
export const setRewards = (rewards: BeansRewards) => set({ rewards });
