import { isHTTPAuthRequired } from './http';

export class FetchError extends Error {
  static fromResponse = (response: Response, method: string) => new FetchError(response, method);
  static is = (error: unknown): error is FetchError => error instanceof FetchError;
  static isLogout = (error: unknown): error is FetchError => FetchError.is(error) && isHTTPAuthRequired(error.status);

  public readonly response: Response;
  public readonly method: string;
  public readonly url: string;
  public readonly status: number;
  public readonly statusText: string;

  constructor(response: Response, method: string) {
    super(`Bad response. ${response.status} ${response.statusText}. Failed to ${method} "${response.url}"`);
    this.response = response;
    this.method = method;
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
  }
}
