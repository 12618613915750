import { useEffect } from 'react';
import { getUnixTime } from 'date-fns';
import { API } from '@/api';
import { setCustomer } from '@/stores/authStore';
import { setBeanState } from '@/stores/beanStore';
import { setStage, updateUser } from '@/stores/userStore';
import { apiBrowser } from '@/utils/api/apiBrowser';
import { FetchError } from '@/utils/api/FetchError';
import { logApp } from '@/utils/logApp';
import { isValidProtocol } from '@/utils/user/getValidProtocol';
import { useAuthCustomer, useAuthToken } from './useAuth';
import { useLogout } from './useLogout';

const log = logApp.create('useUserStatsService');
const fetchOrganizations = apiBrowser.billingCoordinator('/organizations', 'GET');
const postOrganizations = apiBrowser.billingCoordinator('/organizations', 'POST');
const fetchSubscriptions = apiBrowser.billingCoordinator('/subscriptions', 'GET');
const fetchUserStats = apiBrowser.user('/user/stats', 'GET');
const fetchPlan = apiBrowser.user('/user/plan', 'GET');
const fetchPlanStage = apiBrowser.user('/user/planStage', 'GET');

export const useUserStatsService = () => {
  const { handleLogout } = useLogout();
  const { authToken } = useAuthToken();
  const customer = useAuthCustomer();

  const getOrCreateMapping = async (token: string, userStats: API.User.Comps['UserStatsResponseDto']) =>
    await fetchOrganizations({ authToken: token }).catch(() =>
      postOrganizations({
        authToken: token,
        body: { email: userStats.email },
      }).then(() => fetchOrganizations({ authToken: token })),
    );
  const getPlanTypeId = (currentPlanTag: string): number => {
    switch (true) {
      case currentPlanTag.includes('starter'):
        return 20;
      case currentPlanTag.includes('pro'):
        return 40;
      case currentPlanTag.includes('staff'):
        return 69;
      case currentPlanTag.includes('enterprise'):
        return 90;
      default:
        return 1;
    }
  };

  // fixes bug on user login not updating values
  useEffect(() => {
    if (!authToken) return;
    handleUserStatus();
    getUserPlan();
  }, [authToken]);

  const getUserPlan = async () => {
    try {
      // NOTE GET PLANS FOR PRICE PAGE HERE LATER BILL
      const userPlanResponse = await fetchPlan({ authToken });

      let subPrice = '0';
      let isMonthlySub = true;

      let isOg = false;
      try {
        const ogCutOffTimeUnix = 1681578058;
        const subscription = await fetchSubscriptions({ authToken });
        const subCreated = new Date(subscription.createdAt);
        isOg = Boolean(getUnixTime(subCreated) < ogCutOffTimeUnix);
        subPrice = subscription.unit.item.unit_amount_decimal;
        isMonthlySub = subscription.unit.annual ? false : true;
      } catch (err) {
        log.warnError('Unable to get subscription data', err);
        isOg = false;
      }

      updateUser({
        isOg: isOg,
        plan: {
          ...userPlanResponse,
          planTypeId: getPlanTypeId(userPlanResponse.tag),
          price: Number(subPrice),
          isMonthly: isMonthlySub,
        },
      });

      const stage = await fetchPlanStage({ authToken });
      setStage(stage);
    } catch (error) {
      // TODO: (Kristam) WE NEED TO HANDLE THIS ERROR BETTER. SUPPRESSED FOR NOW TO STOP SPAMMING FE NOTIFICATIONS
      // ERRORS SHOULD PROVIDE FEEDBACK TO THE USER SO THEY KNOW WHAT TO DO, NO POINT SAYING
      // "Can't get userPlanStageDto" they don't know what that means -
      // user message should be human/actionable/clear
      log.error('Unable to get user planPlanStageDto', error);
    }
  };

  const handleUserStatus = async () => {
    try {
      const userStatusResponse = await fetchUserStats({ authToken });
      setBeanState({
        amount: userStatusResponse.beanTotal,
        nextDate: userStatusResponse.nextClaimDate,
      });
      updateUser({
        connectedAddress: userStatusResponse.address,
        email: userStatusResponse.email,
        id: userStatusResponse.userId,
        isEmailValidated: userStatusResponse.isEmailValidated,
        lastLogin: userStatusResponse.lastLogged,
        profileId: userStatusResponse.profileId,
        validatedProtocols: userStatusResponse.validatedProtocols?.filter(isValidProtocol),
        addonLimits: userStatusResponse.addonLimits,
        purchasedTrialPlan: userStatusResponse.purchasedTrialPlan,
        tradingViewUserName: userStatusResponse.tradingViewUserName,
      });
      if (!customer && authToken) {
        const customer = await getOrCreateMapping(authToken, userStatusResponse);
        setCustomer(customer);
      }
    } catch (err) {
      if (FetchError.isLogout(err)) {
        handleLogout({
          redirectUrl: null,
          notification: null,
        });
        return;
      }
      throw err;
    }
  };

  const updateUserStats = async () => {
    if (!authToken) return;
    handleUserStatus();
    getUserPlan();
  };

  return { updateUserStats };
};
