import useSWR, { mutate } from 'swr';
import { apiBrowser } from '@/utils/api/apiBrowser';

const fetchSearchCoins1 = apiBrowser.insightsSearch('/coin-search/search', 'POST');
const fetchSearchCoins = apiBrowser.insightsSearch('/coin-search/search', 'GET');

const fetchSearchQuery = async ([, [inputValue, chainIds]]: [string, [string | undefined, string[] | undefined]]) => {
  if (!inputValue) {
    return undefined;
  }

  const coins = chainIds?.length
    ? await fetchSearchCoins1({ body: { query: inputValue, chainIds } })
    : await fetchSearchCoins({ queryParams: { query: inputValue } });

  mutate(['search-v2-query', [inputValue, chainIds]], coins, false);

  return coins;
};

export const useSearchV2 = (inputValue?: string, chainIds?: string[]) => {
  const cacheKey = ['search-v2-query', [inputValue, chainIds]];

  const {
    data: searchResult,
    isValidating,
    isLoading,
  } = useSWR(cacheKey, fetchSearchQuery, {
    revalidateOnFocus: false,
    revalidateIfStale: true,
  });

  const resetSearchResults = async () => {
    await mutate(cacheKey, null, false);
  };

  return { searchResult, isFetching: isLoading || isValidating, resetSearchResults };
};
