import { getCookie } from 'cookies-next';
import { API } from '@/api';
import { CoinFilterDto } from '@/services';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { ITokenExplorerQuery } from '../api/types';
import { EnvSegment } from '../env/segmentClient';
import { logApp } from '../logApp';
import { getChainNameByChainId } from '../supportedChains';

const consentCookieName = 'CookieScriptConsent';
type TConsentCookieCategories = 'performance' | 'targeting' | 'unclassified';

const waitFor = (isReady: () => boolean, { pollingMs }: { pollingMs: number }) => {
  if (isReady()) {
    return Promise.resolve();
  }
  return new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (isReady()) {
        clearInterval(interval);
        resolve();
      }
    }, pollingMs);
  });
};

export class AnalyticsTracker {
  private analytics = new AnalyticsBrowser();
  private serviceVersion = '1';
  private source = 'moralis-money-frontend';
  private log = logApp.create(import.meta.url);

  constructor() {}

  load = async () => {
    // load the analytics library only after the consent cookie is set for GDPR compliance
    await waitFor(() => this.getAcceptedCategoriesFromConsentCookie().targeting, { pollingMs: 1000 });
    this.analytics.load(EnvSegment.cfg, {
      integrations: { 'Segment.io': EnvSegment.intergationCfg },
    });
    this.log.debug('load', null);
  };

  private getAcceptedCategoriesFromConsentCookie() {
    const cookie = getCookie(consentCookieName);
    const categories: Record<TConsentCookieCategories, boolean> = {
      performance: false,
      targeting: false,
      unclassified: false,
    };
    try {
      const parsedCookie = cookie && typeof cookie == 'string' ? JSON.parse(cookie) : null;
      if (parsedCookie?.categories) {
        const acceptedCategories: TConsentCookieCategories[] =
          (typeof parsedCookie.categories === 'string'
            ? JSON.parse(parsedCookie.categories)
            : parsedCookie.categories) ?? [];
        for (const category of acceptedCategories) {
          categories[category] = true;
        }
      }
    } catch (error) {
      this.log.warnError('[getAcceptedCategoriesFromConsentCookie] Error parsing consent cookie', error, { cookie });
      // Do nothing
    }
    return categories;
  }

  trackIdentify = async (userId?: string) => {
    try {
      await Promise.all([
        //
        this.analytics.identify(userId),
        this.analytics.identify({ userId: userId }),
      ]);
      this.log.debug('trackIdentify', { userId });
    } catch (error) {
      this.log.warnError('[trackIdentify] Error identifying user', error, { userId });
    }
  };

  trackButtonClick = async (name: string, plan?: string, billingPeriod?: string) => {
    try {
      this.analytics.track('buttonClicked', {
        button_name: name,
        plan: plan,
        billing_period: billingPeriod,
        service_version: this.serviceVersion,
        source: this.source,
      });
      this.log.debug('trackButtonClick', { name, plan, billingPeriod });
    } catch (error) {
      this.log.warnError('[trackButtonClick] Error tracking button click', error, { name, plan, billingPeriod });
    }
  };

  trackLinkClick = async (text: string, href?: string, location?: string) => {
    try {
      this.analytics.track('linkClicked', {
        link_label: text,
        destination: href,
        link_location: location,
        service_version: this.serviceVersion,
        source: this.source,
      });
      this.log.debug('trackLinkClick', { text, href, location });
    } catch (error) {
      this.log.warnError('[trackLinkClick] Error tracking link click', error, { text, href, location });
    }
  };

  trackLinkEbookClick = async (text: string, href?: string, location?: string) => {
    try {
      this.analytics.track('buttonClicked', {
        link_label: text,
        destination: href,
        link_location: location,
        service_version: this.serviceVersion,
        source: this.source,
      });
      this.log.debug('trackLinkEbookClick', { text, href, location });
    } catch (error) {
      this.log.warnError('[trackLinkEbookClick] Error tracking link click', error, { text, href, location });
    }
  };

  trackMonitorToken = async (
    interval: number,
    emailNotification: boolean,
    webhookNotification: boolean,
    telegramNotification: boolean,
    query?: ITokenExplorerQuery[],
    chainId?: string,
  ) => {
    try {
      this.analytics.track('monitoringActivated', {
        notificationInterval: interval,
        query: query,
        chainId: chainId,
        webhookNotification: webhookNotification,
        emailNotification: emailNotification,
        telegramNotification: telegramNotification,
        service_version: this.serviceVersion,
        source: this.source,
      });
      this.log.debug('trackMonitorToken', {
        interval,
        chainId,
        telegramNotification,
        emailNotification,
        webhookNotification,
        query,
      });
    } catch (error) {
      this.log.warnError('[trackMonitorToken] Error tracking monitor token', error, {
        interval,
        emailNotification,
        webhookNotification,
        telegramNotification,
        query,
        chainId,
      });
    }
  };

  trackTokenSaved = async (metadata: { coinAddress?: string; chainId?: string; name?: string }) => {
    try {
      this.analytics.track('tokenSaved', {
        address: metadata.coinAddress,
        chain: metadata.chainId,
        coinName: metadata.name,
        service_version: this.serviceVersion,
        source: this.source,
      });
      this.log.debug('trackTokenSaved', metadata);
    } catch (error) {
      this.log.warnError('[trackTokenSaved] Error tracking token saved', error, { metadata });
    }
  };

  trackTokenTrade = async (props: {
    chainId: string | undefined;
    name: string | undefined;
    symbol: string | undefined;
    rating: number | undefined;
  }) => {
    const { chainId, name, symbol, rating } = props;
    try {
      this.analytics.track('tokenTrade', {
        chain: chainId,
        coinName: name,
        coinSymbol: symbol,
        coinRating: rating,
        service_version: this.serviceVersion,
        source: this.source,
      });
      this.log.debug('trackTokenTrade', props);
    } catch (error) {
      this.log.warnError('[trackTokenTrade] Error tracking token trade', error, props);
    }
  };

  trackSaveQuery = async (
    name: string,
    chainId: string,
    query: {
      filter: string;
      metric: string;
      value: number;
    }[],
  ) => {
    try {
      this.analytics.track('querySaved', {
        queryName: name,
        chainId: chainId,
        query: query,
        service_version: this.serviceVersion,
        source: this.source,
      });
      this.log.debug('trackSaveQuery', { name, chainId, query });
    } catch (error) {
      this.log.warnError('[trackSaveQuery] Error tracking save query', error, { name, chainId, query });
    }
  };

  trackTokenExplorerSearch = async (
    chainId: string,
    query: API.Insights.Comps['getCoinsByFilteringOptions'] | CoinFilterDto[],
    filterSource: string,
    totalResultsCount?: number,
  ) => {
    try {
      this.analytics.track('tokenExplorerSearch', {
        filter_source: filterSource,
        totalResultsCount: totalResultsCount,
        chain: getChainNameByChainId(chainId),
        chainId: chainId,
        query: query,
        service_version: this.serviceVersion,
        source: this.source,
      });
      this.log.debug('trackTokenExplorerSearch', {
        filterSource,
        totalResultsCount,
        chainId,
        query,
      });
    } catch (error) {
      this.log.warnError('[trackTokenExplorerSearch] Error tracking token explorer search', error, {
        chainId,
        query,
        filterSource,
        totalResultsCount,
      });
    }
  };

  trackPageView = async (extraParams?: Record<string, unknown>) => {
    const basicParams = {
      service_version: this.serviceVersion,
      source: this.source,
      // FIXME:(Maxim) no idea why it's broken, but without manually setting data from location
      // it sends path as url and stale url
      hash: window.location.hash,
      path: window.location.pathname,
      url: window.location.href,
    };
    const params = extraParams ? Object.assign(extraParams, basicParams) : basicParams;
    try {
      this.analytics.page(params);
      this.log.debug('trackPageView', params);
    } catch (error) {
      this.log.warnError('[trackPageView] Error tracking page view', error, extraParams);
    }
  };
}
