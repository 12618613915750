export const invariant: (condition: unknown, message: string) => asserts condition = (condition, message) => {
  if (condition) {
    return;
  }
  throw new Error(['Invariant failed:', message].join(' '));
};

export const ensureValue = <T>(value: T | null | undefined, message: string): T => {
  invariant(value !== null && value !== undefined, message);
  return value;
};
