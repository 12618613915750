import { InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { API } from '@/api';
import { EDataSummaryType } from '@/api/generated/Insights.openapi';
import { TrendingPage } from '@/components/HomePageTrending/TrendingPage';
import { Layout } from '@/components/layout/Layout';
import { useIsMounted } from '@/hooks/useIsMounted';
import { fetchTrendingTokens } from '@/utils/api/browser/fetchTrendingTokens';
import {
  DataSummaryVolume,
  defaultChainDataSummary,
  fetchChainDataSummary,
  fetchChainDataSummaryGraphs,
} from '@/utils/api/ssr/fetchChainsDataSummary';
import { fetchTopTrendingTokensFromAPI } from '@/utils/api/ssr/fetchTopTrendingTokensFromAPI';
import { getSortedCategoriesByVolume } from '@/utils/api/ssr/getSortedCategoriesByVolume';
import { fetchContentfulAdsData } from '@/utils/contentful/fetchAds';
import { fetchContentfulGlossaryData } from '@/utils/contentful/fetchGlossary';
import { Advertisement, TGlossaryItem, TGlossarySection } from '@/utils/contentful/type';
import { handleSettledPromiseResult } from '@/utils/handleSettledPromiseResult';
import { logApp } from '@/utils/logApp';
import { CACHE_KEY, CACHE_TTL, redisCache } from '@/utils/redis/redisCache';
import { CoinCategory } from '@/utils/tx/categoriesResultHardcoded';
import { withL10n } from '@/utils/withL10n';
import { t } from '@lingui/core/macro';

const log = logApp.create('useTrendingTokens HP');

const HomePage = ({
  pageInfo,
  resolvedUrl,
  glossaryMoneyMetricTerms,
  ads,
  sortedCategoriesByVolume,
  dataSummaryTokens,
  dataSummaryTotalVolume,
  dataSummaryNetVolume,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { data: trendingTokenData = [], isLoading } = useSWR('trending-token', () => fetchTrendingTokens());
  const isMounted = useIsMounted();
  const router = useRouter();

  return (
    <Layout
      hasFooter={true}
      hasHeader={true}
      hasSearchBar={false}
      hasTwitterBanner={false}
      layout="tokenExp"
      metaTags={
        <Layout.MetaTags
          title={pageInfo.title}
          description={pageInfo.description}
          url={resolvedUrl}
          includeDefaultSeoTitle={false}
        />
      }
      noPadding={true}
      screenWidth="full"
    >
      <TrendingPage
        ads={ads}
        chainId={null}
        category={null}
        glossaryMoneyMetricTerms={glossaryMoneyMetricTerms}
        sortedCategories={sortedCategoriesByVolume}
        dataSummaryTokens={dataSummaryTokens}
        dataSummaryTotalVolume={dataSummaryTotalVolume}
        dataSummaryNetVolume={dataSummaryNetVolume}
        isTrendingTokenDataLoading={!isMounted ? true : isLoading || !router.isReady}
        trendingTokenData={trendingTokenData}
      />
    </Layout>
  );
};

export const getServerSideProps = withL10n.getServerSideProps(async (context) => {
  const defaultPageInfo: { title: string; description: string } = {
    title: t`Trending Crypto Tokens by On-Chain Data`,
    description: t`Discover trending crypto tokens based on real-time on-chain data across multiple chains. Stay ahead by knowing which crypto tokens are currently trending.`,
  };

  const chainIdForCacheKey = getStringForCacheKey(null);
  const categoryForCacheKey = getStringForCacheKey(null);

  const tokensPromise = (async () => {
    const cacheKey = CACHE_KEY.trendingTokens({ category: categoryForCacheKey, chainId: chainIdForCacheKey });
    const cachedTokens = await redisCache.read<{
      coins: API.Coins.Comps['TrendingCoinInfoDto'][];
      title: string;
      description: string;
    }>(cacheKey);
    if (cachedTokens) {
      return cachedTokens;
    }

    try {
      const tokens = await fetchTopTrendingTokensFromAPI(context.req, {});
      const tokensData = {
        coins: tokens.coins?.filter((token) => token.name !== null) || [],
        title: tokens.title || defaultPageInfo.title,
        description: tokens.description || defaultPageInfo.description,
      };

      redisCache.saveQuiet({
        key: cacheKey,
        data: tokensData,
        ttl: CACHE_TTL.THREE_MINUTES,
      });

      return tokensData;
    } catch {
      return {
        coins: [],
        title: defaultPageInfo.title,
        description: defaultPageInfo.description,
      };
    }
  })();

  const glossaryPromise = (async () => {
    const cachedGlossary = await redisCache.read<TGlossarySection[]>(CACHE_KEY.glossaryMoneyMetricTerms);
    if (cachedGlossary) {
      return cachedGlossary;
    }

    try {
      const glossaryData = await fetchContentfulGlossaryData('moralis-money-metrics');
      const glossaryTerms =
        glossaryData?.sectionsCollection?.items.flatMap(
          (section: TGlossarySection) => section.termsCollection?.items || [],
        ) ?? [];

      redisCache.saveQuiet({
        key: CACHE_KEY.glossaryMoneyMetricTerms,
        data: glossaryTerms,
        ttl: CACHE_TTL.ONE_DAY,
      });

      return glossaryTerms;
    } catch (error) {
      log.error('Failed to load glossary data HP', error);
      return [];
    }
  })();

  const adsPromise = fetchContentfulAdsData(context.req);

  const sortedCategoriesByVolumePromise = (async () => {
    const cacheKey = CACHE_KEY.sortedCategoriesByVolume({ chainId: chainIdForCacheKey });
    const cachedCategories = await redisCache.read(cacheKey);

    if (Array.isArray(cachedCategories)) {
      return cachedCategories;
    }

    try {
      const categories = await getSortedCategoriesByVolume(context.req);

      redisCache.saveQuiet({
        key: cacheKey,
        data: categories,
        ttl: CACHE_TTL.ONE_HOUR,
      });

      return categories;
    } catch (error) {
      log.error('Failed to load sorted categories from API', error);
      return [];
    }
  })();

  const dataSummaryTokensPromise = (async (): Promise<API.InsightsFilter.Comps['ChainDataSummaryDto']> => {
    const cacheKey = CACHE_KEY.dataSummaryTokens({ category: categoryForCacheKey, chainId: chainIdForCacheKey });
    const cachedDataSummary = await redisCache.read<API.InsightsFilter.Comps['ChainDataSummaryDto']>(cacheKey);

    if (cachedDataSummary) {
      return cachedDataSummary;
    }

    try {
      const dataSummary = await fetchChainDataSummary(context.req, { chainId: null, category: null });

      if (!dataSummary?.chainIds?.length) {
        return defaultChainDataSummary;
      }

      redisCache.saveQuiet({
        key: cacheKey,
        data: dataSummary,
        ttl: CACHE_TTL.ONE_HOUR,
      });

      return dataSummary;
    } catch (error) {
      log.error('Failed to fetch data summary tokens', error);
      return defaultChainDataSummary;
    }
  })();

  const dataSummaryTotalVolumePromise = (async (): Promise<DataSummaryVolume[]> => {
    const cacheKey = CACHE_KEY.dataSummaryTotalVolume({ category: categoryForCacheKey, chainId: chainIdForCacheKey });
    const cachedDataSummary = await redisCache.read<DataSummaryVolume[]>(cacheKey);

    if (cachedDataSummary) {
      return cachedDataSummary;
    }

    try {
      const dataSummaryTotalVolume = await fetchChainDataSummaryGraphs(
        context.req,
        { chainId: null, category: null },
        EDataSummaryType.total_volume,
      );

      if (!dataSummaryTotalVolume || dataSummaryTotalVolume.length === 0) {
        return [];
      }

      redisCache.saveQuiet({
        key: cacheKey,
        data: dataSummaryTotalVolume,
        ttl: CACHE_TTL.THREE_HOURS,
      });

      return dataSummaryTotalVolume;
    } catch (error) {
      log.error('Failed to fetch data summary total volume', error);
      return [];
    }
  })();

  const dataSummaryNetVolumePromise = (async (): Promise<DataSummaryVolume[]> => {
    const cacheKey = CACHE_KEY.dataSummaryNetVolume({ category: categoryForCacheKey, chainId: chainIdForCacheKey });
    const cachedDataSummary = await redisCache.read<DataSummaryVolume[]>(cacheKey);

    if (cachedDataSummary) {
      return cachedDataSummary;
    }

    try {
      const dataSummaryNetVolume = await fetchChainDataSummaryGraphs(
        context.req,
        { chainId: null, category: null },
        EDataSummaryType.net_volume,
      );

      if (!dataSummaryNetVolume || dataSummaryNetVolume.length === 0) {
        return [];
      }

      redisCache.saveQuiet({
        key: cacheKey,
        data: dataSummaryNetVolume,
        ttl: CACHE_TTL.THREE_HOURS,
      });

      return dataSummaryNetVolume;
    } catch (error) {
      log.error('Failed to fetch data summary net volume', error);
      return [];
    }
  })();

  const [
    tokensResult,
    glossaryResult,
    adsResult,
    categoriesResult,
    dataSummaryTokensResult,
    dataSummaryTotalVolumeResult,
    dataSummaryNetVolumeResult,
  ] = await Promise.allSettled([
    tokensPromise,
    glossaryPromise,
    adsPromise,
    sortedCategoriesByVolumePromise,
    dataSummaryTokensPromise,
    dataSummaryTotalVolumePromise,
    dataSummaryNetVolumePromise,
  ]);

  const tokens = handleSettledPromiseResult<{
    coins: API.Coins.Comps['TrendingCoinInfoDto'][];
    title: string;
    description: string;
  }>(tokensResult, 'Tokens') ?? { coins: [], title: '', description: '' };
  const glossaryMoneyMetricTerms: TGlossaryItem[] = handleSettledPromiseResult(glossaryResult, 'Glossary') || [];
  const ads: Advertisement[] = handleSettledPromiseResult(adsResult, 'Ads') || [];
  const sortedCategoriesByVolume: (CoinCategory & { oneDayVolumeUsd: string })[] =
    handleSettledPromiseResult(categoriesResult, 'Sorted Categories') || [];
  const dataSummaryTokens = handleSettledPromiseResult<API.InsightsFilter.Comps['ChainDataSummaryDto']>(
    dataSummaryTokensResult,
    'Data Summary Tokens Info',
  ) ?? { chainIds: [] };
  const dataSummaryTotalVolume =
    handleSettledPromiseResult(dataSummaryTotalVolumeResult, 'Data Summary Total Volume') || [];
  const dataSummaryNetVolume = handleSettledPromiseResult(dataSummaryNetVolumeResult, 'Data Summary Net Volume') || [];

  return {
    props: {
      pageInfo: Array.isArray(tokens)
        ? { title: defaultPageInfo.title, description: defaultPageInfo.description }
        : { title: tokens.title, description: tokens.description },
      resolvedUrl: context.resolvedUrl,
      glossaryMoneyMetricTerms,
      ads,
      sortedCategoriesByVolume,
      dataSummaryTokens,
      dataSummaryTotalVolume,
      dataSummaryNetVolume,
    },
  };
});

// Helper functions to ensure category and chainId are strings
export function getStringForCacheKey(value: { id?: string } | string | null): string {
  if (typeof value === 'string') {
    return value ?? 'null';
  }
  return value?.id ?? 'null';
}

export default HomePage;
