'use client';
import { useEffect } from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';

export const PageViewTracking = ({ pageViewProps }: { pageViewProps?: Record<string, unknown> }) => {
  const { trackPageView } = useAnalytics();
  useEffect(() => {
    trackPageView(pageViewProps);
  }, [pageViewProps, trackPageView]);

  return null;
};
