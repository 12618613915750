import useSWR, { Arguments, SWRConfiguration, SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import useSWRMutation, { SWRMutationConfiguration, SWRMutationResponse } from 'swr/mutation';
import { invariant } from './invariant';
import { transformAsyncCatch } from './transformAsync';

export namespace swrHook {
  type K = string | ReadonlyArray<string | number | boolean>;
  export type Fetcher<T, Arg> = (arg: Arg) => Promise<T>;
  export type HandleError<T, Arg> = (err: unknown, arg: Arg) => T | Promise<T>;
  export type SWRConfig<T> = SWRConfiguration<T, unknown>;
  export type SWRConfigMutate<T, Arg> = SWRMutationConfiguration<T, unknown, (arg: Arg) => Arguments, Arg>;
  export type Key<Arg> = K | ((arg: Arg) => null | K);
  export type Hook<T, Arg> = (arg: Arg | null, cfg?: InstanceConfig<T, Arg, SWRConfig<T>>) => SWRResponse<T, unknown>;
  export type HookMutate<T, Arg> = (
    cfg?: InstanceConfig<T, Arg, SWRConfigMutate<T, Arg>>,
  ) => SWRMutationResponse<T, unknown, any, Arg>;

  export type StaticConfig<T, Arg, C = SWRConfig<T>> = {
    handleError?: HandleError<T, Arg>;
    swr?: C;
  };

  export type InstanceConfig<T, Arg, C = SWRConfig<T>> = {
    disable?: boolean;
    handleError?: HandleError<T, Arg>;
    swr?: C;
  };
}

const getSpareParts = <T, Arg, C extends swrHook.SWRConfig<T> | swrHook.SWRConfigMutate<T, Arg>>(
  key: swrHook.Key<Arg>,
  fn: swrHook.Fetcher<T, Arg>,
  staticCfg: swrHook.StaticConfig<T, Arg, C>,
) => {
  invariant(key, 'key is required');
  return {
    getKey:
      typeof key === 'function'
        ? (arg: Arg | null) =>
            // Wrapped into function to be able to throw calling keyFn
            () => {
              if (arg === null) {
                return null;
              }
              const k = key(arg);
              return k === null ? null : ([k, arg] as [Arguments, Arg]);
            }
        : (arg: Arg | null) => (arg === null ? null : ([key, arg] as [Arguments, Arg])),
    getFetcher: (arg: Arg | null, config: swrHook.InstanceConfig<T, Arg, C>) => {
      if (arg === null) {
        return null as T;
      }
      const handleError = staticCfg.handleError || config.handleError;
      if (handleError) {
        const trans = transformAsyncCatch(fn, handleError);
        return trans(arg);
      }
      return fn(arg);
    },
    getSWRConfig: (config: swrHook.InstanceConfig<T, Arg, C>) =>
      config.swr && staticCfg.swr ? { ...staticCfg.swr, ...config.swr } : config.swr ?? staticCfg.swr,
  };
};

/**
 * Simple wrapper over useSWR and useSWRImmutable
 * @usage
 * ```tsx
 * const useFoo = swrHook.create(async (abc: string) => Number(abc));
 * // or
 * const useFoo = swrHook.create(async (abc: string) => Number(abc), {immutable: true, revalidateOnFocus: false});
 * // =>
 * useFoo('123').data; // number | undefined
 * useFoo('123', {disable: true}).data; // Funcrtion is not called => undefined
 * ```
 * You can control enable/disable by passing `disable` prop or return null instead of the Promise:
 * ```tsx
 * const fetchMe = apiFetcher.oneInch('/v6.0/{chain}/approve/allowance', 'GET');
 * const useFoo = swrHook.create(
 *   ({ tokenAddress, walletAddress, chain }: { tokenAddress?: string; walletAddress?: number; chain: string }) =>
 *     isString(tokenAddress) && isNumber(walletAddress)
 *       ? fetchMe({
 *           pathParams: {
 *             chain,
 *           },
 *           queryParams: {
 *             tokenAddress,
 *             walletAddress: String(walletAddress),
 *           },
 *         })
 *       : null,
 * );
 * const Comp = ({ tokenAddress, walletAddress }: { tokenAddress?: string; walletAddress?: number }) => {
 *   const { data } = useFoo({
 *     tokenAddress,
 *     walletAddress,
 *     chain: 'ethereum',
 *   });
 * };
 * ```
 */
export const swrHook = {
  getSpareParts,

  create: <T, Arg>(
    key: swrHook.Key<Arg>,
    fn: swrHook.Fetcher<T, Arg>,
    staticCfg: swrHook.StaticConfig<T, Arg, swrHook.SWRConfig<T>> = {},
  ) => {
    const { getKey, getFetcher, getSWRConfig } = getSpareParts(key, fn, staticCfg);
    return (arg: Arg | null, cfg: swrHook.InstanceConfig<T, Arg, swrHook.SWRConfig<T>> = {}) =>
      useSWR(getKey(arg), async () => await getFetcher(arg, cfg), getSWRConfig(cfg));
  },

  immutable: <T, Arg>(
    key: swrHook.Key<Arg>,
    fn: swrHook.Fetcher<T, Arg>,
    staticCfg: swrHook.StaticConfig<T, Arg> = {},
  ) => {
    const { getKey, getFetcher, getSWRConfig } = getSpareParts(key, fn, staticCfg);
    return (arg: Arg | null, cfg: swrHook.InstanceConfig<T, Arg> = {}) =>
      useSWRImmutable(getKey(arg), async () => await getFetcher(arg, cfg), getSWRConfig(cfg));
  },

  mutation: <T, Arg>(
    key: swrHook.Key<Arg>,
    fn: swrHook.Fetcher<T, Arg>,
    staticCfg: swrHook.StaticConfig<T, Arg, swrHook.SWRConfigMutate<T, Arg>> = {},
  ) => {
    const { getKey, getFetcher, getSWRConfig } = getSpareParts(key, fn, staticCfg);
    return (cfg: swrHook.InstanceConfig<T, Arg, swrHook.SWRConfigMutate<T, Arg>> = {}) =>
      useSWRMutation(getKey, (_: unknown, { arg }: { arg: Arg }) => getFetcher(arg, cfg), getSWRConfig(cfg));
  },
};
