import { createStore, StoreApi, useStore } from 'zustand';
import { devtools, persist as persistFn } from 'zustand/middleware';
import shallowFn from 'zustand/shallow';
import { isDevModeEnabled } from '@/utils/devMode';
import { EnvApp } from '@/utils/env/app';

type CompareFn<T> = (a: T, b: T) => boolean;
type Options<T> = {
  shallow?: boolean;
  comparator?: CompareFn<T>;
};

export type StoreState<T extends StoreApi<unknown>> = T extends StoreApi<infer U> ? U : never;
export const createAppStore = <S>(initState: S, { persist, storeName }: { persist?: boolean; storeName: string }) => {
  const init = persist ? persistFn(() => initState, { name: ['mm', storeName, 'storage'].join('-') }) : () => initState;
  const store = createStore(
    devtools(init, {
      name: storeName,
      enabled: isDevModeEnabled(),
      shouldHotReload: EnvApp.environment === 'local',
      shouldRecordChanges: true,
      trace: true,
    }),
  );

  const createHook: {
    <V>(selector: (state: S, arg?: never) => V, opts?: Options<V>): (arg?: never) => V;
    <V, A>(selector: (state: S, arg: A) => V, opts?: Options<V>): (arg: A) => V;
  } =
    <V, A>(
      selector: (state: S, arg: A) => V,
      { shallow, comparator = shallow ? shallowFn : undefined }: Options<unknown> = {},
    ) =>
    (arg: A) =>
      useStore(store, (state) => selector(state, arg), comparator);

  return {
    store,
    reset: () => store.setState(initState, true),
    createHook,
  };
};
