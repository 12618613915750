import { useMemo } from 'react';
import { ApiError } from '@/services/index';
import { api } from '@/utils/api';
import { DisplayableError } from '@/utils/DisplayableError';
import { tErrorFeedback } from '@/utils/l10n/generic';
import { createMagic, loginWithEmailOTP } from '@/utils/magic';
import { getValidProtocol } from '@/utils/user/getValidProtocol';
import { useUser } from './useUser';

export const useMagic = () => {
  const user = useUser();
  const magic = useMemo(createMagic, []);

  const addEmail = async (newEmailToken: string) =>
    api.user.userControllerAddEmail({
      token: newEmailToken,
    });

  const sendMagicLink = async (email: string) => {
    try {
      const newEmailToken = await loginWithEmailOTP(magic, email);
      if (!newEmailToken) {
        throw new DisplayableError('Error processing the Magic code.', 'Please check your email and retry');
      }
      await addEmail(newEmailToken);
      return newEmailToken;
    } catch (error) {
      let errorMessage = tErrorFeedback();
      if (error instanceof ApiError) {
        errorMessage = error.body?.cause as string;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      throw new DisplayableError('Email Authentication Failed', errorMessage);
    }
  };

  const validateEmail = async (email: string) => {
    const emailValidated = getValidProtocol('email', user)?.data.includes(email);
    if (!emailValidated && email !== user?.email) {
      return sendMagicLink(email);
    }
    return email;
  };

  return { sendMagicLink, validateEmail };
};
