import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { ButtonLink } from 'moralis-ui';
import { AdSource, useAds } from '@/hooks/useAds';
import { TokenAdsView } from '@/services/models/GetTokenAdsViewDto';
import { ERoutePath } from '@/utils/ERoutePath';
import { faRectangleAd } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/react/macro';
import { TokenAdBanner } from './TokenAdBanner';
import { TokenAdBannerSkeleton } from './TokenAdBannerSkeleton';
import styles from './HomeTokenAd.module.scss';

export const HomeTokenAd = ({
  tokenAdsView,
  isLoading,
}: {
  tokenAdsView?: TokenAdsView | null;
  isLoading: boolean;
}) => {
  const [tokenAd, setTokenAd] = useState<TokenAdsView | null | undefined>(null);
  const animationRef = useRef<HTMLDivElement>(null);
  const { onAdClick } = useAds(AdSource.HOME_BANNER);
  const hasCta = Boolean(tokenAd?.cta?.text);
  const hrefLinkCta = hasCta ? tokenAd?.cta?.link : tokenAd?.tokenURL;

  useEffect(() => {
    if (animationRef.current && tokenAdsView?.moneyId !== tokenAd?.moneyId) {
      animationRef.current.classList.add(styles.fadeOutFadeIn);

      const tokenAdAttributionTimeout = setTimeout(() => {
        setTokenAd(tokenAdsView);
      }, 300);

      const animationClassRemovalTimeout = setTimeout(() => {
        animationRef.current?.classList.remove(styles.fadeOutFadeIn);
      }, 600);

      return () => {
        clearTimeout(tokenAdAttributionTimeout);
        clearTimeout(animationClassRemovalTimeout);
        animationRef.current?.classList.remove(styles.fadeOutFadeIn);
      };
    }

    setTokenAd(tokenAdsView);
  }, [tokenAdsView]);

  if (isLoading || (tokenAdsView && !tokenAd)) return <TokenAdBannerSkeleton />;

  if (!tokenAd) return null;

  return (
    <div className={styles.mainContainer}>
      <div ref={animationRef}>
        {hrefLinkCta ? (
          <Link
            className={styles.link}
            href={hrefLinkCta}
            target={hasCta ? '_blank' : '_self'}
            onClick={() => onAdClick({ url: hrefLinkCta, title: tokenAd.pitch })}
          >
            <TokenAdBanner tokenAdsView={tokenAd} />
          </Link>
        ) : (
          <TokenAdBanner tokenAdsView={tokenAd} />
        )}
      </div>
      <Link className={styles.adsWithUslink} href={ERoutePath.marketplaceCryptoAds}>
        <ButtonLink size="xs">
          <FontAwesomeIcon className={styles.socialMediaIcon} icon={faRectangleAd} />
          <Trans>Advertise With Us</Trans>
        </ButtonLink>
      </Link>
    </div>
  );
};
