import { logApp } from '@/utils/logApp';
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';

const log = logApp.create(import.meta.url);

export const createApolloClient = ({ authToken, endpoint }: { authToken?: string; endpoint: string }) => {
  const httpLink = new HttpLink({ uri: endpoint });

  const authMiddleware = new ApolloLink((operation, forward) => {
    if (authToken) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation).map((response) => {
      if (response.errors) {
        response.errors.forEach((error) => {
          log.error('[authMiddleware]', error, { operation });
        });
      }
      return response;
    });
  });

  const link = from([authMiddleware, httpLink]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    ssrMode: true,
  });
};
