import { ChainLogo } from '@/components/common/ChainLogo';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';
import { chainOptions } from '@/utils/chainOptions';
import { getChainNameByChainId } from '@/utils/supportedChains';
import { t } from '@lingui/core/macro';

export const OPTIONS_OF_TRENDING = {
  defaultCategory: 'tokens',
  defaultChainId: 'allChains',
  uncategorized: 'uncategorized',
};

export const createCategoryOptions = (
  categories: {
    id: number;
    value: string;
    name: string;
    type: string;
    parentCategoryId: number | null;
  }[],
) => [
  { id: OPTIONS_OF_TRENDING.defaultCategory, label: t`All Categories` },
  { id: OPTIONS_OF_TRENDING.uncategorized, label: t`Uncategorized` },
  ...categories.map((category) => ({
    id: category.value,
    label: category.name,
  })),
];

export const createChainIDOptions = (uniqueChainIds: string[]) => [
  {
    id: OPTIONS_OF_TRENDING.defaultChainId,
    label: t({
      message: 'All Chains',
      context: 'Navigation CTA',
      comment: 'All chains option in the dropdown',
    }),
    prefix: <ChainLogo chainId="allChains" />,
  },
  ...uniqueChainIds.map((chainID) => {
    const originalChainOption = chainOptions.find((option) => option.id === chainID);

    return {
      id: chainID,
      label: capitalizeFirstLetter(getChainNameByChainId(chainID) || chainID),
      prefix: originalChainOption ? <ChainLogo chainId={originalChainOption.id} /> : null,
    };
  }),
];
