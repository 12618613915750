import { useEffect, useState } from 'react';
import { Modal } from 'moralis-ui';
import { useForceUpdate } from '@/hooks/useForceUpdate';
import { isDevModeEnabled } from '@/utils/devMode';
import { DevModeControlPanel } from './DevModeControlPanel';

export const DevModeToggle = ({ children }: React.PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasButton, setHasButton] = useState(false);
  const reRender = useForceUpdate();

  useEffect(() => {
    if (isDevModeEnabled()) {
      setHasButton(true);
    }
  }, []);

  if (!hasButton) {
    return children;
  }

  return (
    <>
      {children}
      <span
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          color: '#fff',
          padding: '3px',
          zIndex: 99999,
          fontSize: '20px',
          cursor: 'pointer',
          textShadow: '0px 4px 4px #282828',
          borderRadius: '50%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        onClick={() => setIsVisible(!isVisible)}
      >
        ⚜️
      </span>

      <Modal
        hasOverlay
        hasCloseButton={true}
        isVisible={isVisible}
        onChange={setIsVisible}
        slots={{
          header: <>Dev mode configuration</>,
        }}
      >
        {isVisible && (
          <DevModeControlPanel
            onClose={() => {
              setIsVisible(false);
              reRender();
            }}
          />
        )}
      </Modal>
    </>
  );
};
