import { t } from '@lingui/core/macro';

export const POP_MESSAGE = {
  success: {
    get defaultTitle() {
      return t`Success`;
    },
    get defaultMessage() {
      return t`Operation completed successfully`;
    },
  },
  error: {
    get defaultTitle() {
      return t`An error occurred`;
    },
    get defaultMessage() {
      return t`An error occurred while processing your request`;
    },
  },
};
