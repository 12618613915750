import { ButtonDark, ButtonDestructive } from '@/components/core/Button';
import { Modal, ModalClose, ModalContent } from '@/components/core/Modal';
import { IDeleteModalProps, useAlertModalContext } from './useAlertModalContext';
import styles from './DeleteAlertModal.module.scss';
import typography from '@/styles/scss/typography.module.scss';

export const DeleteAlertModal = () => {
  const { modalContent, closeAlertModal, modalProps } = useAlertModalContext<IDeleteModalProps>();

  if (modalContent !== 'delete') return null;

  return (
    <Modal initialOpen={true} onOpenChange={closeAlertModal}>
      <ModalContent className={styles.deleteAlertModal}>
        <div className={styles.modalHeader}>
          <h3 className={typography.h3}>Confirm Deletion</h3>
          <ModalClose />
        </div>
        <div className={styles.modalFooter}>
          <p className={typography.digit24}>
            Are you sure you want to Delete
            <br />
            &quot;{modalProps?.name}&quot;?
          </p>
          <h4>There is no way to undo this action.</h4>
          <div className={styles.buttons}>
            <ButtonDark size="large" onClick={closeAlertModal}>
              Cancel
            </ButtonDark>
            <ButtonDestructive
              size="large"
              onClick={() => {
                modalProps?.onDelete();
                closeAlertModal();
              }}
            >
              Yes, Delete
            </ButtonDestructive>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
