import { useEffect } from 'react';
import { EnvApp } from '@/utils/env/app';
import { logApp } from '@/utils/logApp';
import { AnalyticsTracker } from '@/utils/segment/AnalyticsTracker.browser';

const log = logApp.create(import.meta.url);

const tracker: AnalyticsTracker = new AnalyticsTracker();
export const useAnalyticsLoad = EnvApp.useAnalytics
  ? () => {
      useEffect(() => {
        log.debug('tracker.load', null);
        tracker.load();
      }, []);
    }
  : () => {
      log.debug('Analytics is disabled', EnvApp);
    };

export const useAnalytics = () => tracker;
