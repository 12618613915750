import { useAlertModal } from '@/hooks/useAlertModal';
import { useUserStage } from '@/hooks/useUser';
import { faBarsFilter, faHexagon, faWallet } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserAllowedAlertTypes } from '../alerts/getUserAllowedAlert';
import { AlertTypeCard } from './AlertTypeCard';
import styles from './SelectAlertTypeCards.module.scss';

export const SelectAlertTypeCards = () => {
  const allowedAlertTypes = getUserAllowedAlertTypes(useUserStage());
  const { open } = useAlertModal();

  return (
    <div className={styles.wrapper}>
      <AlertTypeCard
        description="Get notified when your saved strategy spots new tokens."
        disabled={!allowedAlertTypes.strategy}
        icon={<FontAwesomeIcon className={styles.strategy_icon} icon={faBarsFilter} style={{ height: '40px' }} />}
        name="Strategy Alert"
        onClick={() => open.strategy()}
      />
      <AlertTypeCard
        description="Alerts for token price, buyer activity, volume, and more."
        disabled={!allowedAlertTypes.token}
        icon={<FontAwesomeIcon className={styles.token_icon} icon={faHexagon} style={{ height: '40px' }} />}
        name="Token Alert"
        onClick={() => open.token()}
      />
      <AlertTypeCard
        description="Get notified instantly when top traders make a move."
        disabled={!allowedAlertTypes.bundle}
        icon={<FontAwesomeIcon className={styles.wallet_icon} icon={faWallet} style={{ height: '40px' }} />}
        name="Bundle Alert"
        onClick={() => open.bundle()}
      />
    </div>
  );
};
