import { booleanFeature, FeatValidatorCfg } from './createFeatureFlag';

/**
 * Add your flags here
 * @see ./examples.ts for reference
 */
export const ALL_FEATURE_FLAGS = {
  trading: booleanFeature('Show trading widget'),
  table_v2: booleanFeature('Show new tables'),
  token_page_live_feed: booleanFeature('Show live feed on token page'),
  trending_followed: booleanFeature('Show follow/unfollow button on trending tokens table'),
  shill_token: booleanFeature('Show shill token modal'),
  verified_page: booleanFeature('Show verified crypto projects page'),
  dev_mode: booleanFeature('Enable dev mode'),
} satisfies Record<string, FeatValidatorCfg<unknown>>;

// Utils
export type AllFeatureFlags = typeof ALL_FEATURE_FLAGS;
export type FeatName = keyof AllFeatureFlags;
