export enum ERoutePath {
  account = '/account',
  alerts = '/alerts',
  anonymous = '/anonymous',
  beans = '/profile/beans',
  blog = '/blog',
  cryptoTelegramBot = '/crypto-telegram-bot',
  bundles = '/bundles',
  bunny = '/bunny',
  chain = '/chain',
  community = '/community',
  detail = '/profile/nfts/detail',
  ebook = '/ebook',
  enhancedTokenPage = '/enhanced-token-page',
  faqs = '/faqs',
  glossary = '/glossary',
  history = '/profile/history',
  home = '/',
  learn = '/learn',
  live = '/live',
  login = '/login',
  manageSubscription = '/manage-subscription',
  manageWallets = '/portfolio/manage/wallets',
  marketplace = '/marketplace',
  marketplaceDashboard = '/marketplace/dashboard',
  marketplaceEnhancedTokenPage = '/marketplace/enhanced-token-page',
  marketplaceCryptoAds = '/marketplace/crypto-ads',
  marketplaceCryptoAdsOrder = '/marketplace/crypto-ads/order',
  newCoins = '/new-coins',
  nftDetails = '/details',
  nfts = '/profile/nfts',
  notifications = '/notifications',
  portfolio = '/portfolio',
  pricing = '/pricing',
  privacy = '/privacy',
  profile = '/profile',
  promo = '/promo',
  promoSurprise = '/surprise',
  saved = '/saved',
  savedQueries = '/profile/strategies/saved',
  savedTokens = '/profile/tokens/saved',
  search = '/search',
  searchNfts = '/search/nfts',
  swap = '/swap',
  terms = '/terms',
  tokenExplorer = '/token-explorer',
  tokenSingle = '/token',
  tokens = '/profile/tokens',
  topTokensMarketCap = '/top-tokens-by-market-cap',
  topTokensMove = '/top-tokens-on-the-move',
  trade = '/trade',
  unsubscribeEmails = '/unsubscribe-emails',
  verifiedCryptoProjects = '/verified-crypto-projects',
  // WIDGETS
  widgets = '/widgets',
  widgetsTokenPage = '/widgets/token-page',
  widgetsPriceChart = '/widgets/price-chart',
}
