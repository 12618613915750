import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useOutsideClick } from 'moralis-ui';
import { useScrollLock } from '@/hooks/useScrollLock';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDrawerProps } from './types';
import styles from './styles.module.scss';

export const Drawer = ({
  children,
  isVisible,
  hasOverlay = true,
  scrollAmountToClose = 30,
  closeOnClickOutside = true,
  onClose,
  isAboveMenu = false,
  hasCloseButton = true,
}: IDrawerProps) => {
  const [isDown, setIsDown] = useState(false);
  const [startY, setStartY] = useState<number | null>(null);
  const [scrolledAmount, setScrolledAmount] = useState<number>(0);
  const [visible, setVisible] = useState(isVisible);
  const [isClosing, setIsClosing] = useState(false);

  const handleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isVisible) {
      setVisible(true);
      setIsClosing(false); // Reset closing state when opening
    } else {
      setIsClosing(true); // Trigger closing animation
      // After transition duration, set visibility to false
      setTimeout(() => {
        setVisible(false);
        if (onClose) onClose(); // Call the onClose callback after the animation completes
      }, 150); // Ensure this matches the CSS transition duration
    }
  }, [isVisible, onClose]);

  // Implement outside click behavior
  useOutsideClick(handleRef, () => {
    if (closeOnClickOutside && !isClosing) {
      setIsClosing(true);
      setTimeout(() => {
        setVisible(false);
        if (onClose) onClose(); // Call the onClose callback after the animation completes
      }, 150); // Ensure this matches the CSS transition duration
    }
  });

  // Sync the internal visibility state with the prop
  useEffect(() => {
    setScrolledAmount(0); // Reset the scrolled amount when visibility changes
    setVisible(isVisible);
  }, [isVisible]);

  // Common event handler for both touch and mouse down
  const handleStart = (clientY: number) => {
    setIsDown(true);
    setStartY(clientY);
    setScrolledAmount(0); // Reset the scrolled amount when dragging starts
  };

  // Logic to reset the drawer to its original state
  const resetDrawer = useCallback(() => {
    setVisible(false); // Hide the drawer
    setStartY(null);
    setIsDown(false);
    document.documentElement.style.overflow = 'auto'; // Re-enable page scrolling
    if (onClose) onClose(); // Call the onClose callback when reset
  }, [onClose]);

  // Update the drawer's position while dragging
  const handleMove = useCallback(
    (clientY: number) => {
      if (!startY || !isDown) return;

      const diffY = clientY - startY;

      // Allow dragging both up and down, ensuring the drawer can't go past its initial position
      if (diffY > 0) {
        setScrolledAmount(diffY); // Dragging down
      } else {
        setScrolledAmount(0); // If trying to drag up past the original position, lock at 0
      }
    },
    [startY, isDown],
  );

  // Handle the end of dragging (mouse or touch)
  const handleEnd = useCallback(() => {
    setIsDown(false);

    if (!startY) return;

    if (scrolledAmount > scrollAmountToClose) {
      resetDrawer(); // Reset if dragged down far enough
    } else {
      // Reset drawer back to its original position if not pulled far enough
      setScrolledAmount(0);
    }

    setStartY(null);
    document.documentElement.style.overflow = 'auto';
  }, [scrolledAmount, scrollAmountToClose, startY, resetDrawer]);

  // Touch event handlers
  const handleTouchStart = (e: React.TouchEvent) => {
    e.preventDefault();
    handleStart(e.touches[0].clientY);
  };

  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      handleMove(e.touches[0].clientY);
    },
    [handleMove],
  );

  const handleTouchEnd = useCallback(() => {
    handleEnd();
  }, [handleEnd]);

  // Mouse event handlers
  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    handleStart(e.clientY);
  };

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      handleMove(e.clientY);
    },
    [handleMove],
  );

  const handleMouseUp = useCallback(() => {
    handleEnd();
  }, [handleEnd]);

  const handleCloseDrawer = useCallback(() => {
    resetDrawer();
  }, [resetDrawer]);

  useScrollLock(isVisible);

  return (
    <section
      className={clsx(styles.drawer, {
        [styles.hasOverlay]: hasOverlay,
        [styles.open]: visible,
        [styles.aboveMenu]: isAboveMenu,
      })}
    >
      <div className={styles.drawerInner} ref={handleRef} style={{ transform: `translateY(${scrolledAmount}px)` }}>
        <div
          className={clsx(styles.pullHandle, { [styles.pullActive]: isDown })}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        ></div>
        {hasCloseButton && (
          <div className={clsx(styles.closeIcon)} onTouchEnd={handleCloseDrawer} onMouseDown={handleCloseDrawer}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </section>
  );
};
