import create from 'zustand';
import { persist } from 'zustand/middleware';

type TNotificationBannerStore = {
  dismissNotificationBanner: () => void;
  dismissTrialBanner: () => void;
  isNotificationBannerVisible: boolean;
  isTrialBannerVisible: boolean;
};

export const useNotificationBannerStore = create(
  persist<TNotificationBannerStore>((set) => {
    return {
      isNotificationBannerVisible: true,
      isTrialBannerVisible: true,

      dismissNotificationBanner: () => {
        set(() => {
          return {
            isNotificationBannerVisible: false,
          };
        });
      },
      dismissTrialBanner: () => {
        set(() => {
          return {
            isTrialBannerVisible: false,
          };
        });
      },
    };
  }),
);
