import { t } from '@lingui/core/macro';
import { LINKS } from './links';

export const SEO_DOMAIN = 'https://moralis.com';
export const SEO_THEME_COLOR = '#1d22c7';
export const SEO_TITLE = ['Moralis', 'Real-Time On-Chain Crypto Metrics', 'Discover & Trade Undervalued Altcoins'];
export const SEO_DESCRIPTION =
  'Find undervalued altcoins using on-chain metrics like Coin Age, Net Buyers, Buy Pressure, and much more across top chains like Ethereum, Binance Smart Chain and many others.';
export const getSEOTitle = (): string[] => [
  t`Moralis`,
  t`Real-Time On-Chain Crypto Metrics`,
  t`Discover & Trade Undervalued Altcoins`,
];

export const getSEODescription = (): string =>
  t`Find undervalued altcoins using on-chain metrics like Coin Age, Net Buyers, Buy Pressure, and much more across top chains like Ethereum, Binance Smart Chain and many others.`;

export const SEO_PREVIEW_IMAGE = `${SEO_DOMAIN}/static/DefaultPreviewImage.jpg`;
const SEO_LOGOMARK = `${SEO_DOMAIN}/_next/static/media/MoralisMoneyLogomark.5b65b0d7.svg`;

export const SEO_SCHEMA_MORALIS_ORG = {
  '@type': 'Organization',
  'name': 'Moralis',
  'logo': {
    '@type': 'ImageObject',
    'url': SEO_LOGOMARK,
    'height': 30,
    'width': 38,
    'sameAs': [LINKS.twitter, LINKS.youtube, SEO_DOMAIN],
  },
};

export const SEO_SCHEMA_MORALIS_CORP = {
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  'name': 'Moralis',
  'url': SEO_DOMAIN,
  'logo': SEO_LOGOMARK,
  'sameAs': [LINKS.twitter, LINKS.youtube],
};

export const SEO_SCHEMA_IVAN = {
  '@context': 'https://schema.org/',
  '@type': 'Person',
  'name': 'Ivan Liljeqvist (Ivan on Tech)',
  'url': '',
  'image': 'https://academy.moralis.io/wp-content/uploads/2022/05/Ivan_cropped_circle_534x534.png',
  'sameAs': ['https://twitter.com/IvanOnTech', 'https://www.youtube.com/@IvanOnTech'],
  'jobTitle': 'Founder & CEO',
  'worksFor': {
    '@type': 'Organization',
    'name': 'Moralis',
  },
};

export const getSEOImageObject = (url = SEO_PREVIEW_IMAGE) => ({
  '@type': 'ImageObject',
  'url': url,
  'height': 380,
  'width': 640,
});
