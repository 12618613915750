import { useMemo } from 'react';
import Link from 'next/link';
import { API } from '@/api';
import { ChainDataSummaryDto } from '@/services/models/ChainDataSummaryDto';
import { DataSummaryVolume } from '@/utils/api/ssr/fetchChainsDataSummary';
import { ERoutePath } from '@/utils/ERoutePath';
import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { getCategoryID } from './utils/getCategoryID';
import { getTokenExplorerLinkFilters } from './utils/getTokenExplorerLinkFilters';
import { IBSwiperItem, IBSwiperWrap } from './IBSwiper';
import { InfoBoxLink, InfoBoxWithCount, InfoBoxWithMoney, InfoBoxWrapper } from './InfoBox';
import swiperStyles from './IBSwiper.module.scss';

const normaliseData = (data: API.Insights.Comps['IGetChainsDataSummaryResponse']['data'] | undefined) =>
  !data || data.length < 2 ? null : data.map((d) => Number(d.value));

const SWIPER_BREAKPOINTS = {
  0: {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 12,
  },
  768: {
    grabCursor: true,
    slidesPerView: 2,
    spaceBetween: 12,
  },
  1400: {
    grabCursor: false,
    slidesPerView: 3,
    spaceBetween: 0,
  },
};

const INITIAL_SLIDE = 0;
const INITIAL_SLIDES_PER_VIEW = 4;

export const ChainsDataSummary = ({
  category,
  chainId,
  dataSummaryTokens,
  dataSummaryTotalVolume,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  dataSummaryNetVolume,
}: {
  category: string | null;
  chainId: string | null;
  dataSummaryTokens: ChainDataSummaryDto;
  dataSummaryTotalVolume: DataSummaryVolume[];
  dataSummaryNetVolume: DataSummaryVolume[];
}) => {
  const totalVolume = useMemo(() => normaliseData(dataSummaryTotalVolume), [dataSummaryTotalVolume]);
  //const netVolume = useMemo(() => normaliseData(dataSummaryNetVolume), [dataSummaryNetVolume]);

  const tokenExplorerLink =
    Number(dataSummaryTokens.tokensCount) > 0
      ? getTokenExplorerLinkFilters(chainId, category ? getCategoryID(category) : null)
      : ERoutePath.tokenExplorer;

  return (
    <InfoBoxWrapper>
      <IBSwiperWrap
        breakpoints={SWIPER_BREAKPOINTS}
        initialSlide={INITIAL_SLIDE}
        slidesPerView={INITIAL_SLIDES_PER_VIEW}
        customBodyClass={swiperStyles.ibSwiperWrapper}
        id="ChainsDataSummarySlider"
      >
        <IBSwiperItem>
          <InfoBoxWithCount
            title={t({
              message: 'Total Tokens',
              context: 'Total number of tokens tracked',
            })}
            count={dataSummaryTokens.tokensCount}
          >
            {chainId !== null && (
              <Link href={tokenExplorerLink}>
                <InfoBoxLink>
                  <Trans>Token Explorer</Trans>
                </InfoBoxLink>
              </Link>
            )}
          </InfoBoxWithCount>
        </IBSwiperItem>
        <IBSwiperItem>
          <InfoBoxWithCount
            title={t({
              message: 'New Coins 24H',
              context: 'Newly added tokens in the last 24 hours',
            })}
            count={dataSummaryTokens.oneDayTokensCount}
          />
        </IBSwiperItem>
        <IBSwiperItem>
          <InfoBoxWithMoney
            title={t({
              message: 'Total Volume 24H',
              context: 'Total trading volume in USD over the past 24 hours',
            })}
            money={dataSummaryTokens.oneDayVolumeUsd}
            graphData={totalVolume}
          />
        </IBSwiperItem>
      </IBSwiperWrap>
    </InfoBoxWrapper>
  );
};
